import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import '../css/paddockleague.css';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card, Table } from 'react-bootstrap'

const ClassificacaoConstrutoresPage = () => (
    <ClassificacaoConstrutoresForm />
);

class ClassificacaoConstrutoresPageBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        dados: '',
        isProcessando: false,
    };
  }
  getResultados() {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    this.setState({ isProcessando: true, });
    fetch(process.env.REACT_APP_CLASSIFICACAO_CONSTRUTORES_F1, requestOptions)
      .then(response => response.text())
      .then(result => {
        this.setDados(JSON.parse(result))
        this.setState({ isProcessando: false, });
      })
      .catch(error => console.log('error', error));
  }

  componentWillMount() {
    this.getResultados()
  }

  setDados = (data) => {
    console.log(data)
    this.setState({ dados: data })
  }
  
  render() {
    const { dados, isProcessando } = this.state;
    
    const renderResultado = (resultado) => {
      
      return (
        <tr key={resultado.position}>
          <td>{resultado.position}</td>
          <td>
            <a target="_blank" rel="noreferrer"  href={resultado.Constructor.url}>{ resultado.Constructor.name }</a>
          </td>
          <td>{resultado.Constructor.nationality}</td>
          <td>{resultado.points}</td>
          <td>{resultado.wins}</td>
        </tr>
      );
    }
    const verificaValor = (value) => {
      if(typeof value === 'undefined' || value === null || value === ''){
        return false;
      }
      return true;
      
    }

    if(isProcessando){
      return (
        <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
          
        </LoadingOverlay>
      );
    }else{
      return (
        <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
          <Card className="cardPainelTexto">
            <Card.Body>
              <Card.Title className="cardPainelTitulo">Classificação Construtores - Temporada { dados.MRData.StandingsTable.StandingsLists[0].season }</Card.Title>
              <Card.Text className="wordStyle">
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>Posição</th>
                      <th>Escuderia</th>
                      <th>Nacionalidade</th>
                      <th>Pontos</th>
                      <th>Nº de Vitórias</th>
                    </tr>
                    </thead>
                    <tbody>
                       { dados.MRData.StandingsTable.StandingsLists[0].ConstructorStandings.map(renderResultado) }
                    </tbody>
                  </Table>
              </Card.Text>
            </Card.Body>
          </Card>
        </LoadingOverlay>
      );
    }
  }
}

const ClassificacaoConstrutoresForm = compose(
  withFirebase
)(ClassificacaoConstrutoresPageBase);

export default ClassificacaoConstrutoresPage;
export { ClassificacaoConstrutoresForm };