import React, { Component } from 'react';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../Session';
import ErrorMessages from '../../ErrorMessages';
import '../../css/paddockleague.css';
import * as ROLES from '../../../constants/roles';
import LoadingOverlay from 'react-loading-overlay';

const INITIAL_STATE = {
  senha1: '',
  senha2: '',
  error: null,
  isValido: false,
  isProcessando: false,
  statusOk: false,
};

const ChangePasswordPage = () => (
  <Card className="cardPainelTexto">
    <Card.Body>
        <Card.Title className="cardPainelTitulo">Mudar senha </Card.Title>
        <Card.Text>
            <div className="wordStyle">
                <PasswordChangeForm />
            </div>
        </Card.Text>
    </Card.Body>
  </Card>
);

class PasswordChangeFormBase extends Component {
  errorMessages = null;
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  componentWillUnmount() {
    this.errorMessages = null;
  }
  
  componentDidMount() {
    this.errorMessages = new ErrorMessages();
  }

  onSubmit = event => {
    const { senha1, senha2, } = this.state;
    if (senha1 !== senha2){
        alert('As senhas informadas não são iguais, verifique!');
        event.preventDefault();
    }else{
      this.setState({ isValido: true, isProcessando: true });
      this.props.firebase
      .doPasswordUpdate(senha1)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.setState({error: 'Senha alterada com sucesso! Você será redirecionado para a pagina de login.', isProcessando: false, statusOk: true});
        setTimeout(function() {
          this.props.firebase.doSignOut();
        }.bind(this), 5000)
      }).catch(error => {
        this.setState({ error: this.errorMessages.errosAutenticacao(error), isProcessando: false, statusOk: false});
      });
      event.preventDefault();
    }
    
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    let { senha1, senha2, error, isValido, isProcessando, statusOk } = this.state;
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{error}</p>)
      }else{
        return <p className="styleError">{error}</p>
      }
    }

    const isInvalid = 
      senha1 !== senha2 || senha1 === '';
    return (
      <Form noValidate validated={isValido} onSubmit={this.onSubmit}>
          <LoadingOverlay active={isProcessando}
                          spinner
                          text='Aguarde!'>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Senha</Form.Label>
                <Form.Control required maxLength={15} name="senha1" type="password" placeholder="Senha" autoComplete="on" onChange={this.onChange}/>
                <Form.Control.Feedback type="invalid">
                  Informe uma senha
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPassword2">
                <Form.Label>Repita a senha</Form.Label>
                <Form.Control required maxLength={15} name="senha2" type="password" placeholder="Repita a senha" autoComplete="on" onChange={this.onChange}/>
                <Form.Control.Feedback type="invalid">
                  Repita a senha digitada anteriormente
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            {renderMessage()}
            <Button disabled={isInvalid}  variant="primary" type="submit">
              Enviar
            </Button>
          </LoadingOverlay>
        </Form>
    );
  }
}
const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.PILOTO] || !!authUser.roles[ROLES.ADMIN]);

const PasswordChangeForm = compose(
  withRouter,
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(PasswordChangeFormBase);

export default ChangePasswordPage;

export { PasswordChangeForm };