import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Image, Modal, Button, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import * as CONSTANTES from '../../../constants/constantes';
import '../../css/paddockleague.css';
import Delete from '../../css/delete.png';
import Detail from '../../css/detail.png';
import ReactTooltip from "react-tooltip";

const NoticiaPage = () => (
    <NoticiaForm />
);

class NoticiaListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessando: false,
      statusOk: false,
      msgRetorno: '',
      noticias_list: [],
      show: false,
      noticiaSelecionada: '',
    };
  }

  retornaNoticias(){
    this.setState({ isProcessando: true });

    this.props.firebase.noticias().once('value', snapshot => {
      const noticiaObject = snapshot.val();
      let noticiasList = [];
      if(noticiaObject){
        noticiasList = Object.keys(noticiaObject).map(key => ({
          ...noticiaObject[key],
          uid: key,
        }));
      }else{
        noticiasList = [];
      }

      this.setState({
        noticias_list: noticiasList,
        isProcessando: false,
      });
    });
  }

  componentDidMount() {
    this.retornaNoticias();
  }

  componentWillUnmount() {
    this.props.firebase.noticias().off();
  }
  

  render() {
    const { noticias_list, isProcessando, show, noticiaSelecionada } = this.state;
    
    const handleClose = () => {
      this.setState({ show: false })
    };
    const handleShow = (noticia) => {
      this.setState({ noticiaSelecionada: noticia, show: true })
    };
    const handleDeletarNoticia = (uid) => {
      this.setState({ show: false, isProcessando: true })
      this.props.firebase.noticia(uid).remove().then(() => {
        let listaNoticia = noticias_list.filter(camp => camp.uid !== uid);
        this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Registro atualizado com sucesso!', noticias_list: listaNoticia});
      }).catch(error => {
        this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
      })
    };
    
    const renderNoticia = (noticia) => { 
        return (
            <tr key={noticia.uid}>
                <td>{noticia.titulo_noticia}</td>
                <td>{ noticia.data_publicacao }</td>
                <td className="centerTableImage">
                  <Link to={{ pathname: `${ROUTES.LISTAGEM_NOTICIAS}/${noticia.uid}`, 
                                        state: { noticia }, }} >
                    <Image src={Detail} data-tip data-for='detailTip' className="IconTable" />
                    <ReactTooltip id='detailTip' type='error'>
                      <span>Detalhes</span>
                    </ReactTooltip>
                  </Link>
                </td>
                <td className="centerTableImage">
                  <Image src={Delete} data-tip data-for='deleteTip' className="IconTable" onClick={() => handleShow(noticia)}/>
                  <ReactTooltip id='deleteTip' type='error'>
                    <span>Apagar</span>
                  </ReactTooltip>
                </td>
            </tr>
        );
    }
 
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo"> Cadastro de Notícias </Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <div className="wordStyle">
                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th>Título</th>
                        <th>Data Publicação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {noticias_list.map(renderNoticia)}
                    </tbody>
                  </Table> 
                  <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                      <Modal.Title className="wordStyle centered">{CONSTANTES.DRIVE_PRO_SERIES}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="wordStyle">
                      Você está prestes a deletar o {noticiaSelecionada.titulo_noticia}, tem certeza disso? 
                    </Modal.Body>
                    <Modal.Footer>
                      <Button className="wordStyle" variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                      </Button>
                      <Button className="wordStyle" variant="primary" onClick={() => handleDeletarNoticia(noticiaSelecionada.uid)}>
                        Ok, pode deletar
                      </Button>
                    </Modal.Footer>
                  </Modal>     
                </div>
              </Card.Body>
            </Card>
          </LoadingOverlay>
      )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.IMPRENSA]);

const NoticiaForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NoticiaListBase);

export default NoticiaPage;
export { NoticiaForm };