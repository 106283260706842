import React, { Component } from 'react';
import { Form, Button, Col, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import '../../css/paddockleague.css';
import { Redirect  } from 'react-router-dom'

const CategoriaDetailPage = () => (
  <CategoriaDetalheForm />
);

class CategoriaDetalheBase extends Component {
  constructor(props) {
    super(props);
    
    if(props.location.state == null){  
      this.state = {
        nome_categoria: '',
        descricao: '',
        url_categoria: '',
        posicao: 0,
        indAtivo: false,
        
        msgRetorno: '',
        isValido: false,
        statusOk: false,
        statusPaginaInvalida: true,
      }; 
    }else{
      let infoCategoria = props.location.state.categoria;
    
      this.state = {
        isProcessando: false,
        uid: infoCategoria.uid,
        nome_categoria: infoCategoria.nome_categoria,
        descricao: infoCategoria.descricao,
        url_categoria: infoCategoria.url_categoria,
        posicao: infoCategoria.posicao,
        indAtivo: infoCategoria.indAtivo,
        
        ...props.location.state,
        msgRetorno: '',
        statusOk: false,
        isValido: false,
        statusPaginaInvalida: false,
      };
      this.onChange = this.onChange.bind(this); 
    }
  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
  }

  onChangeCheckBoxAtivo = event => {
    this.setState({ indAtivo: !this.state.indAtivo,});
  };

  onChange = event => {  
    this.setState({ [event.target.name]: event.target.value });
  };
  
  onCreateCategoria = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isValido: true });
    }else{
      this.setState({ isValido: false, isProcessando: true, });
      this.props.firebase.categoria(this.state.uid).update({
        nome_categoria: this.state.nome_categoria,
        descricao: this.state.descricao,
        posicao: this.state.posicao,
        url_categoria: this.state.url_categoria,
        indAtivo: this.state.indAtivo,
      }).then(() => {
        this.setState({msgRetorno: 'Categoria salva com sucesso!', isProcessando: false, statusOk: true});
      }).catch(error => {
        this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
      });
    }
    
    event.preventDefault();
  };

  render() {
    const {nome_categoria, descricao, url_categoria, posicao, isValido, isInvalidFields, isProcessando, msgRetorno, statusOk, statusPaginaInvalida, indAtivo } = this.state;
    
    const renderMessage = ()=>{ 
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }

    const redirect = () => {
      if(statusPaginaInvalida){
          return (<Redirect to={{pathname: "/"}} />)
      }
      if (statusOk){
          return (<Redirect to={ROUTES.LISTAGEM_CATEGORIA} delay={5000} />)
      }
    }

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Form noValidate validated={isValido} onSubmit={event => this.onCreateCategoria(event) }>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
              <Card className="cardPainelTexto">
                <Card.Body>
                  <Card.Title className="cardPainelTitulo">Detalhes da Categoria</Card.Title>
                  <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                  <Card.Text className="wordStyle">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridNomeCategoria">
                        <Form.Label>Nome Categoria:</Form.Label>
                        <Form.Control readOnly value={nome_categoria}/>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridDescricaoCategoria">
                        <Form.Label>Descrição:</Form.Label>
                        <Form.Control required name="descricao" value={descricao} as="textarea" rows="3" autoComplete="on" onChange={this.onChange}/>
                        <Form.Control.Feedback type="invalid">
                          Informe a descricao
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCategoriaImagem">
                        <Form.Label>Imagem da Categoria:</Form.Label>
                        <Form.Control required maxLength={100} name="url_categoria" value={url_categoria} type="text" placeholder="hottp://www.image.com.br/categoria.img" autoComplete="on" onChange={this.onChange}/>
                        <Form.Control.Feedback type="invalid">
                          Informe a URL da imagem
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="4" controlId="formGridPosicao">
                        <Form.Label>Posição</Form.Label>
                        <Form.Control required as="select" name="posicao" value={posicao} onChange={this.onChange}>
                          <option key='1' value='1'>1º</option>
                          <option key='2' value='2'>2º</option>
                          <option key='3' value='3'>3º</option>
                          <option key='4' value='4'>4º</option>
                          <option key='5' value='5'>5º</option>
                          <option key='6' value='6'>6º</option>
                          <option key='7' value='7'>7º</option>
                          <option key='8' value='8'>8º</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group id="formGridCheckbox">
                      <Form.Check type="checkbox" name="indAtivo" checked={indAtivo} label="Categoria Ativa" onChange={this.onChangeCheckBoxAtivo}/>
                    </Form.Group>
                    {renderMessage()}
                    {redirect()}
                    <Button disabled={isInvalidFields}  variant="primary" type="submit">
                      Salvar
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </LoadingOverlay>
          </Form>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const CategoriaDetalheForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(CategoriaDetalheBase);

export default CategoriaDetailPage;
export { CategoriaDetalheForm };