import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import '../../css/paddockleague.css';

const NOTIFICACAO = 'Notificação';
const EMAIL = 'E-mail';

const NotificacaoPage = () => (
  <NotificacaoForm />
);
const INITIAL_STATE = {
  msgRetorno: '',
  isProcessando: false,
  statusOk: false,
  isInvalido: false,
};
class NotificacaoFormBase extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      categoria_list: [],
      categoria_nome: '',
      metodo_envio: '',

      msgRetorno: '',
      isProcessando: false,
      statusOk: false,
      isInvalido: false,
    };
  }

  retornaCategorias(){
      this.setState({ isProcessando: true });
      this.props.firebase.categorias().once('value', snapshot => {
        const categoriaObject = snapshot.val();
        if (categoriaObject) {
          const listCategoria = Object.keys(categoriaObject).map(key => ({
            ...categoriaObject[key],
            uid: key,
          }));
          
          this.setState({
            categoria_list: listCategoria,
            isProcessando: false,
            statusOk: true,
          });
        } else {
          this.setState({ categoria_list: null, isProcessando: false, statusOk: false});
        }
      }, error =>{
          this.setState({ categoria_list: null, isProcessando: false, msgRetorno: error, statusOk: false});
      });
  }
  
  componentWillUnmount() {
      
  }
  componentDidMount() {  
    this.retornaCategorias();
  }
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeFiltro = event => { 
    if (event.target.value === NOTIFICACAO){
      this.setState({metodo_envio: NOTIFICACAO})
    }else{
      this.setState({metodo_envio: EMAIL})
    }
  }
  
  render() {
    let {categoria_list, categoria_nome, msgRetorno, isProcessando, statusOk, isInvalido} = this.state;
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    
    const isInvalidFields = false;//onSubmit={this.onSubmit}
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form noValidate validated={isInvalido} >
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                <Card className="cardPainelTexto">
                  <Card.Body>
                    <Card.Title className="cardPainelTitulo">Envio de Notificações/E-mail</Card.Title>
                    <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                    <Card.Text className="wordStyle">
                      <Form.Row>
                        <Form.Group md="6" controlId="formGridCategoria">
                          <Form.Label className='texto_filtro_por'>Enviar: </Form.Label>
                          <Form.Check custom inline type='radio' id='radio-notificacao' name='tipoFiltro' value={NOTIFICACAO} label={NOTIFICACAO} onChange={this.onChangeFiltro} />
                          <Form.Check custom inline type='radio' id='radio-email' name='tipoFiltro' defaultChecked value={EMAIL} label={EMAIL} onChange={this.onChangeFiltro} />
                          <Form.Control required as="select" name="categoria_nome" onChange={this.onChange}>
                            <option key="-1" value="-1"></option>
                            {categoria_list.map(categoria => (
                              <option key={categoria.uid} value={categoria.nome_categoria}> {categoria.nome_categoria}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>
                      {renderMessage()}
                      <Button disabled={isInvalidFields}  variant="primary" type="submit">
                          Enviar
                      </Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
            </LoadingOverlay>
          </Form>      
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const NotificacaoForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NotificacaoFormBase);

export default NotificacaoPage;
export { NotificacaoForm };