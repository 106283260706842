import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import ErrorMessages from '../ErrorMessages';
import '../css/paddockleague.css';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect  } from 'react-router-dom'

const SignInPage = () => (
  <SignInForm />
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  isValido: false,
  isProcessando: false,
};

class SignInFormBase extends Component {
  errorMessages = null;
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  componentWillUnmount() {
    this.errorMessages = null;
  }
  
  componentDidMount() {
    this.errorMessages = new ErrorMessages();
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({ isProcessando: true });

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.setState({ credenciaisOk: true })
      })
      .catch(error => {
        this.setState({ error: this.errorMessages.errosAutenticacao(error), isProcessando: false, credenciaisOk: false });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error, isValido, isProcessando, credenciaisOk } = this.state;
    const redirect = () => {
      if (credenciaisOk){
          return (<Redirect to={ROUTES.LISTAGEM_NEWS} />)
      }
    }

    const isInvalid = password === '' || email === '';

    return (
      <AuthUserContext.Consumer>
        {authUser =>
          <Form noValidate validated={isValido} onSubmit={this.onSubmit}>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
              <Card className="cardPainelTexto">
                <Card.Body>
                  <Card.Title className="cardPainelTitulo">Acesso Restrito </Card.Title>
                  <Card.Text className="wordStyle">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Control required name="email" type="email" placeholder="Informe o e-mail" onChange={this.onChange}/>
                        <Form.Control.Feedback type="invalid">
                          Informe o e-mail!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridSenha">
                        <Form.Control required maxLength={15} name="password" type="password" placeholder="Informe a senha" onChange={this.onChange}/>
                        <Form.Control.Feedback type="invalid">
                          Informe a senha!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    {error && <p className="styleError">{error}</p>}
                    <Button disabled={isInvalid} variant="primary" type="submit">
                      Logar
                    </Button>
                    <PasswordForgetLink />
                    <SignUpLink />
                    {redirect()}      
                  </Card.Text>
                </Card.Body>
              </Card>
            </LoadingOverlay>
          </Form>
      }
      </AuthUserContext.Consumer>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };