import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import '../../css/paddockleague.css';
import axios from "axios";

const TabelasDetailPage = () => (
    <TabelasDetalheForm />
);

class TabelasDetalheBase extends Component {
    constructor(props) {
        super(props);
        let infoTabela = props.location.state.tabela;
        
        this.state = {
            isProcessando: false,
            uid: infoTabela.uid,
            tituloTabela: infoTabela.tituloTabela,
            urlTabela: infoTabela.urlTabela,
            deleteUrl: infoTabela.deleteUrl,
            indAtivo: infoTabela.indAtivo,
            posicao: infoTabela.posicao,
            fileName: '',
            selectedFile: '',
            imgEnviada: false,
            

            ...props.location.state,
            msgRetorno: '',
            statusOk: false,
            show: false,
        };
        this.onChange = this.onChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this); 
    }
    handleInputChange = event => {
        this.setState({ msgRetorno: '',imgEnviada: false, deleteUrl:''  });
        this.setState({ selectedFile: event.target.files[0], })
    }
    onChangeCheckBoxIndAtivo = event => {
        this.setState({ indAtivo: !this.state.indAtivo,});
    };
    onChange = event => {  
        this.setState({ [event.target.name]: event.target.value });
    };
    onChangeTextPosicao = event => {
        this.setState({ posicao: event.target.value });
    };
    
    onSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ isInvalido: true });
        }else{ 
            this.setState({ isInvalido: false, isProcessando: true, });
            this.props.firebase.tabela(this.state.uid).update({
                tituloTabela: this.state.tituloTabela,
                urlTabela: this.state.urlTabela,
                deleteUrl: this.state.deleteUrl,
                indAtivo: this.state.indAtivo,
                posicao: this.state.posicao,
            }).then(() => {
                this.setState({msgRetorno: 'Tabela alterada com sucesso!', isProcessando: false, statusOk: true});
            }).catch(error => {
                this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
            })
        }
    };

    onUpload = event => {
        var fileName = this.state.selectedFile.name;
        if(fileName === undefined || null) {
            this.setState({ msgRetorno: "Escolha uma imagem!", isProcessando: false, statusOk: false});
        }else{
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
                let body = new FormData()
                body.set('key', process.env.REACT_APP_IMGBB_API_KEY);
                body.set('image', this.state.selectedFile)
                this.setState({ isProcessando: true });
                const response = axios.post('https://api.imgbb.com/1/upload',body)
                                      .then(result => {
                                          this.setState({ isProcessando: false });
                                          if(result.data.status == 200){
                                              this.setState({urlTabela: result.data.data.url, msgRetorno: 'Imagem carregada com sucesso!', statusOk: true, selectedFile: '', fileName: '', imgEnviada: true, deleteUrl: result.data.data.delete_url})
                                          }else{
                                              this.setState({ msgRetorno: result.statusText, statusOk: false}); 
                                          }
                                      })
               
            }else{
                this.setState({ msgRetorno: "Permitido apenas arquivos do formato PNG, JPG ou JPEG", isProcessando: false, statusOk: false});
            }
        } 
    }

    render() {
        let { tituloTabela, urlTabela, indAtivo, fileName, imgEnviada, deleteUrl, posicao, msgRetorno, isProcessando, statusOk, isInvalido} = this.state;
        const renderMessage = ()=>{
            if (msgRetorno === null){
                return;
            }
            if (statusOk){
                return (<p className="styleSuccess">{msgRetorno}</p>)
            }else{
                return <p className="styleError">{msgRetorno}</p>
            }
        } 
        const isInvalidFields = false;
        return (
            <AuthUserContext.Consumer>
            {authUser => (
                <Form noValidate validated={isInvalido} onSubmit={this.onSubmit}>
                    <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                        <Card className="cardPainelTexto">
                            <Card.Body>
                                <Card.Title className="cardPainelTitulo">Cadastro de Tabela</Card.Title>
                                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                                <Card.Text className="wordStyle">
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formTituloTabela">
                                            <Form.Label>Título Tabela</Form.Label>
                                            <Form.Control required pattern="\S(.*\S)?" maxLength={100} name="tituloTabela" value={tituloTabela} type="text" placeholder="Classificação Categoria Barrichello 2019" autoComplete="on" onChange={this.onChange}/>
                                            <Form.Control.Feedback type="invalid">
                                            Informe o Título da Tabela
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formUrlTabela">
                                            <Form.Label>Url da Tabela</Form.Label>
                                            <Form.Control required pattern="\S(.*\S)?" name="urlTabela" value={urlTabela} type="text" placeholder="http://www.site.com.br/patrocinador.jpg" autoComplete="on" onChange={this.onChange}/>
                                            <Form.Control.Feedback type="invalid">
                                            Informe a URL da Tabela
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formUrlTabela">
                                            <Form.Label>Url da Tabela para visualizar/deletar</Form.Label>
                                            <Form.Control required pattern="\S(.*\S)?" disabled name="urlTabelaDeletar" value={deleteUrl} type="text" placeholder="http://www.site.com.br/patrocinador.jpg"/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="formTabelaPosicao">
                                            <Form.Label>Posição</Form.Label>
                                            <Form.Control required as="select" name="posicao" value={posicao} onChange={this.onChangeTextPosicao}>
                                            <option key='PRINCIPAL' value='PRINCIPAL'>PRINCIPAL</option>
                                            <option key='INTERMEDIARIO' value='INTERMEDIARIO'>INTERMEDIARIO</option>
                                            <option key='BASICO' value='BASICO'>BASICO</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group id="formGridCheckbox">
                                        <Form.Check type="checkbox" name="indAtivo" checked={indAtivo} label="Ativo" onChange={this.onChangeCheckBoxIndAtivo}/>
                                    </Form.Group>
                                    <Form.Group id="formGridFile">
                                        <Form.File type="file" accept=".png, .jpg, .jpeg"  id="inputFile" label={fileName} onChange={this.handleInputChange}/>
                                        <Button className="margin10PxTop" variant="primary" onClick={() => this.onUpload()}> Carregar Imagem </Button>
                                    </Form.Group>
                                    {renderMessage()}
                                    <Button disabled={isInvalidFields}  variant="primary" type="submit">
                                        Cadastrar
                                    </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </LoadingOverlay>
                </Form>      
            )}
            </AuthUserContext.Consumer>
        );
    }
}

const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.DIRETOR]);

const TabelasDetalheForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(TabelasDetalheBase);

export default TabelasDetailPage;
export { TabelasDetalheForm }; 