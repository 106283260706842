import React, { Component } from 'react';
import { Form, Col, Button, Card, Image } from 'react-bootstrap'
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import '../../css/paddockleague.css';
import WhatsApp from '../../css/whatsapp.png';
import * as ROLES from '../../../constants/roles';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import LoadingOverlay from 'react-loading-overlay';


const ProfilePage = () => (
  <ProfileForm /> 
);

class ProfileFormBase extends Component {
  numeroCelular = '';
  constructor(props) {
    super(props);
    this.state = { celular: '',
                   endereco: '',
                   complemento: '',
                   cidade: '',
                   estado: '',
                   cep: '',
                   isValido: false,
                   isProcessando: false,
                   statusOk: false };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { celular, endereco, complemento, cidade, estado, cep, msgRetorno, isProcessando, statusOk } = this.state;
      
    const onClickBtnSalvarInformacoes = (event, authUser) => {
      this.setState({celular: this.numeroCelular});
      let celularCadastrado = this.state.celular;
      let numCelular = celularCadastrado.replace(new RegExp("_", "g"), "");
      let enderecoAux = this.state.endereco;
      
      if (numCelular === '' || numCelular === null || numCelular.length < 15){
        this.setState({msgRetorno: 'Informe o número do celular!', isProcessando: false, statusOk: false});
        event.preventDefault();
      }else{
        this.setState({ isProcessando: true });
        this.props.firebase.user(authUser.uid).update({
            celular: celularCadastrado,
            endereco: endereco,
            complemento: complemento,
            cidade: cidade,
            estado: estado,
            cep: cep,
        }).then(() => {
            this.setState({msgRetorno: 'Informação atualizada com sucesso!', isProcessando: false, statusOk: true, });
        }).catch(error => {
            this.setState({ msgRetorno: error, isProcessando: false, statusOk: false });
        });
      }
      event.preventDefault();
    }
    const renderGrupoWhatsapp = () =>{
      //href={linkRegulamento} target="_blank" download>Regulamento</Nav.Link>
      return (<div>Clique no link para entrar no nosso grupo de WhatsApp, para acompanhar os avisos das corridas, novidades ou até mesmo para praticar dos nossos 4Fun<a target="_blank" href="https://chat.whatsapp.com/JQqHdGYTOmiIjjLkkHMZhS"> <Image src={WhatsApp} className="whatsappIcon"/> </a> </div>)
      //return (<React.Fragment> <a href="https://chat.whatsapp.com/JQqHdGYTOmiIjjLkkHMZhS"><Image src={WhatsApp} className="IconTable"/> <a/></React.Fragment>);
    }
    const renderMessage = (categoria) =>{
      if (categoria.nome_categoria === 'SEM CATEGORIA'){
        return (<h3 className="mensagemAguardandoTriagemProfile">Aguardando resultado da sua triagem</h3>)
      }else{
        return (<div className="mensagemTriagemProfile">De acordo com seus tempos cadastrados na triagem, você está apto a participar da 
        categoria: {categoria.nome_categoria}</div>)
      }
    }
    const renderMessageErro = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Seja bem vindo ao Cockpit {authUser.nomeUsuario}</Card.Title>
                <Card.Text className="wordStyle">
                  <Form.Group controlId="formGridNome">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control name="nomeUsuario" readOnly value={authUser.nomeUsuario}/>
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control name="email" readOnly value={authUser.email}/>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="formGridCelular">
                      <Form.Label>Celular</Form.Label>
                      <MaskedFormControl autoFocus type='text' name="celular" mask='(11) 11111-1111' placeholder="(11) 12345-1234" value={authUser.celular} onBlur={this.onChange} onFocus={this.onChange} onChange={this.onChange}/>
                    </Form.Group>
                    
                    <Form.Group as={Col} md="4" controlId="formGridPsnPlus">
                      <Form.Label>PSN Plus</Form.Label>
                      <Form.Control className={authUser.utilizaPsnPlus ? "backGroundColorVerde" : "backGroundColorVermelho"} name="psnPlus" readOnly value={authUser.psnPlus}/>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="formGridPsn">
                      <Form.Label>PSN</Form.Label>
                      <Form.Control className={authUser.utilizaPsnPlus ? "backGroundColorVermelho" : "backGroundColorVerde"} name="psn" readOnly value={authUser.psn}/>
                    </Form.Group>  
                  </Form.Row>
                  <Form.Group controlId="formGridEndereco">
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control pattern="\S(.*\S)?" autoFocus name="endereco" maxLength={150} placeholder="Av/Rua Nome da Rua" onBlur={this.onChange} onFocus={this.onChange} onChange={this.onChange} defaultValue={authUser.endereco}/>
                  </Form.Group>
                  <Form.Group controlId="formGridBairro">
                    <Form.Label>Complemento</Form.Label>
                    <Form.Control pattern="\S(.*\S)?" autoFocus name="complemento" maxLength={150} placeholder="Bairro, número" onBlur={this.onChange} onFocus={this.onChange} onChange={this.onChange} defaultValue={authUser.complemento}/>
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridCidade">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control pattern="\S(.*\S)?" autoFocus name="cidade" maxLength={80} placeholder="Joinville" onBlur={this.onChange} onFocus={this.onChange} onChange={this.onChange} defaultValue={authUser.cidade}/>  
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEstado">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control as="select" name="estado" autoFocus onBlur={this.onChange} onFocus={this.onChange} onChange={this.onChange} defaultValue={authUser.estado}>
                        <option>AC - Acre</option>
                        <option>AL - Alagoas</option>
                        <option>AP - Amapá</option>
                        <option>AM - Amazonas</option>
                        <option>BA - Bahia</option>
                        <option>CE - Ceará</option>
                        <option>DF - Distrito Federal</option>
                        <option>ES - Espírito Santo</option>
                        <option>GO - Goiás</option>
                        <option>MA - Maranhão</option>
                        <option>MT - Mato Grosso</option>
                        <option>MS - Mato Grosso do Sul</option>
                        <option>MG - Minas Gerais</option>
                        <option>PA - Pará</option>
                        <option>PB - Paraíba</option>
                        <option>PR - Paraná</option>
                        <option>PE - Pernambuco</option>
                        <option>PI - Piauí</option>
                        <option>RJ - Rio de Janeiro</option>
                        <option>RN - Rio Grande do Norte</option>
                        <option>RS - Rio Grande do Sul</option>
                        <option>RO - Rondônia</option>
                        <option>RR - Roraima</option>
                        <option>SC - Santa Catarina</option>
                        <option>SP - São Paulo</option>
                        <option>SE - Sergipe</option>
                        <option>TO - Tocantins</option>
                      </Form.Control>
                    </Form.Group>
                    
                    <Form.Group as={Col} controlId="formGridCep">
                      <Form.Label>Cep</Form.Label>
                      <Form.Control pattern="\S(.*\S)?" autoFocus name="cep" maxLength={12} placeholder="Código de Endereçamento Postal" onBlur={this.onChange} onFocus={this.onChange} onChange={this.onChange} defaultValue={authUser.cep}/>
                    </Form.Group>    
                  </Form.Row>
                  {renderMessage(authUser.categoria)}
                  {renderGrupoWhatsapp()}
                  {renderMessageErro()}
                  <Button className="alignElementRight" onClick={(event) => onClickBtnSalvarInformacoes(event, authUser)}>
                    Salvar Informação
                  </Button>
                </Card.Text> 
              </Card.Body>
            </Card>
          </LoadingOverlay>
        )}
      </AuthUserContext.Consumer>
    );
  }
}
const condition = authUser =>
  authUser && !!authUser.roles[ROLES.PILOTO];

const ProfileForm = compose(
  withRouter,
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(ProfileFormBase);

export default ProfilePage;

export { ProfileForm };