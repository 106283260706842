import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Form, Image, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import Detail from '../../css/detail.png';
import ReactTooltip from "react-tooltip";

const CATEGORIA = 'Categoria';
const JOGO = 'Jogo';
const TEMPO_TRIAGEM = 'Tempo Triagem';
const SEM_TEMPO_CADASTRADO = "Sem tempo cadastrado";
const COM_TEMPO_CADASTRADO = "Com tempo cadastrado";

const PilotoPage = () => (
  <PilotoForm />
);

class PilotoListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessando: false,
      statusOk: false,
      jogoDisponivel: ['F1 - 2019', 'F1 - 2020', 'F1 - 2021'],
      opcaoTempo: [SEM_TEMPO_CADASTRADO, COM_TEMPO_CADASTRADO],
      tipoFiltro: TEMPO_TRIAGEM,

      error: '',
      users: [],
      categoriaList: [],
    };
  }

  retornaCategorias(){
      this.setState({ isProcessando: true });
      this.props.firebase.categorias().once('value', snapshot => {
        const categoriaObject = snapshot.val();
        if (categoriaObject) {
          const listCategoria = Object.keys(categoriaObject).map(key => ({
            ...categoriaObject[key],
            uid: key,
          }));
          this.setState({
            categoriaList: listCategoria,
            isProcessando: false,
            statusOk: true,
          });
        } else {
          this.setState({ categoriaList: null, isProcessando: false, statusOk: false});
        }
      }, error =>{
          this.setState({ categoriaList: null, isProcessando: false, error: error, statusOk: false});
      });
  }
  formatDate = (date) => {
    if(date !== undefined || date != null){
      let fullDate = date.split('-');
      let dia = fullDate[0];
      let mes = fullDate[1];
      let ano = fullDate[2];
      
      return [mes, dia, ano].join('-')
    }else{
      return ["01", "01", "2022"].join('-');
    }
  }
  dateComparison(a, b) {
    let date1
    let date2
    if(a !== undefined || a != null) {
      date1 = new Date(a.dataCadastro);
    }else{
      date1 = new Date("2050-01-01")
    }
    
    if(b !== undefined || b != null) {
      date2 = new Date(b.dataCadastro);
    }else{
      date2 = new Date("2050-01-01")
    }
    
    return date1 - date2;
  }
  retornaPilotos(){
    this.setState({ isProcessando: true });

    this.props.firebase.users().once('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      this.setState({
        users: usersList.sort(this.dateComparison),
        isProcessando: false,
      });
    });
  }

  componentDidMount() {
    this.retornaCategorias();
    this.retornaPilotos();
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  onChangeFiltro = event => {
    if (event.target.value === CATEGORIA){
      this.setState({tipoFiltro: CATEGORIA})
    }else if (event.target.value === JOGO){
      this.setState({tipoFiltro: JOGO})
    }else if (event.target.value === TEMPO_TRIAGEM){
      this.setState({tipoFiltro: TEMPO_TRIAGEM})
    }
  }

  onChange = event =>{
    this.setState({ isProcessando: true });
    if (this.state.tipoFiltro === CATEGORIA){
      this.onChangeCategoria(event);
    }else if (this.state.tipoFiltro === JOGO){
      this.onChangeJogo(event);
    }else if(this.state.tipoFiltro === TEMPO_TRIAGEM){
      console.log('TRIAGEM');
      this.onChangeTempoTriagem(event);
    }
  }

  onChangeTempoTriagem(event) {
    let tempoCadastradoOuTodos = event.target.value;

    this.props.firebase.users().once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject){
        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        if (tempoCadastradoOuTodos === 'TODOS'){
          this.setState({
            users: usersList,
            isProcessando: false,
          });
        }else{
          if (tempoCadastradoOuTodos === COM_TEMPO_CADASTRADO){
            this.setState({
              users: usersList.filter(cat => typeof cat.provas !== "undefined"),
              isProcessando: false,
            });
          }else if(tempoCadastradoOuTodos === SEM_TEMPO_CADASTRADO){
            this.setState({
              users: usersList.filter(cat => typeof cat.provas === "undefined"),
              isProcessando: false,
            });
          }
        }
        
      }else{
        this.setState({
          users: [],
          isProcessando: false,
        });
      }
      
    });
  }

  onChangeJogo(event){
    let jogoUid = event.target.value;
    
    this.props.firebase.users().once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject){
        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        if (jogoUid === 'TODOS'){
          this.setState({
            users: usersList,
            isProcessando: false,
          });
        }else{
          this.setState({
            users: usersList.filter(cat => cat.jogoEscolhido === jogoUid),
            isProcessando: false,
          });
        }
        
      }else{
        this.setState({
          users: [],
          isProcessando: false,
        });
      }
      
    });
  }

  onChangeCategoria(event){
    let categoriaUid = event.target.value;
    this.props.firebase.users().once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject){
        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        if (categoriaUid === 'TODOS'){
          this.setState({
            users: usersList,
            isProcessando: false,
          });
        }else{
          let lista = [];
          for(var i = 0; i < usersList.length; i++ ){
            let result = usersList[i].categoria;
            if(typeof result[categoriaUid] !== "undefined"){
              if (result[categoriaUid].uid === categoriaUid){
                lista.push(usersList[i]);
              }
            }
          }
          
          this.setState({
            //users: usersList.filter(cat => cat.categoria.uid === categoriaUid),
            users: lista,
            isProcessando: false,
          });
        }
        
      }else{
        this.setState({
          users: [],
          isProcessando: false,
        });
      }
      
    });
  }

  render() {
    const { users, isProcessando, categoriaList, jogoDisponivel, opcaoTempo, tipoFiltro } = this.state;
    const renderPiloto = (piloto) => {
        return (
            <tr key={piloto.uid}>
                <td>{piloto.nomeUsuario}</td>
                <td>{piloto.psnPlus}</td>
                {/*<td>{piloto.categoria.nome_categoria}</td>*/}
                <td>
                  <Link to={{ pathname: `${ROUTES.LISTAGEM_PILOTOS}/${piloto.uid}`, 
                                        state: { piloto }, }} >
                    <Image src={Detail} data-tip data-for='detailTip' className="IconTable" />
                    <ReactTooltip id='detailTip' type='error'>
                      <span>Detalhes</span>
                    </ReactTooltip>
                  </Link>
                </td>
            </tr>
        );
    }

    const renderList = () => {
      if (tipoFiltro === CATEGORIA){
        return (<React.Fragment>
                {categoriaList.map(categoria => (
                  <option key={categoria.uid} value={categoria.uid}> {categoria.nome_categoria}</option>
                ))}
              </React.Fragment>)
      }else if (tipoFiltro === JOGO){
        return (<React.Fragment>
                {jogoDisponivel.map(jogo => (
                  <option key={jogo} value={jogo}> {jogo}</option>
                ))}
              </React.Fragment>)
      }else{
        return (<React.Fragment>
                {opcaoTempo.map(tempo => (
                  <option key={tempo} value={tempo}> {tempo}</option>
                ))}
              </React.Fragment>)
      }
    }
    //

    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Listagem de pilotos</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <div className="wordStyle">
                  <Form>
                    <Form.Row>
                      <Form.Group md="6" controlId="formGridCategoria">
                        <Form.Label className='texto_filtro_por'>Filtro por: </Form.Label>
                        <Form.Check custom inline type='radio' id='radio-categoria' name='tipoFiltro' value={CATEGORIA} label={CATEGORIA} onChange={this.onChangeFiltro} />
                        <Form.Check custom inline type='radio' id='radio-jogo' name='tipoFiltro' value={JOGO} label={JOGO} onChange={this.onChangeFiltro} />
                        <Form.Check custom inline type='radio' id='radio-tempo' name='tipoFiltro' defaultChecked value={TEMPO_TRIAGEM} label={TEMPO_TRIAGEM} onChange={this.onChangeFiltro} />
                        <Form.Control required as="select" name="categoria" onChange={this.onChange}>
                          <option key='-1' value='TODOS'> TODOS </option> 
                          {renderList()}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                  </Form>
                  <Table striped bordered hover variant="dark">
                      <thead>
                          <tr>
                              <th>Nome</th>
                              <th>Psn Plus</th>
                              {/*<th>Categoria</th>*/}
                              <th>{users.length}</th>
                          </tr>
                      </thead>
                      <tbody>
                          {users.map(renderPiloto)}
                      </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </LoadingOverlay>
      )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.DIRETOR]);

const PilotoForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(PilotoListBase);

export default PilotoPage;
export { PilotoForm };