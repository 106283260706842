import React, { Component } from 'react';
import { Form, Button, Col, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as CONSTANTES from '../../../constants/constantes';
import * as ROUTES from '../../../constants/routes';
import '../../css/paddockleague.css';
import MaskedFormControl from 'react-bootstrap-maskedinput'
import { Redirect } from 'react-router-dom';
import{ init } from 'emailjs-com';
import emailjs from 'emailjs-com';


const PilotoDetailPage = () => (
    <PilotoDetalheForm />
);

class PilotoDetalheBase extends Component {
  constructor(props) {
    super(props);
    this.listaCategoriasSelecionadas = new Map();
    init(process.env.REACT_APP_EMAILJS_KEY);
    if(props.location.state == null){ 
      this.state = {
        categoriaList: [],
        provas: [],
        error: '',
        statusOk: false,
        statusPaginaInvalida: true,
        statusZeraTempos: false
      }; 
    }else{ 
      let infoPiloto = props.location.state.piloto;
    
      this.state = {
        isProcessando: false,
        uid: infoPiloto.uid,
        nomeUsuario: infoPiloto.nomeUsuario,
        endereco: infoPiloto.endereco,
        complemento: infoPiloto.complemento,
        cidade: infoPiloto.cidade,
        estado: infoPiloto.estado,
        cep: infoPiloto.cep,
        email: infoPiloto.email,
        gameTag: infoPiloto.gameTag,
        psnPlus: infoPiloto.psnPlus,
        psn: infoPiloto.psn,
        utilizaPsnPlus: infoPiloto.utilizaPsnPlus,
        //categoria: infoPiloto.categoria.nome_categoria,
        celular: infoPiloto.celular,
        dataCadastro: infoPiloto.dataCadastro === undefined || null ? '' : infoPiloto.dataCadastro,

        jogoDisponivel: ['F1 - 2019', 'F1 - 2020', 'F1 - 2021'],
        categoriaList: [],
        categoriasSelecionadas: [],
        provas: [],
        ...props.location.state,
        error: '',
        statusOk: false,
        statusPaginaInvalida: false,
        statusZeraTempos: false
      };
      this.onChange = this.onChange.bind(this); 
    }

     
  }
  getTriagem(){
    this.setState({ isProcessando: true });
      
      this.props.firebase.user(this.state.uid).once('value', snapshot => {
      let tempoTriagem = snapshot.val();
      if(tempoTriagem.provas){
        this.setState({
          provas: tempoTriagem.provas,
          jogoEscolhido: tempoTriagem.jogoEscolhido,
          categoriasSelecionadas: tempoTriagem.categoria,
          isProcessando: false,
        });
      }else{
        this.setState({
          provas: [],
          categoriasSelecionadas: tempoTriagem.categoria,
          isProcessando: false,
        });
      }
    });
  }
  
  retornaCategorias(){
      this.setState({ isProcessando: true });
      this.props.firebase.categorias().once('value', snapshot => {
        const categoriaObject = snapshot.val();
        if (categoriaObject) {
          const listCategoria = Object.keys(categoriaObject).map(key => ({
            ...categoriaObject[key],
            uid: key,
          }));
          this.setState({
            categoriaList: listCategoria,
            isProcessando: false,
            statusOk: true,
          });
        } else {
          this.setState({ categoriaList: null, isProcessando: false, statusOk: false});
        }
      }, error =>{
          this.setState({ categoriaList: null, isProcessando: false, error: error, statusOk: false});
      });
  }

  componentDidMount() {  
    this.retornaCategorias();
    this.getTriagem();
  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
  }

  onChange = event => {  
    this.setState({ [event.target.name]: event.target.value });
  };

  async retornaQtdeVagas(categoria){
    var response = {isValido: false}
    var qtdePilotosInscritos = 0;
    var qtdeMaximaPiloto = 0;
    var msgQtdeVagas = "";

    var result = await this.props.firebase.campeonatos().once('value').then(function(snapshot) {
      const campeonatosObject = snapshot.val();
      if (campeonatosObject){
        const listCampeonatos = Object.keys(campeonatosObject).map(key => ({
          ...campeonatosObject[key],
          uid: key,
        }));

        let campeonatoSelecionadoByCategoria = listCampeonatos.filter(camp => camp.categoria.uid === categoria.uid);
        if(campeonatoSelecionadoByCategoria.length !== 0){
          if( typeof campeonatoSelecionadoByCategoria[0].lista_pilotos !== "undefined"){
            qtdePilotosInscritos = Object.values(campeonatoSelecionadoByCategoria[0].lista_pilotos).filter(piloto => piloto.inscricaoPaga === 'SIM').length
            qtdeMaximaPiloto = campeonatoSelecionadoByCategoria[0].qtde_max_piloto;
            msgQtdeVagas = "Até o momento, o Campeonato para a categoria " + categoria.nome_categoria + " está com "+qtdePilotosInscritos + " piloto(s) confirmado(s), e possuimos um total de 20 vagas.";
            response = { qtdePilotosInscritos: qtdePilotosInscritos, 
                         qtdeMaximaPiloto: qtdeMaximaPiloto, 
                         msgQtdeVagas:msgQtdeVagas,
                         isValido: true  };
          }else{
            response = { qtdePilotosInscritos: 0, 
                         qtdeMaximaPiloto: 0, 
                         msgQtdeVagas:0,
                         isValido: false  };
          }
        }else{
          response = { qtdePilotosInscritos: 0, 
                       qtdeMaximaPiloto: 0, 
                       msgQtdeVagas:0,
                       isValido: false  };
        }
      }else{
        response = { qtdePilotosInscritos: 0, 
                       qtdeMaximaPiloto: 0, 
                       msgQtdeVagas:0,
                       isValido: false  };
      }
      return response; 
    }).catch(error => {
      this.setState({ isProcessando: false, error: error, statusOk: false, });
    })
    
    return result;
  }


  EnviaEmail = event => {
      const {nomeUsuario, email, categoria, categoriaList, isSucessoRetornaVaga, categoriasSelecionadas } = this.state;
      const templateId = 'AlocacaoCategoria';
      this.setState({ isProcessando: true });
      
      for(let cat of Object.keys(categoriasSelecionadas)) {
        this.retornaQtdeVagas(categoriasSelecionadas[cat]).then(result => {
          if(result.isValido){
            var emailParam = {categoria: categoriasSelecionadas[cat].nome_categoria, nome_piloto: nomeUsuario,  from_name: "driveproseries@gmail.com", to_name: email};
            emailParam.msgQtdeVagas = result.msgQtdeVagas;
            emailjs.send('Envio_Email_DPS', templateId, emailParam).then(res => {  
              this.setState({ isProcessando: false, statusOk: true, error: 'E-mail enviado com sucesso!'});
              event.preventDefault();
              event.persist()
            }).catch(error => {
              this.setState({ isProcessando: false, statusOk: false, error: error });
              event.preventDefault();
              event.persist()
            });  
          }
        }).catch(error => {
          this.setState({ isProcessando: false, error: error, statusOk: false, });
        })
      }
      event.preventDefault();
      event.persist()
      
      /*emailjs.send('Envio_Email_DPS', templateId, emailParam).then(res => {
            this.setState({ isProcessando: false, statusOk: true, error: 'E-mail enviado com sucesso!'});
            event.preventDefault();
            event.persist()
          }).catch(error => {
            this.setState({ isProcessando: false, statusOk: false, error: error });
            event.preventDefault();
            event.persist()
          });*/
  }
  
  onSubmit = event => {
      const {uid, categoria, categoriaList, categoriasSelecionadas } = this.state;
      this.setState({ isProcessando: true });
      
      //if(this.state.categoriasSelecionadas.size === 0){
      //  this.setState({ isProcessando: false, statusOk: false, error: 'Informe pelo menos uma categoria.'});
      //}else{
        this.props.firebase.user(uid).child('categoria').set(this.state.categoriasSelecionadas).then(() => {
          this.setState({ isProcessando: false, statusOk: true, error: 'Registro atualizado com sucesso!'});
        }).catch(error => {
          this.setState({ isProcessando: false, statusOk: false, error: error });
        })
      //}

      
      /*this.props.firebase.user(uid).update({
          categoria: this.state.categoriasSelecionadas
      }).then(() => {
          this.setState({ isProcessando: false, statusOk: true, error: 'Registro atualizado com sucesso!'});
      }).catch(error => {
          this.setState({ isProcessando: false, statusOk: false, error: error });
      })*/
      
      event.preventDefault();
  }
  
  handleSelect = event => {
        let { categoriaList, categoriasSelecionadas } = this.state;
        let categoriaSelecionada = categoriasSelecionadas[event.target.value];
        
        if(categoriaSelecionada === undefined || null){
          let auxCategoria = categoriaList.filter(categoria => categoria.uid === event.target.value)[0];
          this.listaCategoriasSelecionadas = categoriasSelecionadas;
          this.listaCategoriasSelecionadas[auxCategoria.uid] = auxCategoria
        }else{
          if(Object.keys(categoriasSelecionadas).length > 0){
            let novaLista = new Map();
            for (let chave of Object.keys(categoriasSelecionadas)){
              if (chave !== event.target.value){
                novaLista[chave] = categoriasSelecionadas[chave];
              }
            }
            this.listaCategoriasSelecionadas = novaLista;
          }else{
            let novaLista = new Map();
            for (let chave of Object.keys(this.listaCategoriasSelecionadas)){
              if (chave !== event.target.value){
                novaLista[chave] = this.listaCategoriasSelecionadas[chave];
              }
            }
            this.listaCategoriasSelecionadas = novaLista;
          }
        }
        this.setState({categoriasSelecionadas: this.listaCategoriasSelecionadas})
  }

  ZeraTempos = event => {
    this.setState({ isProcessando: true });
    let auxCategoria = this.state.categoriaList.filter(cat => cat.nome_categoria === CONSTANTES.SEM_CATEGORIA)[0];
    let mapCategoria = new Map();
    mapCategoria[auxCategoria.uid] = auxCategoria; 
    this.props.firebase.user(this.state.uid).update({
      jogoEscolhido: '',
      provas: [],
      categoria: mapCategoria 
    }).then(() => {
      this.setState({ isProcessando: true, statusOk: true, statusZeraTempos: true, error: 'O usuário teve os seus tempos e sua alocação nas categorias zerados com sucesso. Aguarde o refresh da pagina.'});
    }).catch(error => {
      this.setState({ isProcessando: false, statusOk: false, error: error });
    })
    event.preventDefault();
  }
  

  render() {
    const { nomeUsuario, endereco, complemento, cidade, estado, cep, email, gameTag, psnPlus, psn, utilizaPsnPlus, isProcessando, error, categoriaList, categoria, statusOk, provas, statusPaginaInvalida, jogoEscolhido, jogoDisponivel, celular, categoriasSelecionadas, dataCadastro } = this.state;
    
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{error}</p>)
      }else{
        return <p className="styleError">{error}</p>
      }
    }

    const redirect = () => {
      if(statusPaginaInvalida){
        return (<Redirect to={{pathname: "/"}} />)
      }
      if (this.state.statusZeraTempos){
        return (<Redirect to={ROUTES.LISTAGEM_PILOTOS} delay={10000} />)
      }
    }

    const renderZeraTempoButton = (roles) => {
      if(roles.ADMIN === ROLES.ADMIN){
        return(
          <Form.Row className="row-button">
            <Button variant="primary" type="submit" onClick={this.ZeraTempos}>
              Zerar tempos
            </Button>
          </Form.Row>
        );
      }
    }

    const renderProvaTriagem = (prova) => {
        return (
            <Form.Row key={"formRow" + prova.nome_prova}>
                <Form.Group as={Col} key={"formGrid" + prova.nome_prova} controlId={"formGrid" + prova.nome_prova}>
                    <Form.Label>Nome da Prova</Form.Label>
                    <Form.Control name={prova.nome_prova} readOnly value={prova.nome_prova}/>
                </Form.Group>
                
                <Form.Group key={"tempo" + prova.nome_prova} controlId={"tempo" + prova.nome_prova}>
                    <Form.Label>1º Tempo </Form.Label>
                    <MaskedFormControl type='text' disabled name={prova.nome_prova} mask='1:11.111' value={prova.tempo} placeholder="1:27.625"/>
                </Form.Group>
            </Form.Row>
        );
    }
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form onSubmit={this.onSubmit}>
          <LoadingOverlay active={isProcessando}
                          spinner
                          text='Aguarde!'>
            {redirect()}              
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Detalhes do Piloto</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <Card.Text className="wordStyle">
                  <Form.Group controlId="formGridNome">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control name="nomeUsuario" readOnly value={nomeUsuario}/>
                  </Form.Group>
                  <Form.Group controlId="formGridEndereco">
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control name="endereco" readOnly value={endereco}/>
                  </Form.Group>
                
                  <Form.Group controlId="formGridBairro">
                    <Form.Label>Complemento</Form.Label>
                    <Form.Control name="complemento" readOnly value={complemento}/>
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridCidade">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control name="cidade" readOnly value={cidade}/>
                    </Form.Group>
                    
                    <Form.Group as={Col} controlId="formGridEstado">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control name="estado" readOnly value={estado}/>
                    </Form.Group>
                    
                    <Form.Group as={Col} controlId="formGridCep">
                      <Form.Label>Cep</Form.Label>
                      <Form.Control name="cep" readOnly value={cep}/>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control name="email" readOnly value={email}/>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="formGridCelular">
                      <Form.Label>Celular</Form.Label>
                      <Form.Control name="celular" readOnly value={celular}/>
                    </Form.Group>
                    
                    <Form.Group as={Col} md="4" controlId="formGridPsnPlus">
                      <Form.Label>PSN Plus</Form.Label>
                      <Form.Control className={utilizaPsnPlus ? "backGroundColorVerde" : "backGroundColorVermelho"} name="psnPlus" readOnly value={psnPlus}/>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="formGridPsn">
                      <Form.Label>PSN</Form.Label>
                      <Form.Control className={utilizaPsnPlus ? "backGroundColorVermelho" : "backGroundColorVerde"} name="psn" readOnly value={psn}/>
                    </Form.Group>
                    
                  </Form.Row>
                  <Form.Row>
                    <Form.Check type="checkbox" disabled name="utilizaPsnPlus" label="Utilizar conta da PSN Plus para identificação" checked={utilizaPsnPlus} />  
                  </Form.Row>
                  {provas.map(renderProvaTriagem)}
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="formGridJogo">
                      <Form.Label>Jogo em que foi realizado a tomada de tempo</Form.Label>
                      <Form.Control required disabled as="select" value={jogoEscolhido} name="jogoEscolhido">
                      {jogoDisponivel.map(jogo => (
                        <option key={jogo} value={jogo}> {jogo}</option>
                      ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formGridDataCadastro">
                      <Form.Label>Data do Cadastro</Form.Label>
                      <Form.Control name="dataCadastro" readOnly value={dataCadastro}/>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formCategoriasDisponiveis">
                      <Form.Label>Categorias Disponiveis</Form.Label>
                      <Form.Control as="select" multiple onDoubleClick={this.handleSelect}>
                        {categoriaList.map(categoria => (
                          <option key={categoria.uid} value={categoria.uid}>{categoria.nome_categoria}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    
                    <Form.Group as={Col} controlId="formCategoriasSelecionadas">
                      <Form.Label>Categorias Selecionadas</Form.Label>
                      <Form.Control as="select" multiple disabled>
                        {Object.keys(categoriasSelecionadas).map((uid) => 
                          <option key={uid} value={categoriasSelecionadas[uid]}>{categoriasSelecionadas[uid].nome_categoria}</option>
                        )}
                      </Form.Control>
                    </Form.Group>
                    
                    
                    {/*<Form.Group as={Col} md="4" controlId="formGridCategoria">
                      <Form.Label>Categoria</Form.Label>
                      <Form.Control required as="select" name="categoria" value={categoria || ' '} onChange={this.onChange}>
                          {categoriaList.map(categoria => (
                              <option key={categoria.uid} value={categoria.nome_categoria}> {categoria.nome_categoria}</option>
                          ))}
                      </Form.Control>
                    </Form.Group>*/}
                  </Form.Row>
                  {renderMessage()}
                  <Form.Row>
                    <Form.Row className="row-button">
                      <Button variant="primary" type="submit">
                        Salvar Alteração
                      </Button>
                    </Form.Row>
                    <Form.Row className="row-button">
                      <Button variant="primary" type="submit" onClick={this.EnviaEmail}>
                        Enviar e-mail
                      </Button>
                    </Form.Row>
                    {renderZeraTempoButton(authUser.roles)}
                  </Form.Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </LoadingOverlay>
        </Form>
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.DIRETOR]);

const PilotoDetalheForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(PilotoDetalheBase);

export default PilotoDetailPage;
export { PilotoDetalheForm };