import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  
  <div className="dropdown-item" type="button" onClick={firebase.doSignOut}>
    Logout
  </div>
);

export default withFirebase(SignOutButton);