import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import '../css/paddockleague.css';
import LoadingOverlay from 'react-loading-overlay';
import { Button, Modal, Card } from 'react-bootstrap'
import * as CONSTANTES from '../../constants/constantes';
import moment from 'moment';
//import {NotificationManager} from 'react-notifications';
//import 'react-notifications/lib/notifications.css';

class Categoria extends Component {

  constructor(props) {
    super(props);
    //let infoCategoria = this.props.location.state.categoria;
    
    this.state = {
      isProcessando: true,
      nome_categoria: '',
      descricao: '',
      posicao: '',
      url_categoria: '',
      id_categoria: this.props.match.params.id,

      msgRetorno: '',
      statusOk: false,
      show: false, 
    };
  }
  componentDidMount() {
    this.getCategoria(this.props.match.params.id);
  }
  
  componentWillUnmount() {
    this.props.firebase.categorias().off();
  }

  onClick = (event) => {
    this.setState({ show: true })
    event.preventDefault();;
  }
  
  getCategoria = (idCategoria) => {
    this.props.firebase
      .categoria(idCategoria)
      .once('value', snapshot => {
        const categoria = snapshot.val();
        if (categoria) {
          this.setState({
            nome_categoria: categoria.nome_categoria,
            descricao: categoria.descricao,
            posicao: categoria.posicao,
            url_categoria: categoria.url_categoria,
            isProcessando: false,
            statusOk: true,
          });
        } else {
          this.setState({
            nome_categoria: '',
            descricao: '',
            posicao: '',
            url_categoria: '',
            isProcessando: false,
            statusOk: false,
            msgRetorno: 'Ocorreu um problema ao buscar a categoria selecionada. Tente novamente', 
          });
        }
      }, error =>{
        this.setState({
            isProcessando: false,
            statusOk: false,
            msgRetorno: error, 
        });
			});
  };
  /*createNotification = (type) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info('Info message');
          break;
        case 'success':
          NotificationManager.success('Success message', 'Title here');
          break;
        case 'warning':
          NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      }
    };
  };*/
 
  render() {
    const { nome_categoria, descricao, posicao, url_categoria ,isProcessando, statusOk, msgRetorno, show, id_categoria } = this.state;
    let podeSeInscrever = false;

    const renderBtnInscricao = (authUser) =>{
      if (authUser == null){
        return (<h4 className="wordStyle">Para se inscrever no campeonato, é necessário realizar o cadastro.</h4>)
      }

      if((typeof authUser.categoria[id_categoria]) === "undefined"){
        return (<h4 className="wordStyle">Sua triagem ainda não foi concluida ou você não está apto a participar dessa categoria. Por favor aguarde!</h4>)
      }else {
        if(authUser.categoria[id_categoria] !== undefined){
          if(authUser.categoria[id_categoria].nome_categoria !== nome_categoria){
            return (<h4 className="wordStyle">De acordo com a sua categoria, você não pode participar deste campeonato. </h4>)
          }else{
            podeSeInscrever = true;
          }
        }
        
      }
    }

    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess wordStyle">{msgRetorno}</p>)
      }else{
        //if(msgRetorno.length != 0){
          //NotificationManager.success('Success message', 'Title here');
        //}
        return (<p className="styleError wordStyle">{msgRetorno}</p>)
      }
    }
    const handleClose = () => {
      this.setState({ show: false })
    };
    /*const handleShow = () => {
      this.setState({ show: true })
    };*/
    const handleInscricaoCampeonato = (authUser, event) => {
      let idCategoria = this.state.id_categoria;
      this.setState({ show: false, isProcessando: true })
      this.setState({ isProcessando: true });
      this.props.firebase.campeonatos().once('value', snapshot => {
        let campeonatosObject = snapshot.val();
        
        if(campeonatosObject){
          let listaCampeonatos = Object.keys(campeonatosObject).map(key => ({
            ...campeonatosObject[key],
            uid: key,
          }));

          let campeonato = listaCampeonatos.filter(camp => camp.categoria.uid === idCategoria && camp.ind_ativo === true)[0];
          
          if(campeonato){
            let listaPilotos = new Map();
            let pilotoInscrito = null;

            if(campeonato.lista_pilotos){
              listaPilotos = new Map(Object.entries(campeonato.lista_pilotos));
              pilotoInscrito = listaPilotos.get(authUser.uid);
            }
            
            
            if(pilotoInscrito){
              this.setState({ isProcessando: false, statusOk: false, msgRetorno: 'Você já está inscrito para este campeonato.' });
            }else if(listaPilotos.size >= campeonato.qtde_max_piloto){
              /*Adicionar a logica para pegar a quantidade de pilotos confirmados*/
              this.setState({ isProcessando: false, statusOk: false, msgRetorno: 'O campeonato disponivel para essa categoria atingiu o número máximo de participantes.' });
            }else{
              let piloto = {}
              piloto.uid = authUser.uid;
              piloto.nomeUsuario = authUser.nomeUsuario;
              piloto.email = authUser.email;
              piloto.psn = authUser.psn;
              piloto.psnPlus = authUser.psnPlus;
              piloto.utilizaPsnPlus = authUser.utilizaPsnPlus;
              piloto.dataHoraInscricao = moment().format("DD/MM/YYYY HH:mm:ss");
              piloto.inscricaoPaga = "NÃO";
              piloto.formaPagamento = "NÃO CONFIRMADO";
              //campeonatoSelecionado === undefined || null
              if (authUser.celular === undefined || null) {
                piloto.celular = 'Telefone não cadastrado';
              }else{
                piloto.celular = authUser.celular;
              }

              if(campeonato.lista_pilotos){
                listaPilotos = campeonato.lista_pilotos;
                listaPilotos[authUser.uid] = piloto
              }else{
                listaPilotos[authUser.uid] = piloto
              }
              /**
               * - Adicionar mais informacoes para o objeto PILOTO
               * - Verificar se tem outro jeito de adicionar registro, para dar mais segurança
               */
              this.props.firebase.campeonato(campeonato.uid).update({
                lista_pilotos: listaPilotos,
              }).then(() => {
                this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Inscrição realizada com sucesso!' });
              }).catch(error => {
                this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
              })
            }
          } else{
            this.setState({ isProcessando: false, statusOk: false, msgRetorno: 'Inscrição não permitida, pois a temporada já foi iniciada ou está em andamento!' });
          }
        }else{
          this.setState({ isProcessando: false, statusOk: false, msgRetorno: 'Inscrição não permitida, pois a temporada não foi iniciada ou está em andamento!' });
        }
      }, error =>{
        this.setState({ isProcessando: false, msgRetorno: error, statusOk: false});
      })
      event.preventDefault();
    };
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Categoria {nome_categoria} </Card.Title>
                <Card.Text className="black-color wordStyle">
                  {descricao}
                  <img className="img-categoria" alt="Imagem da categoria"  id="Image4_img" src={url_categoria} />
                </Card.Text>
                {renderBtnInscricao(authUser)}
                {renderMessage()}
                <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false}>
                  <Modal.Header closeButton>
                    <Modal.Title className="wordStyle centered">{CONSTANTES.DRIVE_PRO_SERIES}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="wordStyle">
                      Você está prestes a se inscrever em um campeonato da categoria {nome_categoria}, tem certeza disso? 
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="wordStyle" variant="secondary" onClick={() => handleClose()}> Não </Button>
                    <Button className="wordStyle" variant="primary" onClick={(event) => handleInscricaoCampeonato(authUser, event) }>
                        Sim
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Button className="wordStyle" variant="primary" disabled={!podeSeInscrever} type="submit" onClick={(event) => this.onClick(event)}>
                    Inscreva-se
                </Button>
              </Card.Body>
            </Card>
          </LoadingOverlay>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default compose(
  withFirebase
)(Categoria);