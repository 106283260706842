import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import SignOutButton from '../SignOut';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { NavDropdown, Nav } from "react-bootstrap";

import { DropdownSubmenu, NavDropdownMenu} from "react-bootstrap-submenu";

class AccountOptionProfile extends Component {
  /*constructor(props) {
    super(props);
    <NavLink key='Categorias' disable className='dropdown-item' to={`/categorias/`}> Categorias </NavLink>
                        <NavLink key='Solicitar Análise' className='dropdown-item' to={`/categorias/`}> Solicitar Análise </NavLink>
                        <NavLink key='Resultado Análise' className='dropdown-item' to={`/categorias/`}> Resultado Análise </NavLink>
  }*/
  
  render() {
    const renderMenu = (roles)=>{
      if(roles.PILOTO === ROLES.PILOTO){
        return(
          <React.Fragment>
            <NavLink key='Perfil' className='dropdown-item' to={ROUTES.ACCOUNT_PROFILE}> Perfil </NavLink>
            <NavLink key='Triagem' className='dropdown-item' to={ROUTES.TRIAGEM}> Triagem </NavLink>            
          </React.Fragment>
        );
      }else if(roles.IMPRENSA === ROLES.IMPRENSA){
        return(
          <React.Fragment>
            <NavLink key='Listar Noticias' className='dropdown-item' to={ROUTES.LISTAGEM_NOTICIAS}>Listagem de Noticias</NavLink>
            <NavLink key='Cadastrar Noticia' className='dropdown-item' to={ROUTES.CADASTRO_NOTICIA}>Cadastrar Noticias</NavLink>
          </React.Fragment>
        );
      }else if(roles.ADMIN === ROLES.ADMIN){
        return(
          <React.Fragment>
            <NavLink key='Painel Administrativo'  className='dropdown-item menuAlignSenhaAdministrativoNotificacao' to={ROUTES.PAINEL_ADMINISTRATIVO}>Administrativo</NavLink>
            
            <NavDropdown title="Tabela">
              <NavLink key='Cadastro Tabela' className='dropdown-item' to={ROUTES.CADASTRO_TABELA}>Cadastro Tabela</NavLink>
              <NavLink key='Listagem Tabela' className='dropdown-item' to={ROUTES.LISTAGEM_TABELA}>Listagem Tabela</NavLink>
            </NavDropdown>
            
            <NavDropdown title="Calendario">
              <NavLink key='Cadastro Calendario' className='dropdown-item' to={ROUTES.CADASTRO_CALENDARIO}>Cadastro Calendario</NavLink>
              <NavLink key='Listagem Calendario' className='dropdown-item' to={ROUTES.LISTAGEM_CALENDARIO}>Listagem Calendario</NavLink>
            </NavDropdown>
            
            <NavDropdown title="Notícias">
              <NavLink key='Cadastro Noticia' className='dropdown-item' to={ROUTES.CADASTRO_NOTICIA}>Cadastrar Notícia</NavLink>
              <NavLink key='Listagem Noticia' className='dropdown-item' to={ROUTES.LISTAGEM_NOTICIAS}>Listagem Notícia</NavLink>
            </NavDropdown>
            
            <NavDropdown title="Pilotos">
              <NavLink key='Listagem pilotos' className='dropdown-item' to={ROUTES.LISTAGEM_PILOTOS}>Listagem de Pilotos</NavLink>
            </NavDropdown>

            <NavDropdown title="Categorias">
              <NavLink key='Cadastrar Categoria' className='dropdown-item' to={ROUTES.CADASTRO_CATEGORIA}>Cadastrar Categorias</NavLink>
              <NavLink key='Listagem Categorias' className='dropdown-item' to={ROUTES.LISTAGEM_CATEGORIA}>Listagem Categoria</NavLink>
            </NavDropdown>

            <NavDropdown title="Provas">
              <NavLink key='Cadastrar Provas' className='dropdown-item' to={ROUTES.CADASTRO_PROVAS}>Cadastro de Provas</NavLink>
            </NavDropdown>
            
            <NavDropdown title="Campeonatos">
              <NavLink key='Cadastrar Campeonato' className='dropdown-item' to={ROUTES.CADASTRO_CAMPEONATO}>Cadastro de Campeonato</NavLink>
              <NavLink key='Listagem Campeonatos' className='dropdown-item' to={ROUTES.LISTAGEM_CAMPEONATOS}>Listagem Campeonatos</NavLink>
            </NavDropdown>
            
            <NavDropdown title="Temporadas">
              <NavLink key='Cadastrar Temporada' className='dropdown-item' to={ROUTES.CADASTRO_TEMPORADA}>Cadastro de Temporada</NavLink>
              <NavLink key='Listagem Temporadas' className='dropdown-item' to={ROUTES.LISTAGEM_TEMPORADAS}>Listagem Temporadas</NavLink>
            </NavDropdown>
            
            <NavDropdown title="Patrocinadores">
              <NavLink key='Cadastrar Patrocinadores' className='dropdown-item' to={ROUTES.CADASTRO_PATROCINADOR}>Cadastrar Patrocinadores</NavLink>
              <NavLink key='Listagem Patrocinadores' className='dropdown-item' to={ROUTES.LISTAGEM_PATROCINADOR}>Listagem Patrocinadores</NavLink>
            </NavDropdown>
            
            
            <NavLink key='Enviar Notificacao' className='dropdown-item menuAlignSenhaAdministrativoNotificacao' to={ROUTES.ENVIO_NOTIFICACAO}> Enviar Notificação </NavLink>
            
          </React.Fragment>
        );
      }else if(roles.DIRETOR === ROLES.DIRETOR){
        return(
          <React.Fragment>
            <NavDropdown title="Tabela">
              <NavLink key='Listagem Tabela' className='dropdown-item' to={ROUTES.LISTAGEM_TABELA}>Listagem Tabela</NavLink>
            </NavDropdown>

            <NavDropdown title="Pilotos">
              <NavLink key='Listagem pilotos' className='dropdown-item' to={ROUTES.LISTAGEM_PILOTOS}>Listagem de Pilotos</NavLink>
            </NavDropdown>
            
          </React.Fragment>
        );
      }
    }
/**
 * 
 *  return (
      <AuthUserContext.Consumer>
			{authUser =>
				<React.Fragment>
          <NavLink key='Senha' className='dropdown-item' to={ROUTES.PASSWORD_CHANGE}> Senha </NavLink>
          {renderMenu(authUser.roles)}
          <NavDropdown.Divider />
          <SignOutButton/>
        </React.Fragment>
			}
      </AuthUserContext.Consumer>
    );
 * 
 * <DropdownSubmenu title="Tabela">
            <NavDropdown.Item key='Cadastro Tabela' href={ROUTES.CADASTRO_TABELA}>Cadastro Tabela</NavDropdown.Item>
            <NavDropdown.Item key='Listagem Tabela' href={ROUTES.LISTAGEM_TABELA}>Listagem Tabela</NavDropdown.Item>
          </DropdownSubmenu>
          <DropdownSubmenu title="Calendário">
            <NavDropdown.Item key='Cadastro Calendario' href={ROUTES.CADASTRO_TABELA}>Cadastro Tabela</NavDropdown.Item>
            <NavDropdown.Item key='Listagem Calendario' href="#action/8.1">Listagem Tabela</NavDropdown.Item>
          </DropdownSubmenu>
 */
    return (
      <AuthUserContext.Consumer>
			{authUser =>
				<React.Fragment>
          <NavLink key='Senha' className='dropdown-item menuAlignSenhaAdministrativoNotificacao' to={ROUTES.PASSWORD_CHANGE}> Senha </NavLink>
          {renderMenu(authUser.roles)}
          <NavDropdown.Divider />
          <SignOutButton/>
        </React.Fragment>
			}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(AccountOptionProfile);
