import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import '../../css/paddockleague.css';

const TemporadasPage = () => (
  <TemporadaForm />
);
const INITIAL_STATE = {
  nome_temporada: '',
  lista_temporadas_selecionadas: [],
  ind_ativo: false,

  campeonato_list: [],
  msgRetorno: '',
  isProcessando: false,
  statusOk: false,
  isInvalido: false,
};
class TemporadaFormBase extends Component {
  
  constructor(props) {
    super(props);
    this.listaTemporadaSelecionada = new Map();

    this.state = {
      nome_temporada: '',
      lista_temporadas_selecionadas: [],
      ind_ativo: false,

      campeonato_list: [],
      msgRetorno: '',
      isProcessando: false,
      statusOk: false,
      isInvalido: false,
    };
  }
  
  retornaCampeonato(){
    this.setState({ isProcessando: true });
    this.props.firebase.campeonatos().once('value', snapshot => {
        const campeonatoObject = snapshot.val();
        if (campeonatoObject) {
          const listCampeonato = Object.keys(campeonatoObject).map(key => ({
            ...campeonatoObject[key],
            uid: key,
          }));

          this.setState({
            campeonato_list: listCampeonato.filter(campeonato => campeonato.ind_ativo === false && campeonato.ind_preparado === true),
            isProcessando: false,
            statusOk: true,
          });
        } else {
          this.setState({ campeonato_list: [], isProcessando: false, statusOk: false});
        }
      }, error =>{
          this.setState({ campeonato_list: [], isProcessando: false, error: error, statusOk: false});
      });
  }
  componentWillUnmount() {
      this.props.firebase.campeonatos().off();
  }
  componentDidMount() {  
    this.retornaCampeonato();
  }
  
  onSubmit = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isInvalido: true });
    }else{
      let campeonatoSelecionados = new Map(Object.entries(this.state.lista_temporadas_selecionadas)); 
      
      if(campeonatoSelecionados.length === 0){
        this.setState({ msgRetorno: 'Adicione os campeonatos que serão disponibilizadas na Temporada!', statusOk: false});
      }else{
        this.setState({ isInvalido: false, isProcessando: true, });
        this.props.firebase.temporadas().push({
          nome_temporada: this.state.nome_temporada,
          lista_temporadas_selecionadas: this.state.lista_temporadas_selecionadas,
          ind_ativo: this.state.ind_ativo,
          ind_preparado: false,
        }).then(() => {
          this.setState({ ...INITIAL_STATE });
          this.setState({msgRetorno: 'Campeonato cadastrado com sucesso!', isProcessando: false, statusOk: true});
        }).catch(error => {
          this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
        })
      }
    }
    event.preventDefault();
  };
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckBox = event => {
    this.setState({ ind_ativo: !this.state.ind_ativo,});
  }

  handleSelect = event => {
        let { campeonato_list, lista_temporadas_selecionadas } = this.state;
        let campeonatoSelecionado = lista_temporadas_selecionadas[event.target.value];
        if(campeonato_list.length === 0){
          this.setState({ msgRetorno: 'Não existe campeonatos disponiveis', isProcessando: false, statusOk: false});
          return;
        }

        if(campeonatoSelecionado === undefined || null){
          let auxCampeonato = campeonato_list.filter(campeonato => campeonato.uid === event.target.value)[0];
          this.listaTemporadaSelecionada = lista_temporadas_selecionadas;
          this.listaTemporadaSelecionada[auxCampeonato.uid] = auxCampeonato
        }else{
          let novaLista = new Map();
          for (let chave of Object.keys(this.listaTemporadaSelecionada)){
            if (chave !== event.target.value){
              novaLista[chave] = this.listaTemporadaSelecionada[chave];
            }
          }
          this.listaTemporadaSelecionada = novaLista; 
        }
        this.setState({lista_temporadas_selecionadas: this.listaTemporadaSelecionada})
  }

  render() {
    let { campeonato_list, lista_temporadas_selecionadas, msgRetorno, isProcessando, statusOk, isInvalido} = this.state;
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    
    const isInvalidFields = false;
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form noValidate validated={isInvalido} onSubmit={this.onSubmit}>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                <Card className="cardPainelTexto">
                  <Card.Body>
                    <Card.Title className="cardPainelTitulo">Cadastro de Temporadas</Card.Title>
                    <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                    <Card.Text className="wordStyle">
                      <Form.Row>
                          <Form.Group as={Col} controlId="formNomeTemporada">
                              <Form.Label>Nome da temporada</Form.Label>
                              <Form.Control required pattern="\S(.*\S)?" maxLength={50} name="nome_temporada" type="text" placeholder="1ª Temporada 2020" autoComplete="on" onChange={this.onChange}/>
                              <Form.Control.Feedback type="invalid">
                              Informe um Nome da Temporada
                              </Form.Control.Feedback>
                          </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} controlId="formCampeonatosDisponiveis">
                          <Form.Label>Campeonatos Disponiveis</Form.Label>
                          <Form.Control as="select" multiple onDoubleClick={this.handleSelect}>
                            {campeonato_list.map(temporada => (
                              <option key={temporada.uid} value={temporada.uid}>{temporada.nome_campeonato}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        
                        <Form.Group as={Col} controlId="formCampeonatosSelecionadas">
                          <Form.Label>Campeonatos Selecionados</Form.Label>
                          <Form.Control as="select" multiple disabled>
                            {Object.keys(lista_temporadas_selecionadas).map((uid) => 
                              <option key={uid} value={lista_temporadas_selecionadas[uid].nome_campeonato}>{lista_temporadas_selecionadas[uid].nome_campeonato}</option>
                            )}
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>
                      <Form.Group id="formGridCheckbox">
                        <Form.Check type="checkbox" name="ind_ativo" label="Ativo" onChange={this.onChangeCheckBox}/>
                      </Form.Group>
                      {renderMessage()}
                      <Button disabled={isInvalidFields}  variant="primary" type="submit">
                          Cadastrar
                      </Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
            </LoadingOverlay>
          </Form>      
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const TemporadaForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(TemporadaFormBase);

export default TemporadasPage;
export { TemporadaForm };