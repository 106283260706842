import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import '../../css/paddockleague.css';

const AdministrativoPage = () => (
  
    <AdministrativoForm />
    
   
);
const INITIAL_STATE = {
    linkCalendarioTemporada: '',
    linkCalendarioProximaTemporada: '',
    link_regulamento: '',

    msgRetorno: '',
    isProcessando: false,
    statusOk: false,
    isInvalido: false,
};
class AdministrativoFormBase extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
        sobre: '',
        link_regulamento: '',
        idVideoYouTube: '',
        tituloVideoYoutube: '',
        uid: '',

        msgRetorno: '',
        isProcessando: true,
        statusOk: false,
        isInvalido: false,
    };
     
  }
  setLinkRegulamento = (administrativoObject) => {
      this.setState({ link_regulamento: administrativoObject.link_regulamento,
                      sobre: administrativoObject.sobre,                     
                      uid: administrativoObject.uid })
  }
  setLinkYoutube = (administrativoObject) => {
      this.setState({ idVideoYouTube: administrativoObject.idVideoYouTube,
                      tituloVideoYoutube: administrativoObject.tituloVideoYoutube })
  }
  componentWillUnmount() {
      this.props.firebase.administrativos().off();
  }
  componentDidMount() {
      let administrativoObject = null;
      this.props.firebase.administrativos().once('value', snapshot => {
          
        administrativoObject = Object.keys(snapshot.val()).map(key => ({
            ...snapshot.val()[key],
            uid: key,
        }))[0]
        
    }).then(() => {
        this.setLinkRegulamento(administrativoObject);
        this.setLinkYoutube(administrativoObject);

        this.setState({ isProcessando: false })
    }).catch(error => {
        this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
    });  
  }

  onSubmit = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isInvalido: true });
    }else{
      this.setState({ isInvalido: false, isProcessando: true, });
      let uid = this.state.uid;

      this.props.firebase.administrativo(uid).update({
          link_regulamento: this.state.link_regulamento,
          sobre: this.state.sobre,
          idVideoYouTube: this.state.idVideoYouTube,
          tituloVideoYoutube: this.state.tituloVideoYoutube
      }).then(() => {      
          this.setState({ ...INITIAL_STATE });
          this.setState({msgRetorno: 'Informações cadastradas com sucesso!', isProcessando: false, statusOk: true});
          window.location.reload();
      }).catch(error => {
          this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
      })
    }
    event.preventDefault();
  };
  
  onChange = event => {
      this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { link_regulamento, sobre, idVideoYouTube, tituloVideoYoutube, msgRetorno, isProcessando, statusOk, isInvalido} = this.state;
    
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    
    const isInvalidFields = false;
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form noValidate validated={isInvalido} onSubmit={this.onSubmit}>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                <Card className="cardPainelTexto">
                    <Card.Body>
                        <Card.Title className="cardPainelTitulo">Painel Administrativo </Card.Title>
                        <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar </Card.Title>
                        <Card.Text className="wordStyle">
                            <Form.Row>
                                <Form.Group as={Col} controlId="formLinkRegulamento">
                                    <Form.Label>Link Regulamento</Form.Label>
                                    <Form.Control required pattern="\S(.*\S)?" name="link_regulamento" value={link_regulamento} type="text" autoComplete="on" onChange={this.onChange}/>
                                    <Form.Control.Feedback type="invalid">
                                    Informe o Link do Regulamento
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridSobre">
                                    <Form.Label>Sobre</Form.Label>
                                    <Form.Control required name="sobre" value={sobre} as="textarea" rows="3" autoComplete="on" onChange={this.onChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe a descricao
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridIdVideo">
                                    <Form.Label>Id Video Youtube</Form.Label>
                                    <Form.Control required pattern="\S(.*\S)?" name="idVideoYouTube" value={idVideoYouTube} type="text" autoComplete="on" onChange={this.onChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe o ID do video
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridIdVideo">
                                    <Form.Label>Título Vídeo</Form.Label>
                                    <Form.Control required pattern="\S(.*\S)?" name="tituloVideoYoutube" value={tituloVideoYoutube} type="text" autoComplete="on" onChange={this.onChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe o título do vídeo
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            {renderMessage()}
                            <Button disabled={isInvalidFields}  variant="primary" type="submit">
                                Salvar
                            </Button>
                        </Card.Text>
                    </Card.Body>
                </Card>
                
                
                
            </LoadingOverlay>
          </Form>      
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const AdministrativoForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(AdministrativoFormBase);

export default AdministrativoPage;
export { AdministrativoForm };