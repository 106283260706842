import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import '../../css/paddockleague.css';
import '../../css/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import * as CONSTANTES from '../../../constants/constantes'; 

const NoticiaPage = () => (
    <NoticiaForm />
);

const INITIAL_STATE = {
    titulo_noticia: '',
    editorState: EditorState.createEmpty(),
    data_publicacao: '',
    autor: '',

    msgRetorno: '',
    isProcessando: false,
    statusOk: false,
    isInvalido: false,
};
class NoticiaFormBase extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        titulo_noticia: '',
        editorState: EditorState.createEmpty(),
        data_publicacao: '',
        autor: '',

        msgRetorno: '',
        isProcessando: false,
        statusOk: false,
        isInvalido: false,
    };
  }
  
  componentWillUnmount() {
      this.props.firebase.noticias().off();
  }
  
  onSubmit = (event, authUser) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isInvalido: true });
    }else{
        this.setState({ isInvalido: false, isProcessando: true, });
        let conteudoNoticia = this.state.editorState.getCurrentContent();

        const blocks = convertToRaw(conteudoNoticia).blocks;
        const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        this.props.firebase.noticias().push({
            titulo_noticia: this.state.titulo_noticia,
            editorState: value,
            conteudo_html: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
            data_publicacao: new Date().toLocaleDateString('pt-BR', CONSTANTES.DATE_OPTIONS),
            data_timestamp: new Date().getTime() * -1,
            autor: authUser.nomeUsuario,
        }).then(() => {
            this.setState({ ...INITIAL_STATE });
            this.setState({msgRetorno: 'Notícia cadastrado com sucesso!', isProcessando: false, statusOk: true});
        }).catch(error => {
            this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
        })
    }
    event.preventDefault();
  };

  onEditorStateChange = editorState => {
      this.setState({ editorState });
  };
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  render() {
    let { editorState, msgRetorno, isProcessando, statusOk, isInvalido} = this.state;
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    
    const isInvalidFields = false;
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form noValidate validated={isInvalido} onSubmit={(event) => this.onSubmit(event, authUser)}>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                <Card className="cardPainelTexto">
                  <Card.Body>
                    <Card.Title className="cardPainelTitulo">Cadastro de Notícias</Card.Title>
                    <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                    <Card.Text className="wordStyle">
                      <Form.Row>
                        <Form.Group as={Col} controlId="formNomeCampeonato">
                          <Form.Label>Título</Form.Label>
                          <Form.Control required pattern="\S(.*\S)?" maxLength={100} name="titulo_noticia" type="text" placeholder="Título da Notícia" onChange={this.onChange}/>
                          <Form.Control.Feedback type="invalid">
                            Informe o Título da Notícia!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Editor editorState={editorState}
                        wrapperClassName="rich-editor demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                        placeholder="Qual será a noticia de hoje..." />
                      {renderMessage()}
                      <Button disabled={isInvalidFields}  variant="primary" type="submit">
                          Cadastrar
                      </Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
            </LoadingOverlay>
          </Form> 
                
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.IMPRENSA]);

const NoticiaForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NoticiaFormBase);

export default NoticiaPage;
export { NoticiaForm };