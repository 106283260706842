import React, { Component } from 'react';
import { Form, Button, Col, Card, Modal } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import '../../css/paddockleague.css';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import * as CONSTANTES from '../../../constants/constantes';

const CampeonatoDetailPage = () => (
    <CampeonatoDetalheForm /> 
);

class CampeonatoDetalheBase extends Component {
  constructor(props) {
    super(props);
    let infoCampeonato = props.location.state.campeonato;
    console.log(infoCampeonato)
    this.state = {
      isProcessando: false,
      uid: infoCampeonato.uid,
      categoria: infoCampeonato.categoria,
      data_fim: infoCampeonato.data_fim,
      data_inicio: infoCampeonato.data_inicio,
      descricao: infoCampeonato.descricao,
      ind_ativo: infoCampeonato.ind_ativo,
      lista_provas_selecionadas: infoCampeonato.lista_provas_selecionadas,
      nome_campeonato: infoCampeonato.nome_campeonato,
      qtde_max_piloto: infoCampeonato.qtde_max_piloto,
      percentual_prova: infoCampeonato.percentual_prova,
      lista_pilotos: infoCampeonato.lista_pilotos ? infoCampeonato.lista_pilotos: [],

      provas: [],
      ...props.location.state,
      msgRetorno: '',
      msgRetornoMelhores: '',
      statusOk: false,
      statusOkMelhores: false,
      show: false,
      showMelhores: false,
      piloto:{nomeUsuario: '', utilizaPsnPlus: '', psnPlus: '', psn: '', campeonatoUid: '', inscricaoPaga: '' },
      provaSelecionada: [],
    };
    this.onChange = this.onChange.bind(this); 
  }
  
  componentWillUnmount() {
    this.props.firebase.campeonato(this.props.match.params.id).off();
  }

  onChange = event => {  
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeConfirmaPagamento = event => {
    this.state.piloto.inscricaoPaga = event.target.value;
    this.setState({ [event.target.name]: event.target.value });
  }

  onChangeConfirmaFormaPagamento = event => {
    this.state.piloto.formaPagamento = event.target.value;
    this.setState({ [event.target.name]: event.target.value });
  }

  onChangeCheckBox = event => {
    this.setState({ ind_ativo: !this.state.ind_ativo,});
  }
  
  onSubmit = event => {
      const {uid, ind_ativo } = this.state;
      this.setState({ isProcessando: true });
      this.props.firebase.campeonato(uid).update({
          ind_ativo: ind_ativo
      }).then(() => {
          this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Registro atualizado com sucesso!'});
      }).catch(error => {
          this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
      })
      
      event.preventDefault();
  }

  onChangeMelhores = event => {
    this.state.provaSelecionada[event.target.name] = event.target.value;
  }

  render() {
    const { nome_campeonato, categoria, data_fim, data_inicio, descricao, ind_ativo, lista_provas_selecionadas, qtde_max_piloto, percentual_prova, lista_pilotos, isProcessando, msgRetorno, statusOk, show, showMelhores, provaSelecionada, piloto, inscricaoPaga, statusOkMelhores, msgRetornoMelhores } = this.state;
    
    const renderMessageMelhores = ()=>{
      if (statusOkMelhores){
        return (<p className="styleSuccess">{msgRetornoMelhores}</p>)
      }else{
        return <p className="styleError">{msgRetornoMelhores}</p>
      }
    }
    
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }

    const handleSalvarEstatistica = () => {
      let caminho = this.state.uid+'/lista_provas_selecionadas/'+provaSelecionada.uid;
      
      if(provaSelecionada['primeiroColocado'] === undefined || null || provaSelecionada['primeiroColocado'] === "NÃO INFORMADO"){
        this.setState({ statusOkMelhores: false, msgRetornoMelhores: 'Informe qual piloto foi o primeiro colocado!' });
      }else if(provaSelecionada['segundoColocado'] === undefined || null || provaSelecionada['segundoColocado'] === "NÃO INFORMADO"){
        this.setState({ statusOkMelhores: false, msgRetornoMelhores: 'Informe qual piloto foi o segundo colocado!' });
      }else if(provaSelecionada['terceiroColocado'] === undefined || null || provaSelecionada['terceiroColocado'] === "NÃO INFORMADO"){
        this.setState({ statusOkMelhores: false, msgRetornoMelhores: 'Informe qual piloto foi o terceiro colocado!' });
      }else if(provaSelecionada['polePosition'] === undefined || null || provaSelecionada['polePosition'] === "NÃO INFORMADO"){
        this.setState({ statusOkMelhores: false, msgRetornoMelhores: 'Informe qual piloto irá largar na pole position!' });
      }else if(provaSelecionada['melhorTempoPiloto'] === undefined || null || provaSelecionada['melhorTempoPiloto'] === "NÃO INFORMADO"){
        this.setState({ statusOkMelhores: false, msgRetornoMelhores: 'Informe qual piloto fez o melhor tempo!' });
      }else if(provaSelecionada['melhorTempo'] === undefined || null){
        this.setState({ statusOkMelhores: false, msgRetornoMelhores: 'Informe o tempo do melhor piloto!' });
      }else{
        this.props.firebase.campeonato(caminho).update({
          primeiroColocado: provaSelecionada['primeiroColocado'],
          segundoColocado: provaSelecionada['segundoColocado'],
          terceiroColocado: provaSelecionada['terceiroColocado'],
          polePosition: provaSelecionada['polePosition'],
          melhorTempoPiloto: provaSelecionada['melhorTempoPiloto'],
          melhorTempo: provaSelecionada['melhorTempo']
        }).then(() => {
          handleCloseMelhores();
          this.setState({ statusOk: true, msgRetorno: 'Informações salvas com sucesso!' });
        }).catch(error => {
          this.setState({ statusOk: false, msgRetorno: error });
        })
      }
      
    }

    const handleSalvar = () => {
      let caminho = this.state.piloto.campeonatoUid+'/lista_pilotos/'+this.state.piloto.uid;
      this.props.firebase.campeonato(caminho).update({
          inscricaoPaga: this.state.piloto.inscricaoPaga,
          formaPagamento: this.state.piloto.formaPagamento
      }).then(() => {
          this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Registro atualizado com sucesso!', show: false});
      }).catch(error => {
          this.setState({ isProcessando: false, statusOk: false, msgRetorno: error, show: false });
      })
    }

    const handleClose = () => {
      this.setState({ show: false, uid: null })
    };

    const handleShow = (piloto) => {
      piloto.campeonatoUid = this.state.uid;
      this.setState({ show: true , piloto: piloto})
    };

    const handleShowMelhores = (provaSelecionada) => {
      console.log(provaSelecionada)
      this.setState({ showMelhores: true, provaSelecionada: provaSelecionada});
    }

    const handleCloseMelhores = () => {
      this.state.provaSelecionada['primeiroColocado'] = 'NÃO INFORMADO';
      this.state.provaSelecionada['segundoColocado'] = 'NÃO INFORMADO';
      this.state.provaSelecionada['terceiroColocado'] = 'NÃO INFORMADO';
      this.state.provaSelecionada['polePosition'] = 'NÃO INFORMADO';
      this.state.provaSelecionada['melhorTempoPiloto'] = 'NÃO INFORMADO';
      this.state.provaSelecionada['melhorTempo'] = '';
      
      this.setState({ showMelhores: false, msgRetornoMelhores: '' })
    }

    const renderModalMelhores = (provaSelecionada) =>{
      if(provaSelecionada.lista_pilotos === undefined || null) return;
      return(
        <Modal show={showMelhores} onHide={() => handleCloseMelhores()} backdrop="static" keyboard={false} size="lg" className="wordStyle">
          <Modal.Header closeButton>
            <Modal.Title className="cardPainelSubTitulo">{CONSTANTES.DRIVE_PRO_SERIES} - Classificação - {provaSelecionada.nome_prova}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card.Text className="wordStyle">
              <Form.Row>
                
                <Form.Group as={Col} md="4" controlId="formPrimeiroColocado">
                  <Form.Label>Primeiro Colocado</Form.Label>
                  <Form.Control required as="select" name="primeiroColocado" value={provaSelecionada['primeiroColocado']} onChange={this.onChangeMelhores}>
                    <option key='NÃO INFORMADO' value='NÃO INFORMADO'>NÃO INFORMADO</option>
                    {Object.keys(provaSelecionada.lista_pilotos).map(uid => (
                      <option key={uid} value={provaSelecionada.lista_pilotos[uid].uid} >{provaSelecionada.lista_pilotos[uid].utilizaPsnPlus ? provaSelecionada.lista_pilotos[uid].psnPlus : provaSelecionada.lista_pilotos[uid].psn}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                
                <Form.Group as={Col} md="4" controlId="formSegundoColocado">
                  <Form.Label>Segundo Colocado</Form.Label>
                  <Form.Control required as="select" name="segundoColocado" value={provaSelecionada['segundoColocado']} onChange={this.onChangeMelhores}>
                    <option key='NÃO INFORMADO' value='NÃO INFORMADO'>NÃO INFORMADO</option>
                    {Object.keys(provaSelecionada.lista_pilotos).map(uid => (
                      <option key={uid} value={provaSelecionada.lista_pilotos[uid].uid} >{provaSelecionada.lista_pilotos[uid].utilizaPsnPlus ? provaSelecionada.lista_pilotos[uid].psnPlus : provaSelecionada.lista_pilotos[uid].psn}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                
                <Form.Group as={Col} md="4" controlId="formTerceiroColocado">
                  <Form.Label>Terceiro Colocado</Form.Label>
                  <Form.Control required as="select" name="terceiroColocado" value={provaSelecionada['terceiroColocado']} onChange={this.onChangeMelhores}>
                    <option key='NÃO INFORMADO' value='NÃO INFORMADO'>NÃO INFORMADO</option>
                    {Object.keys(provaSelecionada.lista_pilotos).map(uid => (
                      <option key={uid} value={provaSelecionada.lista_pilotos[uid].uid} >{provaSelecionada.lista_pilotos[uid].utilizaPsnPlus ? provaSelecionada.lista_pilotos[uid].psnPlus : provaSelecionada.lista_pilotos[uid].psn}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="formPolePosition">
                  <Form.Label>Pole Position</Form.Label>
                  <Form.Control required as="select" name="polePosition" value={provaSelecionada['polePosition']} onChange={this.onChangeMelhores}>
                    <option key='NÃO INFORMADO' value='NÃO INFORMADO'>NÃO INFORMADO</option>
                    {Object.keys(provaSelecionada.lista_pilotos).map(uid => (
                      <option key={uid} value={provaSelecionada.lista_pilotos[uid].uid} >{provaSelecionada.lista_pilotos[uid].utilizaPsnPlus ? provaSelecionada.lista_pilotos[uid].psnPlus : provaSelecionada.lista_pilotos[uid].psn}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                
                <Form.Group as={Col} md="4" controlId="formMelhorTempo">
                  <Form.Label>Melhor Tempo</Form.Label>
                  <Form.Control required as="select" name="melhorTempoPiloto" value={provaSelecionada['melhorTempoPiloto']} onChange={this.onChangeMelhores}>
                    <option key='NÃO INFORMADO' value='NÃO INFORMADO'>NÃO INFORMADO</option>
                    {Object.keys(provaSelecionada.lista_pilotos).map(uid => (
                      <option key={uid} value={provaSelecionada.lista_pilotos[uid].uid} >{provaSelecionada.lista_pilotos[uid].utilizaPsnPlus ? provaSelecionada.lista_pilotos[uid].psnPlus : provaSelecionada.lista_pilotos[uid].psn}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formTempo">
                  <Form.Label>Tempo</Form.Label>
                  <MaskedFormControl type='text'  name="melhorTempo" mask='1:11.111' value={provaSelecionada['melhorTempo']} placeholder="1:27.625" onChange={this.onChangeMelhores}/>
                </Form.Group>
              </Form.Row>
            </Card.Text>
          </Modal.Body>
          {renderMessageMelhores()}
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseMelhores()}> Cancelar </Button>
            <Button variant="primary" onClick={() => handleSalvarEstatistica()}> Salvar </Button>
          </Modal.Footer>
        </Modal>
      );
    }

    const downloadTxtFile = (event) => {
      const element = document.createElement("a");
      let header = 'nomeUsuario; email; celular; psnPlus; psn; utilizaPsnPlus;dataHoraInscricao;formaPagamento;inscricaoPaga\n';

      for(const uid of Object.keys(lista_pilotos)){
        header += lista_pilotos[uid].nomeUsuario+';'+lista_pilotos[uid].email+';'+lista_pilotos[uid].celular+';'+lista_pilotos[uid].psnPlus+';'+lista_pilotos[uid].psn+';'+lista_pilotos[uid].utilizaPsnPlus+';'+lista_pilotos[uid].dataHoraInscricao+';'+lista_pilotos[uid].formaPagamento+';'+lista_pilotos[uid].inscricaoPaga+'\n';
      }
       
      const file = new Blob([header],    
                  {type: 'text/plain;charset=utf-8'});
      
      element.href = URL.createObjectURL(file);
      element.download = "Pilotos Inscritos.csv";
      document.body.appendChild(element);
      element.click();
      event.preventDefault();;
    }
  
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form onSubmit={this.onSubmit}>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
              <Card className="cardPainelTexto">
                <Card.Title className="cardPainelTitulo">Detalhe do Campeonato</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <Card.Text className="wordStyle">
                  <div className="padding15px">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formNomeCampeonato">
                        <Form.Label>Nome do Campeonato</Form.Label>
                        <Form.Control name="nome_campeonato" readOnly value={nome_campeonato}/>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formDescricao">
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control rows="4" name="descricao" as="textarea" readOnly value={descricao}/>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="4.5" controlId="formDtInicio">
                        <Form.Label>Data Inicio </Form.Label>
                        <MaskedFormControl type='date' name="data_inicio" mask='1111-11-11' readOnly value={data_inicio} selected={data_inicio}/>
                      </Form.Group>
                      <Form.Group as={Col} md="4.5" controlId="formDtFim">
                        <Form.Label>Data Fim </Form.Label>
                        <MaskedFormControl type='date' name="data_fim" mask='1111-11-11' readOnly value={data_fim} selected={data_fim}/>
                      </Form.Group>
                      <Form.Group as={Col} md="4" controlId="formGridCategoria">
                        <Form.Label>Categoria</Form.Label>
                        <Form.Control name="categoria" readOnly value={categoria.nome_categoria}/>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formQtdeVoltas">
                        <Form.Label>Percentual da Prova</Form.Label>
                        <Form.Control name="percentual_prova" readOnly value={percentual_prova}/>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formQtdeMaxPiloto">
                        <Form.Label>Quantidade Máxima de Pilotos</Form.Label>
                        <Form.Control name="qtde_max_piloto" type="number" readOnly value={qtde_max_piloto}/>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formProvasSelecionadas">
                        <Form.Label>Provas Selecionadas</Form.Label>
                        <Form.Control as="select" className="selectListHeight" multiple>
                          {Object.keys(lista_provas_selecionadas).map(uid => (
                            <option key={uid} value={lista_provas_selecionadas[uid].nome_prova} onDoubleClick={() => handleShowMelhores(lista_provas_selecionadas[uid])} >{lista_provas_selecionadas[uid].nome_prova}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formPilotosInscritos">
                        <Form.Label>Pilotos Inscritos - {Object.values(lista_pilotos).length}/{qtde_max_piloto}</Form.Label>
                        <Form.Control ref={(textSelect) => this.textSelect = textSelect} as="select" className="selectListHeight" multiple>
                          {Object.keys(lista_pilotos).map(uid => (
                            <option className={lista_pilotos[uid].inscricaoPaga === "NÃO" ? "backGroundColorVermelho" : "backGroundColorVerde"} key={uid} onDoubleClick={() => handleShow(lista_pilotos[uid])} value={lista_pilotos[uid].nomeUsuario} >{lista_pilotos[uid].utilizaPsnPlus ? lista_pilotos[uid].psnPlus : lista_pilotos[uid].psn}</option>
                          ))}
                        </Form.Control>
                        <Form.Label>Pilotos Confirmados - {Object.values(lista_pilotos).filter(piloto => piloto.inscricaoPaga === 'SIM').length}/{qtde_max_piloto}</Form.Label>
                        <div className="paddingTopBottom10px">
                          <Button className="alignElementRight" onClick={downloadTxtFile}>
                            Exportar Inscritos
                          </Button>
                        </div>
                        <div className="paddingTopBottom10px">
                          <Button className="alignElementRight">
                            Enviar notificação por E-Mail
                          </Button>
                        </div>
                      </Form.Group>
                    </Form.Row>
                    {renderModalMelhores(this.state.provaSelecionada)} 
                    

                    <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false} size="lg" className="wordStyle">
                      <Modal.Header closeButton>
                        <Modal.Title className="cardPainelSubTitulo">{CONSTANTES.DRIVE_PRO_SERIES} - Confirmação de Pagamento</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Card.Text className="wordStyle">
                          <Form.Row>
                            <Form.Group as={Col} controlId="formNomePiloto">
                              <Form.Label>Nome do piloto</Form.Label>
                              <Form.Control readOnly value={piloto.nomeUsuario}/>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="formApelido">
                              <Form.Label>Apelido</Form.Label>
                              <Form.Control readOnly value={piloto.utilizaPsnPlus ? piloto.psnPlus : piloto.psn}/>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="4" controlId="formConfirmaPgto">
                              <Form.Label>Pagamento Confirmado</Form.Label>
                              <Form.Control required as="select" name="inscricaoPaga" value={piloto.inscricaoPaga} onChange={this.onChangeConfirmaPagamento}>
                                <option key='SIM' value='SIM'>SIM</option>
                                <option key='NÃO' value='NÃO'>NÃO</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="formFormaPagamento">
                              <Form.Label>Forma de Pagamento</Form.Label>
                              <Form.Control required as="select" name="formaPagamento" value={piloto.formaPagamento} onChange={this.onChangeConfirmaFormaPagamento}>
                                <option key='NÃO CONFIRMADO' value='NÃO CONFIRMADO'>NÃO CONFIRMADO</option>
                                <option key='BB' value='BB'>BB</option>
                                <option key='NUBANK' value='NUBANK'>NUBANK</option>
                                <option key='PICPAY' value='PICPAY'>PICPAY</option>
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>
                        </Card.Text>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose()}>
                          Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => handleSalvar()}>
                          Salvar
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    
                    <Form.Group id="formGridCheckbox">
                      <Form.Check type="checkbox" name="ind_ativo" label="Ativo" checked={ind_ativo} onChange={this.onChangeCheckBox}/>
                    </Form.Group>
                    {renderMessage()}
                    <Button variant="primary" type="submit">
                      Salvar Alterações
                    </Button>
                  </div>
                </Card.Text>
              </Card>
            </LoadingOverlay>
        </Form>
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const CampeonatoDetalheForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(CampeonatoDetalheBase);

export default CampeonatoDetailPage;
export { CampeonatoDetalheForm };