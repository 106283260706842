import React, { Component } from 'react';
import { Form, Button, Col, Modal, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import '../../css/paddockleague.css';
import * as CONSTANTES from '../../../constants/constantes';

const TemporadaDetailPage = () => (
  <TemporadaDetalheForm />
);

class TemporadaDetalheBase extends Component {
  constructor(props) {
    super(props);
    let infoTemporada = props.location.state.temporada;

    this.state = {
      isProcessando: false,
      uid: infoTemporada.uid,
      nome_temporada: infoTemporada.nome_temporada,
      ind_ativo: infoTemporada.ind_ativo,
      url_tabela: infoTemporada.url_tabela === null ? '' : infoTemporada.url_tabela,
      
      lista_temporadas_selecionadas: infoTemporada.lista_temporadas_selecionadas === null || typeof infoTemporada.lista_temporadas_selecionadas == "undefined"  ? [] : infoTemporada.lista_temporadas_selecionadas,

      provas: [],
      ...props.location.state,
      msgRetorno: '',
      statusOk: false,
      show: false,
    };
    
    this.onChange = this.onChange.bind(this); 
  }

  componentWillUnmount() {
    this.props.firebase.temporada(this.props.match.params.id).off();
  }

  onChange = event => {  
    this.setState({ [event.target.name]: event.target.value });
  };
  onChangeCheckBox = event => {
    this.setState({ ind_ativo: !this.state.ind_ativo,});
  }
  
  onSubmit = event => {
      const {uid, ind_ativo, url_tabela } = this.state;
      this.setState({ isProcessando: true });
      
      this.props.firebase.temporada(uid).update({
          ind_ativo: ind_ativo,
          url_tabela: url_tabela
      }).then(() => {
          this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Registro atualizado com sucesso!'});
      }).catch(error => {
          this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
      })
      
      event.preventDefault();;
  }
  
  render() {
    let {nome_temporada, ind_ativo, lista_temporadas_selecionadas, url_tabela, msgRetorno, isProcessando, statusOk, isInvalido, show} = this.state;
    
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    const handleClose = () => {
      this.setState({ show: false, uid: null })
    };
    const handleShow = (uidCampeonato) => {
      this.setState({ show: true , uidCampeonato: uidCampeonato})
    };

    const renderProvas = (uidCampeonato) =>{
      if (uidCampeonato){
        return (
          Object.keys(lista_temporadas_selecionadas[this.state.uidCampeonato].lista_provas_selecionadas).map((uid) =>
            <option key={uid} value={lista_temporadas_selecionadas[this.state.uidCampeonato].lista_provas_selecionadas[uid].nome_prova} >{lista_temporadas_selecionadas[this.state.uidCampeonato].lista_provas_selecionadas[uid].nome_prova}</option>)
            );
      }
    }

    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form noValidate validated={isInvalido} onSubmit={this.onSubmit}>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                <Card className="cardPainelTexto">
                  <Card.Body>
                    <Card.Title className="cardPainelTitulo">Detalhes da Temporada</Card.Title>
                    <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                    <Card.Text className="wordStyle">
                      <Form.Row>
                          <Form.Group as={Col} controlId="formNomeTemporada">
                              <Form.Label>Nome da temporada</Form.Label>
                              <Form.Control readOnly value={nome_temporada}/>
                          </Form.Group>
                      </Form.Row>
                      <Form.Row>                
                        <Form.Group as={Col} controlId="formCampeonatosSelecionadas">
                          <Form.Label>Campeonatos Selecionados</Form.Label>
                          <Form.Control as="select" multiple >
                            {Object.keys(lista_temporadas_selecionadas).map((uid) => 
                              <option key={uid} value={lista_temporadas_selecionadas[uid].nome_campeonato} onDoubleClick={() => handleShow(uid)}>{lista_temporadas_selecionadas[uid].nome_campeonato}</option>
                            )}
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formTabelaTemporada">
                          <Form.Label>URL da Tabela da Temporada</Form.Label>
                          <Form.Control name="url_tabela" type="text" placeholder="http://www.img.com.br/tabela.jpg" value={url_tabela} onChange={this.onChange}/>
                        </Form.Group>
                      </Form.Row>
                      <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false} size="lg">
                        <Modal.Header closeButton>
                          <Modal.Title>{CONSTANTES.DRIVE_PRO_SERIES} - Cadastro de Pontuação por Prova</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="4" controlId="formGridCategoria">
                              <Form.Label>Prova</Form.Label>
                              <Form.Control required as="select" name="categoria_nome" onChange={this.onChange}>
                                {renderProvas(this.state.uidCampeonato)}
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => handleClose()}>
                            Cancelar
                          </Button>
                          <Button variant="primary" onClick={() => handleClose()}>
                            Salvar Pontuação
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Form.Group id="formGridCheckbox">
                        <Form.Check type="checkbox" name="ind_ativo" label="Ativo" checked={ind_ativo} onChange={this.onChangeCheckBox}/>
                      </Form.Group>
                      {renderMessage()}
                      <Button  variant="primary" type="submit">
                          Salvar Alterações
                      </Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
            </LoadingOverlay>
          </Form>
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const TemporadaDetalheForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(TemporadaDetalheBase);

export default TemporadaDetailPage;
export { TemporadaDetalheForm };