import React, { Component } from 'react';
import { Card, Table, Image } from 'react-bootstrap'
import { compose } from 'recompose';
import '../../css/paddockleague.css';
import * as CONSTANTES from '../../../constants/constantes';

const BandeirasPage = () => (
    <BandeirasForm />
);

class BandeirasBase extends Component {
  render() {
    return (
      <Card className="cardPainelTexto">
        <Card.Body>
          <Card.Title className="cardPainelTitulo">Sistema de Pontuacao </Card.Title>
          <Card.Text className="wordStyle">
            Quando algo que ocorre na pista necessita da atenção de todos os pilotos, são exibidas bandeiras coloridas próximas a linha de chegada/largada. Os pilotos também podem ser avisados pelo rádio, pelos chamados spotters, mas como estes avisos normalmente são críticos e o rádio pode apresentar problemas durante um Grande Prêmio, usa-se o método das bandeiras que é mais prático e seguro.
            As bandeiras são mais habitualmente usadas para avisar os pilotos sobre a entrada ou a saída do safety car ou perigos na pista. As bandeiras usadas atualmente são:
            <br/>
            <br/>
            <Table striped bordered hover variant="dark" className="tituloTabela">
              <thead>
                <tr className="tituloTabela" >
                  <th>Bandeira</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_AMARELA}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Bandeira Amarela é uma indicação ao piloto de perigo na pista como um carro parado em local perigoso ou algum objeto na pista. Se houver apenas uma bandeira, a intenção é advertir o piloto. Caso sejam duas bandeiras, o piloto deve tomar mais atenção e estar preparado para
                    reduzir bastante a velocidade no trecho. São proibidas ultrapassagens no ponto que estiver a bandeira amarela.
                    <br/>
                    - 1 Bandeira: Perigo adiante, detritos resultantes de acidente; pilotos devem reduzir a velocidade ao cruzar o local do acidente.
                    <br/>
                    - 2 Bandeiras: Grande perigo adiante; pilotos devem preparar-se para uma eventual parada, se necessário.
                    <br/>
                    - 1 Bandeira e a Placa de VSC: Os pilotos terão de respeitar uma velocidade imposta pela FIA em cada setor da pista. Quem não respeitar, poderá ser penalizado.
                    <br/>
                    - 1 Bandeira e a Placa de SC: Entrada do safety car; pilotos devem reduzir a velocidade e aguardar uma nova formação. 
                  </td>
                </tr>
                <tr key="2">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_AZUL}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Dê passagem (o retardatário) a um carro mais veloz que quer ultrapassar. Pelas regras de 2009, o piloto deverá permitir a passagem até três curvas após lhe ser mostrada a bandeira ou arrisca penalizações (geralmente essa punição se dá através da passagem pelo box sem parada, ou parada de 10 segundos). 
                  </td>
                </tr>
                <tr key="2">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_VERDE}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Pista livre após incidente. Os pilotos podem voltar a acelerar normalmente.
                  </td>
                </tr>
                <tr key="3">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_VERMELHA}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Corrida paralisada devido a acidente nas duas primeiras voltas, ou devido a problemas meteorológicos ou acidentes particularmente graves. A bandeira também pode indicar o término da prova em virtude desta falta de segurança.
                  </td>
                </tr>
                <tr key="4">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_LISTRADA}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Mostra ao piloto que a pista está escorregadia, seja por óleo derramado no asfalto ou por água em excesso em alguns pontos do circuito.
                  </td>
                </tr>
                <tr key="5">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_BRANCA}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Carro lento na pista. Pode ser uma ambulância ou qualquer outro carro de serviço na pista.
                  </td>
                </tr>
                <tr key="6">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_QUADRICULADA}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Na prova, essa bandeira mostrada pela primeira vez ao piloto que vence o GP. Em seguida, ela é dada aos pilotos que cruzam após o vencedor. Já nos treinos, a bandeira quadriculada indica o término da sessão, e os pilotos que cruzam antes dela podem encerrar sua volta e ela será computada para efeito de classificação.
                  </td>
                </tr>
                <tr key="7">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_PRETA_BRANCA}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Vem acompanhada com o número do piloto. Ele tomou alguma atitude antidesportiva, a qual deve ser corrigida o mais rápido possível para não haver desclassificação.
                  </td>
                </tr>
                <tr key="8">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_PRETA}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Vem acompanhada do número do piloto e mostra que ele deve retornar ao pit, pois foi desclassificado da prova em virtude de alguma irregularidade.
                  </td>
                </tr>
                <tr key="9">
                  <td className="semBorda">
                    <Image className="bandeiraSize" src={CONSTANTES.BANDEIRA_PRETA_CIRCULO_LARANJA}/>
                  </td>
                  <td style={{textAlign:'left'}}>
                    Vem acompanhada com o número do piloto. Indica que há algum problema técnico. O piloto deverá voltar aos boxes.
                  </td>
                </tr>
              </tbody>
            </Table>
            <p style={{fontSize: '12px'}}>
            Referências:
              <br/> 
              <a href="https://argent.fia.com/web/fia-public.nsf/DE0607008B24C094C125742700570E99/$FILE/L3_Annexe%20H_08.04.09.pdf?Openelement" target="noreferrer">FIA</a>
              <br/>
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

const BandeirasForm = compose(
)(BandeirasBase);

export default BandeirasPage;
export { BandeirasForm };