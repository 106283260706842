import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';



const CategoriaPage = () => (
  <CategoriaForm />
);

class CategoriaFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome_categoria: '',
      descricao: '',
      posicao: 0,
      url_categoria: '',
      indAtivo: false,

      msgRetorno: '',
      isProcessando: false,
      statusOk: false,
      isValido: false,
    };
  }

  componentWillUnmount() {
    this.props.firebase.categorias().off();
  }
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeTextCategoria = event => {
    this.setState({ nome_categoria: event.target.value });
  };
  
  onChangeTextDescricao = event => {
    this.setState({ descricao: event.target.value });
  };
  
  onChangeTextPosicao = event => {
    this.setState({ posicao: event.target.value });
  };

  onCreateCategoria = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isValido: true });
    }else{
      this.setState({ isValido: false, isProcessando: true, });
      this.props.firebase.categorias().push({
        nome_categoria: this.state.nome_categoria,
        descricao: this.state.descricao,
        posicao: this.state.posicao,
        url_categoria: this.state.url_categoria,
        indAtivo: this.state.indAtivo
      }).then(() => {
        this.setState({ nome_categoria: '', descricao: '', posicao: 0 });
        this.setState({msgRetorno: 'Categoria cadastrada com sucesso!', isProcessando: false, statusOk: true});
      }).catch(error => {
        this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
      });
    }
    
    event.preventDefault();
  };

  render() {
    const { isValido, isProcessando, isInvalidFields, msgRetorno, statusOk } = this.state;
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Form noValidate validated={isValido} onSubmit={event => this.onCreateCategoria(event) }>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
              <Card className="cardPainelTexto">
                <Card.Body>
                  <Card.Title className="cardPainelTitulo">Cadastro de Categoria</Card.Title>
                  <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                  <Card.Text className="wordStyle">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCategoria">
                        <Form.Label>Nome Categoria:</Form.Label>
                        <Form.Control required maxLength={50} name="nome_categoria" type="text" placeholder="Interlagos" autoComplete="on" onChange={this.onChangeTextCategoria}/>
                        <Form.Control.Feedback type="invalid">
                          Informe a categoria
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridDescricao">
                        <Form.Label>Descrição:</Form.Label>
                        <Form.Control required name="descricao" as="textarea" rows="3" autoComplete="on" onChange={this.onChangeTextDescricao}/>
                        <Form.Control.Feedback type="invalid">
                          Informe a descricao
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCategoriaImagem">
                        <Form.Label>Imagem da Categoria:</Form.Label>
                        <Form.Control required maxLength={100} name="url_categoria" type="text" placeholder="hottp://www.image.com.br/categoria.img" autoComplete="on" onChange={this.onChange}/>
                        <Form.Control.Feedback type="invalid">
                          Informe a URL da imagem
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="4" controlId="formGridPosicao">
                        <Form.Label>Posição</Form.Label>
                        <Form.Control required as="select" name="posicao" onChange={this.onChangeTextPosicao}>
                          <option key='1' value='1'>1º</option>
                          <option key='2' value='2'>2º</option>
                          <option key='3' value='3'>3º</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    {renderMessage()}
                    <Button disabled={isInvalidFields}  variant="primary" type="submit">
                      Cadastrar
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </LoadingOverlay>
          </Form>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const CategoriaForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(CategoriaFormBase);

export default CategoriaPage;
export { CategoriaForm };