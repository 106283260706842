import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import '../css/paddockleague.css';
import LoadingOverlay from 'react-loading-overlay';
import { Card } from 'react-bootstrap'

class Season extends Component {

  constructor(props) {
    super(props);
    let infoTemporada = this.props.location.state.temporada;
    
    this.state = {
      isProcessando: false,
      nome_temporada: infoTemporada.nome_temporada,
      url_tabela: infoTemporada.url_tabela,
      
      id_categoria: this.props.match.params.id,

      msgRetorno: '',
      statusOk: false,
      show: false, 
    };
  }
  componentDidMount() {
    //this.getCategoria(this.props.match.params.id);
  }
  
  componentWillUnmount() {
    this.props.firebase.temporadas().off();
  }

  onClick = (event) => {
    this.setState({ show: true })
    event.preventDefault();;
  }
  
  getCategoria = (idCategoria) => {
    this.props.firebase
      .categoria(idCategoria)
      .once('value', snapshot => {
        const categoria = snapshot.val();
        if (categoria) {
          this.setState({
            nome_categoria: categoria.nome_categoria,
            descricao: categoria.descricao,
            posicao: categoria.posicao,
            isProcessando: false,
            statusOk: true,
          });
        } else {
          this.setState({
            nome_categoria: '',
            descricao: '',
            posicao: '',
            isProcessando: false,
            statusOk: false,
            msgRetorno: 'Ocorreu um problema ao buscar a categoria selecionada. Tente novamente', 
          });
        }
      }, error =>{
        this.setState({
            isProcessando: false,
            statusOk: false,
            msgRetorno: error, 
        });
			});
  };
 
  render() {
    const { nome_temporada, url_tabela, isProcessando, statusOk, msgRetorno } = this.state;

    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">{nome_temporada}</Card.Title>
                <Card.Text>
                  <img className="img-tabela-pontuacao" alt="Tabela de Pontuacao"  id="TabelaPontuacao" src={url_tabela} />
                </Card.Text>
              </Card.Body>
            </Card>
          </LoadingOverlay>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default compose(
  withFirebase
)(Season);