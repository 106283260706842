import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import '../css/paddockleague.css';
import LoadingOverlay from 'react-loading-overlay';
import { Button, Modal, Card } from 'react-bootstrap'
import * as CONSTANTES from '../../constants/constantes';
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Adsense } from '@ctrl/react-adsense';

class Calendario extends Component {

  constructor(props) {
    super(props);
    this.state = {
        linkCalendarioTemporadaAtual: [{}],
        linkCalendarioProximaTemporada: [{}],
        isProcessando: true
	};
	this.getLinkCalendarios()
  }
  componentDidMount () {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  getLinkCalendarios(){
      let linkCalendarios = null;
      let linkCalendarioTemporadaAtual = [{}];
      let linkCalendarioProximaTemporada = [{}];

      this.props.firebase.calendarios().once('value', snapshot =>{
          if(snapshot.val()){
              linkCalendarios = Object.keys(snapshot.val()).map(key => ({
                  ...snapshot.val()[key],
                  uid: key,
			  }))
              linkCalendarioTemporadaAtual = linkCalendarios.filter(cal => cal.indProximaTemporada === false)
                                                            .sort(function(a,b){
                                                                return a.posicao < b.posicao ? -1 : 1
                                                            });
              linkCalendarioProximaTemporada = linkCalendarios.filter(cal => cal.indProximaTemporada === true)
                                                              .sort(function(a,b){
                                                                return a.posicao < b.posicao ? -1 : 1
                                                              });
		  }	
		}).then(() => {
            this.setState({ linkCalendarioTemporadaAtual: linkCalendarioTemporadaAtual,
							linkCalendarioProximaTemporada: linkCalendarioProximaTemporada })
		}).catch(error => {
			this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
		});
  }
  
  render() {
    const propertiesAtual = {
        duration: 5000,
        autoplay: true,
        transitionDuration: 500,
        arrows: false,
        infinite: true,
        easing: "ease",
	};
    const propertiesProximaTemporada = {
        duration: 5000,
        autoplay: true,
        transitionDuration: 500,
        arrows: false,
        infinite: true,
        easing: "ease",
	};

    const renderCalendarioProximaTemporada = (linkCalendarioProximaTemporada) =>{
        if(typeof linkCalendarioProximaTemporada === 'undefined' || linkCalendarioProximaTemporada === null || linkCalendarioProximaTemporada.length === 0) return;	
        return ( 
            <aside>
                <a className="wordStyleCalendario">Próxima Temporada</a>
				<div className="slide-container slide-container-side">
                    <Slide ref={this.slideRef} {...propertiesProximaTemporada}>
                        {this.state.linkCalendarioProximaTemporada.map((each, index) => (
                            <div key={index} className="each-slide">
                                <img className="imgCalendario" src={each.urlCalendario} alt={each.descricaoCalendario} width="950" />
                            </div>
                        ))}
                    </Slide>
                </div>
				<div className="clear"></div>
			</aside>
		);
	}
    return (
      <AuthUserContext.Consumer>
        {authUser => (
            <React.Fragment>
                <div className="float-sm-left tituloCalendario">
                    <aside>
                        <a className="wordStyleCalendario">Calendário 2023</a>
                        <div className="slide-container slide-container-side">
                            <Slide ref={this.slideRef} {...propertiesAtual}>
                                {this.state.linkCalendarioTemporadaAtual.map((each, index) => (
                                    <div key={index} className="each-slide">
                                        <img className="imgCalendario" src={each.urlCalendario} alt={each.descricaoCalendario} width="950" />
                                    </div>
                                ))}
                            </Slide>
                        </div>
                        <div className="clear"></div>
                    </aside>
                    {renderCalendarioProximaTemporada(this.state.linkCalendarioProximaTemporada)}
                    <div className="quadrado_250">
                        <Adsense client="ca-pub-7094612235789206"
								 slot="9086509140"
								 style={{ display: 'block' }}
								 layout="in-article"
								 format="fluid"
                                 responsive='true'
						/>
					</div>
                </div>
            </React.Fragment>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default compose(
  withFirebase
)(Calendario);