export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signout';
export const HOME = '/home';
export const ACCOUNT_PROFILE = '/account/profile';
export const PASSWORD_CHANGE = '/account/password';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const CATEGORIA_DETAIL = '/categoria/:id';
export const CATEGORIA = '/categorias';
export const ADMIN_DETAILS = '/admin/:id';

export const LISTAGEM_CATEGORIA = '/admin/categorias';
export const CADASTRO_CATEGORIA = '/admin/categoria';
export const CATEGORIA_DETAILS = '/admin/categorias/:id';

export const CADASTRO_PROVAS = '/admin/provas';

export const LISTAGEM_PILOTOS = '/admin/pilotos';
export const PILOTO_DETAILS = '/admin/pilotos/:id';

export const TRIAGEM = '/triagem';

export const CADASTRO_CAMPEONATO = '/admin/campeonato';
export const LISTAGEM_CAMPEONATOS = '/admin/campeonatos';
export const CAMPEONATO_DETAILS = '/admin/campeonatos/:id';

export const CADASTRO_TEMPORADA = '/admin/temporada';
export const LISTAGEM_TEMPORADAS = '/admin/temporadas';
export const TEMPORADA_DETAILS = '/admin/temporadas/:id';
export const TEMPORADA_SITE = '/temporada';
export const TEMPORADA_ALL_SITE = '/temporadas';  
export const TEMPORADA_SITE_DETAIL = '/temporada/:id'; 

export const CADASTRO_PATROCINADOR = '/admin/patrocinador';
export const LISTAGEM_PATROCINADOR = '/admin/patrocinadores';
export const PATROCINADOR_DETAILS = '/admin/patrocinadores/:id';

export const CADASTRO_NOTICIA = '/admin/noticia';
export const LISTAGEM_NOTICIAS = '/admin/noticias';
export const NOTICIA_DETAILS = '/admin/noticias/:id';

export const LISTAGEM_NEWS = '/news';

export const TIPO_PNEU = '/formula1/tipopneu';
export const PONTUACAO = '/formula1/pontuacao';
export const BANDEIRAS = '/formula1/bandeiras';
export const GRAND_PRIX = '/formula1/grandprix';
export const SOBRE = '/sobre';
export const CONTATO = '/contato';
export const ULTIMOS_RESULTADOS = '/ultimos-resultados';
export const CLASSIFICACAO_PILOTOS = '/classificacao-pilotos';
export const CLASSIFICACAO_CONSTRUTORES = '/classificacao-construtores';

export const PAINEL_ADMINISTRATIVO = '/admin/administrativo';

export const CADASTRO_CALENDARIO = '/admin/calendario';
export const LISTAGEM_CALENDARIO = '/admin/calendarios';
export const CALENDARIO_DETAIL = '/admin/calendarios/:id';

export const CADASTRO_TABELA = '/admin/tabela';
export const LISTAGEM_TABELA = '/admin/tabelas';
export const TABELA_DETAIL = '/admin/tabelas/:id';

export const ENVIO_NOTIFICACAO = '/admin/notificacao';
