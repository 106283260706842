import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Image, Modal, Button, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import * as CONSTANTES from '../../../constants/constantes';
import '../../css/paddockleague.css';
import Delete from '../../css/delete.png';
import Ready from '../../css/ready.png';
import NoReady from '../../css/nonReady.png';
import Detail from '../../css/detail.png';
import ReactTooltip from "react-tooltip";

const TemporadaPage = () => (
  <TemporadaForm />
);

class TemporadaListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessando: false,
      statusOk: false,
      msgRetorno: '',
      temporadas: [],
      show: false,
      temporadaSelecionada: ''
    };
  }

  retornaTemporadas(){
    this.setState({ isProcessando: true });

    this.props.firebase.temporadas().once('value', snapshot => {
      const temporadaObject = snapshot.val();
      let temporadaList = [];
      if(temporadaObject){
        temporadaList = Object.keys(temporadaObject).map(key => ({
          ...temporadaObject[key],
          uid: key,
        }));
      }else{
        temporadaList = [];
      }

      this.setState({
        temporadas: temporadaList,
        isProcessando: false,
      });
    });
  }

  componentDidMount() {
    this.retornaTemporadas();
  }

  componentWillUnmount() {
    this.props.firebase.temporadas().off();
  }
  

  render() {
    const { temporadas, isProcessando, show, temporadaSelecionada} = this.state;
    
    const handleClose = () => {
      this.setState({ show: false })
    };
    
    const handleShow = (temporada) => {
      this.setState({ temporadaSelecionada: temporada, show: true })
    };
    
    const handlePrepararTemporada = (temporada) => {
      if(temporada.ind_ativo === false){
        /*
        adicionar mensagem informando que a temporada está inativa
        */
        return;
      }
      if (temporada.ind_preparado === false){

        this.props.firebase.temporada(temporada.uid).update({
          ind_preparado: true
        }).then(() => {
          Object.keys(temporada.lista_temporadas_selecionadas).map(uid => (
            this.props.firebase.campeonato(uid).remove())
          )
          window.location.reload();
        }).catch(error => {
          this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
        })
        
      }
    }

    const handleDeletarTemporada = (uid) => {
      this.setState({ show: false, isProcessando: true })
      this.props.firebase.temporada(uid).remove().then(() => {
        let listaTemporada = temporadas.filter(temporada => temporada.uid !== uid);
        this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Registro atualizado com sucesso!', temporadas: listaTemporada});
      }).catch(error => {
        this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
      })
    };
    
    const renderTemporada = (temporada) => {
        return (
            <tr key={temporada.uid}>
                <td>{temporada.nome_temporada}</td>
                <td className="centerTableImage">
                  <Link to={{ pathname: `${ROUTES.LISTAGEM_TEMPORADAS}/${temporada.uid}`, 
                                        state: { temporada }, }} >
                    <Image src={Detail} data-tip data-for='detailTip' className="IconTable" />
                    <ReactTooltip id='detailTip' type='error'>
                      <span>Detalhes</span>
                    </ReactTooltip>
                  </Link>
                </td>
                <td className="centerTableImage">
                  <Image src={Delete} data-tip data-for='deleteTip' className="IconTable" onClick={() => handleShow(temporada)}/>
                  <ReactTooltip id='deleteTip' type='error'>
                    <span>Apagar</span>
                  </ReactTooltip>
                </td>
                <td className="centerTableImage" >
                  <Image src={!temporada.ind_preparado ? Ready : NoReady} disabled data-tip data-for='readyTip' className="IconTable" onClick={() => handlePrepararTemporada(temporada)}/>
                  <ReactTooltip id='readyTip' type='error'>
                    <span>{!temporada.ind_preparado ? 'Preparar Temporada' : 'Temporada Pronta'}</span>
                  </ReactTooltip>
                </td>
            </tr>
        );
    }

    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Listagem de Temporadas</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <div className="wordStyle">
                  <Table striped bordered hover variant="dark">
                      <thead>
                          <tr>
                              <th>Nome Temporada</th>
                          </tr>
                      </thead>
                      <tbody>
                          {temporadas.map(renderTemporada)}
                      </tbody>
                  </Table>
                  <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                      <Modal.Title className="wordStyle">{CONSTANTES.DRIVE_PRO_SERIES}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="wordStyle">
                      Você está prestes a deletar o {temporadaSelecionada.nome_temporada}, tem certeza disso? 
                    </Modal.Body>
                    <Modal.Footer>
                      <Button className="wordStyle" variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                      </Button>
                      <Button className="wordStyle" variant="primary" onClick={() => handleDeletarTemporada(temporadaSelecionada.uid)}>
                        Ok, pode deletar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Card.Body>
            </Card>
          </LoadingOverlay>
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const TemporadaForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(TemporadaListBase);

export default TemporadaPage;
export { TemporadaForm };