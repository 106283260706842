import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import '../css/paddockleague.css';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Col, Card, Table } from 'react-bootstrap'

const UltimosResultadosPage = () => (
    <UltimosResultadosForm />
);

class UltimosResultadosPageBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        dados: '',
        isProcessando: false,
    };
  }
  getResultados() {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    this.setState({ isProcessando: true, });
    fetch(process.env.REACT_APP_LAST_RESULT_F1, requestOptions)
      .then(response => response.text())
      .then(result => {
        this.setDados(JSON.parse(result))
        this.setState({ isProcessando: false, });
      })
      .catch(error => console.log('error', error));
  }

  componentWillMount() {
    this.getResultados()
  }

  setDados = (data) => {
    this.setState({ dados: data })
  }
  
  render() {
    const { dados, isProcessando } = this.state;
    
    const renderResultado = (resultado) => {
      
      return (
        <tr key={resultado.number}>
          <td>{resultado.position}</td>
          <td>{resultado.number}</td>
          <td>
            <a target="_blank" rel="noreferrer"  href={resultado.Driver.url}>{resultado.Driver.givenName + ' ' +resultado.Driver.familyName}</a>
          </td>
          <td>
            <a target="_blank" rel="noreferrer"  href={resultado.Constructor.url}>{resultado.Constructor.name}</a>
          </td>
          <td>{resultado.laps}</td>
          <td>{verificaValor(resultado.Time) ? resultado.Time.time : ' '}</td>
          <td>{resultado.status}</td>
        </tr>
      );
    }
    const verificaValor = (value) => {
      if(typeof value === 'undefined' || value === null || value === ''){
        return false;
      }
      return true;
      
    }

    if(isProcessando){
      return (
        <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
          
        </LoadingOverlay>
      );
    }else{
      return (
        <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
          <Card className="cardPainelTexto">
            <Card.Body>
              <Card.Title className="cardPainelTitulo">{ dados.MRData.RaceTable.Races[0].raceName } - Temporada { dados.MRData.RaceTable.Races[0].season}</Card.Title>
              <Card.Text className="wordStyle">
                Circuito: <a target="_blank" rel="noreferrer"  href={dados.MRData.RaceTable.Races[0].Circuit.url}>{dados.MRData.RaceTable.Races[0].Circuit.circuitName}</a>
              </Card.Text>
              <Card.Text className="wordStyle">
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    Pais: {dados.MRData.RaceTable.Races[0].Circuit.Location.country}
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridEmail">
                    Cidade: {dados.MRData.RaceTable.Races[0].Circuit.Location.locality}
                  </Form.Group>
                </Form.Row>
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>Posição</th>
                      <th>Nº</th>
                      <th>Piloto</th>
                      <th>Escuderia</th>
                      <th>Voltas</th>
                      <th>Tempo</th>
                      <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                       { dados.MRData.RaceTable.Races[0].Results.map(renderResultado) }
                    </tbody>
                  </Table>
              </Card.Text>
            </Card.Body>
          </Card>
        </LoadingOverlay>
      );
    }
  }
}

const UltimosResultadosForm = compose(
  withFirebase
)(UltimosResultadosPageBase);

export default UltimosResultadosPage;
export { UltimosResultadosForm };