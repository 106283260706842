import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withFirebase } from '../Firebase';


class CategoriaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      categorias: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase
      .categorias()
      .once('value', snapshot => {
        const messageObject = snapshot.val();
        if (messageObject) {
          let categoriaList = Object.keys(messageObject).map(key => ({
            ...messageObject[key],
            uid: key,
          }));
          categoriaList = categoriaList.filter(categoria => categoria.nome_categoria !== 'SEM CATEGORIA' && categoria.indAtivo == true);
          this.setState({
            categorias: categoriaList.sort(function compare(a,b) { return a.posicao - b.posicao; }),
            loading: false,
          });
        } else {
          this.setState({ categorias: null, loading: false,});
        }
      }, 
      error =>{
          console.log(error)
      });
  }

  componentWillUnmount() {
    this.props.firebase.categorias().off();
  }
/*
to={{pathname: ROUTES.CATEGORIA+'/'+categoria.uid }}> {categoria.posicao} - {categoria.nome_categoria}

to={{pathname: ${ROUTES.CATEGORIA}/${categoria.uid}` }}> {categoria.posicao} - {categoria.nome_categoria}

to={`/categoria/${categoria.uid}`}> {categoria.posicao} - {categoria.nome_categoria}
 */
  render() {
    const { categorias } = this.state;

    return (
        <React.Fragment>
          {categorias.map(categoria => (
            <NavLink key={categoria.uid}
                    className='dropdown-item' 
                    to={{pathname: '/categoria/'+categoria.uid, 
                                        state: { categoria }, }}>{categoria.nome_categoria}
            </NavLink >
          ))}
        </React.Fragment>
    );
  }
}

export default withFirebase(CategoriaList);