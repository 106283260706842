import React, { Component } from 'react';
import { Card } from 'react-bootstrap'
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import '../css/paddockleague.css';
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";

const LandingPage = () => (
  <LandingForm />
); 

class LandingPageBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        idVideoYouTube: '',
        tituloVideoYoutube: '',
        listaTabelaPrincipal:[],
        listaTabelaIntermediaria:[],
        listaTabelaBasica:[],
    };
     
  }
  retornaTabelas(){
        this.props.firebase.tabelas().once('value', snapshot => {
            const tabelasObject = snapshot.val();
            let tabelaPrincipal = [];
            let tabelaIntermediaria = [];
            let tabelaBasica = [];
            let tabelasList = [];
            if(tabelasObject){
                tabelasList = Object.keys(tabelasObject).map(key => ({
                ...tabelasObject[key],
                uid: key,
                }));
                tabelaPrincipal = tabelasList.filter(tabela => tabela.indAtivo === true && tabela.posicao == "PRINCIPAL");
                tabelaIntermediaria = tabelasList.filter(tabela => tabela.indAtivo === true && tabela.posicao == "INTERMEDIARIA");
                tabelaBasica = tabelasList.filter(tabela => tabela.indAtivo === true && tabela.posicao == "BASICO");
            }else{
              tabelaPrincipal = [];
              tabelaIntermediaria = [];
              tabelaBasica = [];
            }

            this.setState({
                listaTabelaPrincipal: tabelaPrincipal,
                listaTabelaIntermediaria: tabelaIntermediaria,
                listaTabelaBasica: tabelaBasica,
            });
        });
  }
  componentDidMount() {
      this.props.firebase.administrativos().once('value', snapshot => {
          
        const administrativoObject = Object.keys(snapshot.val()).map(key => ({
            ...snapshot.val()[key],
            uid: key,
        }))[0]
        
        this.setTextoSobre(administrativoObject) 
    });
    this.retornaTabelas();  
  }
  setTextoSobre = (administrativoObject) => {
      this.setState({ idVideoYouTube: administrativoObject.idVideoYouTube,
                      tituloVideoYoutube: administrativoObject.tituloVideoYoutube })
  }

  render() {
    const { idVideoYouTube, tituloVideoYoutube, listaTabelaPrincipal, listaTabelaIntermediaria, listaTabelaBasica } = this.state;

    const renderTabela = (tabela) => {
      return (
        <React.Fragment key={tabela.uid}>
          <Card.Title className="cardPainelTitulo">{tabela.tituloTabela} </Card.Title>
          <Card.Text>
            <br/>
            <img className="img-tabela-pontuacao" alt={tabela.tituloTabela}  id="TabelaPontuacao" src={tabela.urlTabela} />
          </Card.Text>
        </React.Fragment>
      );
    }

    return (
      <Card key="cardPainel" className="cardPainelTexto">
        <Card.Body key="cardBody">
          <Card.Title key="cardTitle" className="cardPainelTitulo">{tituloVideoYoutube}</Card.Title>
          <div className="img-tabela-pontuacao">
              <LiteYouTubeEmbed key="cardYoutube"
                  id={idVideoYouTube} // Default none, id of the video or playlist
                  adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
                  playlist={false} // Use  true when your ID be from a playlist
                  poster="hqdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                  title={tituloVideoYoutube} // a11y, always provide a title for iFrames: https://dequeuniversity.com/tips/provide-iframe-titles Help the web be accessible ;)
                  noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
              />
          </div>
          {listaTabelaPrincipal.map(renderTabela)}
          {listaTabelaIntermediaria.map(renderTabela)}
          {listaTabelaBasica.map(renderTabela)}
        </Card.Body>
      </Card>
    );
  }
}

const LandingForm = compose(
  withFirebase
)(LandingPageBase);

export default LandingPage;
export { LandingForm };