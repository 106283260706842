import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import '../css/paddockleague.css';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink } from 'react-router-dom';
import { Card } from 'react-bootstrap';

class SeasonAll extends Component {

  constructor(props) {
    super(props);
    let listaTemporadasInativas = this.props.location.state.temporadas_inativas;
    
    
    this.state = {
      isProcessando: false,
      lista_temporadas_inativas: listaTemporadasInativas,

      msgRetorno: '',
      statusOk: false,
      show: false, 
    };
  }
  
  componentWillUnmount() {
    this.props.firebase.temporadas().off();
  }
 
  render() {
    const { lista_temporadas_inativas, isProcessando, statusOk, msgRetorno, show } = this.state;
    
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    } 
    
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Todas Temporadas </Card.Title>
                <Card.Text className="wordStyle">
                  <div className="allTemporadas">
                    {lista_temporadas_inativas.map(temporada => (
                      <NavLink key={temporada.uid}
                               bg="light"
                               to={{pathname: '/temporada/'+temporada.uid, 
                                                    state: { temporada }, }}> {temporada.nome_temporada}
                      </NavLink >
                    ))}
                  </div>
                  {renderMessage()}
                </Card.Text>
              </Card.Body>
            </Card>
            
          </LoadingOverlay>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default compose(
  withFirebase
)(SeasonAll);