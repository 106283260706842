import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Image, Modal, Button, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import * as CONSTANTES from '../../../constants/constantes';
import '../../css/paddockleague.css';
import Delete from '../../css/delete.png';
import Detail from '../../css/detail.png';
import Ready from '../../css/ready.png';
import NoReady from '../../css/nonReady.png';
import GreenSignal from '../../css/greenSignal.png';
import RedSignal from '../../css/redSignal.png';
import ReactTooltip from "react-tooltip"; 

const DATE_OPTIONS = { year: 'numeric', month: 'numeric', day: 'numeric' };

const CampeonatoPage = () => (
  <CampeonatoForm />
);

class CampeonatoListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessando: false,
      statusOk: false,
      msgRetorno: '',
      campeonatos: [],
      show: false,
      campeonatoSelecionado: ''
    };
  }

  retornaCampeonatos(){
    this.setState({ isProcessando: true });

    this.props.firebase.campeonatos().once('value', snapshot => {
      const campeonatoObject = snapshot.val();
      let campeonatoList = [];
      if(campeonatoObject){
        campeonatoList = Object.keys(campeonatoObject).map(key => ({
          ...campeonatoObject[key],
          uid: key,
        }));
      }else{
        campeonatoList = [];
      }
      
      this.setState({
        campeonatos: campeonatoList,
        isProcessando: false,
      });
    });
  }

  componentDidMount() {
    this.retornaCampeonatos();
  }

  componentWillUnmount() {
    this.props.firebase.campeonatos().off();
  }
  

  render() {
    const { campeonatos, isProcessando, show, campeonatoSelecionado} = this.state;
    
    const handleClose = () => {
      this.setState({ show: false })
    };
    const handleShow = (campeonato) => {
      this.setState({ campeonatoSelecionado: campeonato, show: true })
    };
    const handleDeletarCampeonato = (uid) => {
      this.setState({ show: false, isProcessando: true })
      this.props.firebase.campeonato(uid).remove().then(() => {
        let listaCampeonato = campeonatos.filter(camp => camp.uid !== uid);
        this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Registro atualizado com sucesso!', campeonatos: listaCampeonato});
      }).catch(error => {
        this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
      })
    };
    const handlePrepararCampeonato = (campeonato) => {
      if (campeonato.ind_preparado === false){
        let campeonatoObject = new Map(Object.entries(campeonato));
        let listaPilotosInscritosCampeonato = campeonatoObject.get("lista_pilotos");
        campeonato["ind_preparado"] = true;
        for (let uidListaProvasSelecionadas of Object.keys(campeonato.lista_provas_selecionadas)){
          if (listaPilotosInscritosCampeonato) {
            campeonato.lista_provas_selecionadas[uidListaProvasSelecionadas]["lista_pilotos"] = listaPilotosInscritosCampeonato;
          }
        }
        this.props.firebase.campeonato(campeonato.uid).update(campeonato).then(() => {
          window.location.reload();
        }).catch(error => {
          this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
        })
      }
    }

    
    function geraData(dataBanco) {
        let data = new Date(dataBanco);
        return (data).toLocaleDateString('pt-BR', DATE_OPTIONS)
    }
    
    const renderCampeonato = (campeonato) => {
        let dataInicioFormatada = geraData(campeonato.data_inicio) 
        let dataFimFormatada = geraData(campeonato.data_fim)
        return (
            <tr key={campeonato.uid}>
                <td>{campeonato.nome_campeonato}</td>
                <td>{ dataInicioFormatada }</td>
                <td>{ dataFimFormatada }</td>
                <td>{campeonato.categoria.nome_categoria}</td>
                <td>
                  <Link to={{ pathname: `${ROUTES.LISTAGEM_CAMPEONATOS}/${campeonato.uid}`, 
                                        state: { campeonato }, }} >
                    <Image src={Detail} data-tip data-for='detailTip' className="IconTable" />
                    <ReactTooltip id='detailTip' type='error'>
                      <span>Detalhes</span>
                    </ReactTooltip>
                  </Link>
                </td>
                <td>
                  <Image src={Delete} data-tip data-for='deleteTip' className="IconTable" onClick={() => handleShow(campeonato)}/>
                  <ReactTooltip id='deleteTip' type='error'>
                    <span>Apagar</span>
                  </ReactTooltip>
                </td>
                <td className="centerTableImage" >
                  <Image src={!campeonato.ind_preparado ? Ready : NoReady} disabled data-tip data-for='readyTip' className="IconTable" onClick={() => handlePrepararCampeonato(campeonato)}/>
                  <ReactTooltip id='readyTip' type='error'>
                    <span>{!campeonato.ind_preparado ? 'Preparar Campeonato' : 'Campeonato Pronto'}</span>
                  </ReactTooltip>
                </td>
                <td  >
                  <Image src={!campeonato.ind_ativo ? RedSignal : GreenSignal} className="IconTable"/>
                </td>
            </tr>
        );
    }
 
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Listagem de Campeonatos</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <div className="wordStyle">
                  <Table striped bordered hover variant="dark">
                      <thead>
                          <tr>
                              <th>Nome Campeonato</th>
                              <th>Data Inicio</th>
                              <th>Data Fim</th>
                              <th>Categoria</th>
                          </tr>
                      </thead>
                      <tbody>
                          {campeonatos.map(renderCampeonato)}
                      </tbody>
                  </Table>
                  <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>{CONSTANTES.DRIVE_PRO_SERIES}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Você está prestes a deletar o {campeonatoSelecionado.nome_campeonato}, tem certeza disso? 
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                      </Button>
                      <Button variant="primary" onClick={() => handleDeletarCampeonato(campeonatoSelecionado.uid)}>
                        Ok, pode deletar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Card.Body>
            </Card>
          </LoadingOverlay>
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const CampeonatoForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(CampeonatoListBase);

export default CampeonatoPage;
export { CampeonatoForm };