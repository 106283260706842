import React, { Component } from 'react';
import { Card } from 'react-bootstrap'
import { compose } from 'recompose';
import '../css/paddockleague.css';  

const ContatoPage = () => (
    <ContatoForm />
);

class ContatoPageBase extends Component {
  /*constructor(props) {
    super(props); 
  }*/
  
  render() {
    return (
      <Card className="cardPainelTexto">
        <Card.Body>
          <Card.Title className="cardPainelTitulo">DriveProSeries League</Card.Title>
          <Card.Text className="wordStyle">
            Estamos iniciando o nosso projeto focado em pilotos iniciantes, se tiver alguma dúvida, sugestão ou algum problema no site, por gentileza entre em contato por e-mail:
            <a className="textoEmail" href="mailto:driveproseries@gmail.com">driveproseries@gmail.com</a> ou nos mande uma mensagem pelo WhatsApp para os números (44) 99937-1696 (Felipe) ou (44) 99171-0192 (André).
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

const ContatoForm = compose()(ContatoPageBase);

export default ContatoPage;
export { ContatoForm };