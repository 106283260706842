import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Image, Modal, Button, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import * as CONSTANTES from '../../../constants/constantes';
import '../../css/paddockleague.css';
import Delete from '../../css/delete.png';
import GreenSignal from '../../css/greenSignal.png';
import RedSignal from '../../css/redSignal.png';
import Detail from '../../css/detail.png';
import ReactTooltip from "react-tooltip";

const CalendarioPage = () => (
  <CalendarioForm />
);

class CalendarioListBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessando: false,
            statusOk: false,
            msgRetorno: '',
            calendarios: [],
            show: false,
            calendarioSelecionado: ''
        };
    }
    
    retornaCalendarios(){
        this.setState({ isProcessando: true });

        this.props.firebase.calendarios().once('value', snapshot => {
            const calendarioObject = snapshot.val();
            let calendarioList = [];
            if(calendarioObject){
                calendarioList = Object.keys(calendarioObject).map(key => ({
                ...calendarioObject[key],
                uid: key,
                }));
            }else{
                calendarioList = [];
            }

            this.setState({
                calendarios: calendarioList,
                isProcessando: false,
            });
        });
    }

    componentDidMount() {
        this.retornaCalendarios();
    }

    render() {
      const { calendarios, isProcessando, show, msgRetorno, calendarioSelecionado} = this.state;
      const handleClose = () => {
        this.setState({ show: false })
      };
      const handleShow = (calendario) => {
        this.setState({ calendarioSelecionado: calendario, show: true })
      };
      const handleDeletarCalendario = (uid) => {
        this.setState({ show: false, isProcessando: true })
        this.props.firebase.calendario(uid).remove().then(() => {
            let listaCalendarios = calendarios.filter(calendario => calendario.uid !== uid);
            this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Registro atualizado com sucesso!', calendarios: listaCalendarios});
        }).catch(error => {
            this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
        })
      };
      const renderCalendario = (calendario) => {
          return (
            <tr key={calendario.uid}>
                <td>{calendario.descricaoCalendario}</td>
                <td>{calendario.indProximaTemporada ? 'Sim' : 'Não'}</td>
                <td className="centerTableImage">
                  <Link to={{ pathname: `${ROUTES.LISTAGEM_CALENDARIO}/${calendario.uid}`, 
                                        state: { calendario }, }} >
                    <Image src={Detail} data-tip data-for='detailTip' className="IconTable" />
                    <ReactTooltip id='detailTip' type='error'>
                      <span>Detalhes</span>
                    </ReactTooltip>
                  </Link>
                </td>
                <td className="centerTableImage">
                  <Image src={Delete} data-tip data-for='deleteTip' className="IconTable" onClick={() => handleShow(calendario)}/>
                  <ReactTooltip id='deleteTip' type='error'>
                    <span>Apagar</span>
                  </ReactTooltip>
                </td>
            </tr>
          );
      }
      return (
      <AuthUserContext.Consumer>
      {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Listagem de Calendarios</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <div className="wordStyle">
                  <Table striped bordered hover variant="dark">
                      <thead>
                          <tr>
                              <th>Descrição Calendario</th>
                              <th>Calendario da próxima temporada</th>
                          </tr>
                      </thead>
                      <tbody>
                          {calendarios.map(renderCalendario)}
                      </tbody>
                  </Table>
                  <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                      <Modal.Title className="wordStyle">{CONSTANTES.DRIVE_PRO_SERIES}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="wordStyle">
                      Você está prestes a deletar o Calendario {calendarioSelecionado.descricaoCalendario}, tem certeza disso? 
                    </Modal.Body>
                    <Modal.Footer>
                      <Button className="wordStyle" variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                      </Button>
                      <Button className="wordStyle" variant="primary" onClick={() => handleDeletarCalendario(calendarioSelecionado.uid)}>
                        Ok, pode deletar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Card.Body>
            </Card>
          </LoadingOverlay>
      )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const CalendarioForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(CalendarioListBase);

export default CalendarioPage;
export { CalendarioForm };