import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage'

const prodConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
const devConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID

};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
	  this.storage = app.storage();
    
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      //url: "http://localhost:3000",
      url: "http://www.driveproseries.com.br",
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
			
            next(authUser);
          });
      } else {
        fallback();
      }
    });
  onlyRef = ref => this.db.ref(`${ref}`);

  // *** User API ***
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  // *** Message API ***
  message = uid => this.db.ref(`messages/${uid}`);
  messages = () => this.db.ref('messages');
  
  // *** Categoria API ***
  categoria = uid => this.db.ref(`categorias/${uid}`);
  categorias = () => this.db.ref('categorias');

  // *** Provas API ***
  prova = uid => this.db.ref(`provas/${uid}`);
  provas = () => this.db.ref('provas');

  // *** Triagem API ***
  triagem = uid => this.db.ref(`triagem/${uid}`);
  triagens = () => this.db.ref('trigem');

  // *** Campeonato API ***
  campeonato = uid => this.db.ref(`campeonato/${uid}`);
  campeonatos = () => this.db.ref('campeonato');

  // *** Temporada API ***
  temporada = uid => this.db.ref(`temporada/${uid}`);
  temporadas = () => this.db.ref('temporada');

  // *** Patrocinadores API ***
  patrocinador = uid => this.db.ref(`patrocinador/${uid}`);
  patrocinadores = () => this.db.ref('patrocinador');

  // *** Administrativo API ***
  administrativo = uid => this.db.ref(`administrativos/${uid}`);
  administrativos = () => this.db.ref('administrativos');

  // *** Noticias API ***
  noticia = uid => this.db.ref(`noticias/${uid}`);
  noticias = () => this.db.ref('noticias');

  // *** Calendarios API ***
  calendario = uid => this.db.ref(`calendarios/${uid}`);
  calendarios = () => this.db.ref('calendarios');

  // *** Tabelas API ***
  tabela = uid => this.db.ref(`tabelas/${uid}`);
  tabelas = () => this.db.ref('tabelas');

  regulamento = () => this.storage.ref('Regulamento').child('Regulamento Drive Pro Series League.pdf');
}

export default Firebase;