import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import '../../css/paddockleague.css';
import axios from "axios";

const CalendarioDetailPage = () => (
    <CalendarioDetalheForm /> 
);

class CalendarioDetalheBase extends Component {
    constructor(props) {
        super(props);
        let infoCalendario = props.location.state.calendario;
        
        this.state = {
            isProcessando: false,
            uid: infoCalendario.uid,
            descricaoCalendario: infoCalendario.descricaoCalendario,
            indProximaTemporada: infoCalendario.indProximaTemporada,
            posicao: infoCalendario.posicao,
            urlCalendario: infoCalendario.urlCalendario,
            deleteUrl: infoCalendario.deleteUrl,
            fileName: '',
            selectedFile: '',
            imgEnviada: false,

            ...props.location.state,
            msgRetorno: '',
            statusOk: false,
            show: false,
        };
        this.onChange = this.onChange.bind(this); 
    }
    
    onChange = event => {  
        this.setState({ [event.target.name]: event.target.value });
    };

    handleInputChange = event => {
        this.setState({ msgRetorno: '',imgEnviada: false, deleteUrl:''  });
        this.setState({ selectedFile: event.target.files[0], })
    }

    onChangeCheckBoxProximaTemporada = event => {
        this.setState({ indProximaTemporada: !this.state.indProximaTemporada,});
    };
    onSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ isInvalido: true });
        }else{ 
            this.setState({ isInvalido: false, isProcessando: true, });
            this.props.firebase.calendario(this.state.uid).update({
                descricaoCalendario: this.state.descricaoCalendario,
                indProximaTemporada: this.state.indProximaTemporada,
                posicao: parseInt(this.state.posicao),
                urlCalendario: this.state.urlCalendario,
                deleteUrl: this.state.deleteUrl
            }).then(() => {
                this.setState({msgRetorno: 'Calendario alterado com sucesso!', isProcessando: false, statusOk: true});
            }).catch(error => {
                this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
            })
        }
    };

    onUpload = event => {
        var fileName = this.state.selectedFile.name;
        if(fileName === undefined || null) {
            this.setState({ msgRetorno: "Escolha uma imagem!", isProcessando: false, statusOk: false});
        }else{
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
                let body = new FormData()
                body.set('key', process.env.REACT_APP_IMGBB_API_KEY);
                body.set('image', this.state.selectedFile)
                this.setState({ isProcessando: true });
                const response = axios.post('https://api.imgbb.com/1/upload',body)
                                      .then(result => {
                                          this.setState({ isProcessando: false });
                                          if(result.data.status == 200){
                                              this.setState({urlTabela: result.data.data.url, msgRetorno: 'Imagem carregada com sucesso!', statusOk: true, selectedFile: '', fileName: '', imgEnviada: true, deleteUrl: result.data.data.delete_url})
                                          }else{
                                              this.setState({ msgRetorno: result.statusText, statusOk: false}); 
                                          }
                                      })
               
            }else{
                this.setState({ msgRetorno: "Permitido apenas arquivos do formato PNG, JPG ou JPEG", isProcessando: false, statusOk: false});
            }
        } 
    }

    render() {
        let { descricaoCalendario, indProximaTemporada, posicao, urlCalendario, msgRetorno, isProcessando, statusOk, isInvalido, deleteUrl, fileName} = this.state;
        const renderMessage = ()=>{
            if (statusOk){
                return (<p className="styleSuccess">{msgRetorno}</p>)
            }else{
                return <p className="styleError">{msgRetorno}</p>
            }
        }
        const isInvalidFields = false;
        return (
            <AuthUserContext.Consumer>
            {authUser => (
                <Form noValidate validated={isInvalido} onSubmit={this.onSubmit}>
                    <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                        <Card className="cardPainelTexto">
                            <Card.Body>
                                <Card.Title className="cardPainelTitulo">Cadastro de Calendarios</Card.Title>
                                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                                <Card.Text className="wordStyle">
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formNomeCalendario">
                                            <Form.Label>Descrição Calendario</Form.Label>
                                            <Form.Control required pattern="\S(.*\S)?" maxLength={50} name="descricaoCalendario" value={descricaoCalendario} type="text" placeholder="Categoria Barrichello 2020" autoComplete="on" onChange={this.onChange}/>
                                            <Form.Control.Feedback type="invalid">
                                            Informe a Descrição do Calendario
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formUrlCalendario">
                                            <Form.Label>Url do Calendario</Form.Label>
                                            <Form.Control required pattern="\S(.*\S)?" name="urlCalendario" value={urlCalendario} type="text" placeholder="http://www.site.com.br/patrocinador.jpg" autoComplete="on" onChange={this.onChange}/>
                                            <Form.Control.Feedback type="invalid">
                                            Informe a URL do Calendario
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formUrlCalendarioDeletar">
                                            <Form.Label>Url do Calendario para visualizar/deletar</Form.Label>
                                            <Form.Control required pattern="\S(.*\S)?" disabled name="urlCalendarioDeletar" value={deleteUrl} type="text" placeholder="http://www.site.com.br/patrocinador.jpg"/>
                                        </Form.Group>
                                    </Form.Row>
                        
                                    <Form.Group id="formGridCheckbox">
                                        <Form.Check type="checkbox" name="indProximaTemporada" checked={indProximaTemporada} label="Próxima Temporada" onChange={this.onChangeCheckBoxProximaTemporada}/>
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="formGridPosicao">
                                            <Form.Label>Posição</Form.Label>
                                            <Form.Control required as="select" value={posicao} name="posicao" onChange={this.onChange}>
                                                <option key='1' value='1'>1º</option>
                                                <option key='2' value='2'>2º</option>
                                                <option key='3' value='3'>3º</option>
                                                <option key='4' value='4'>4º</option>
                                                <option key='5' value='5'>5º</option>
                                                <option key='6' value='6'>6º</option>
                                                <option key='7' value='7'>7º</option>
                                                <option key='8' value='8'>8º</option>
                                                <option key='9' value='9'>9º</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group id="formGridFile">
                                        <Form.File type="file" accept=".png, .jpg, .jpeg"  id="inputFile" label={fileName} onChange={this.handleInputChange}/>
                                        <Button className="margin10PxTop" variant="primary" onClick={() => this.onUpload()}> Carregar Imagem </Button>
                                    </Form.Group>
                                    {renderMessage()}
                                    <Button disabled={isInvalidFields}  variant="primary" type="submit">
                                        Cadastrar
                                    </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </LoadingOverlay>
                </Form>      
            )}
            </AuthUserContext.Consumer>
        );
    }
}


const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.DIRETOR]);

const CalendarioDetalheForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(CalendarioDetalheBase);

export default CalendarioDetailPage;
export { CalendarioDetalheForm }; 