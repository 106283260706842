import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import '../../css/paddockleague.css';

const PatrocinadorPage = () => (
  <PatrocinadorForm />
);

const INITIAL_STATE = {
  nomePatrocinador: '',
  urlPatrocinador: '',
  indDestaque: false,
  indAtivo: false,

  msgRetorno: '',
  isProcessando: false,
  statusOk: false,
  isInvalido: false,
};

class PatrocinadorFormBase extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            nomePatrocinador: '',
            urlBanner:'',
            urlPatrocinador: '',
            indDestaque: false,
            indAtivo: false,

            msgRetorno: '',
            isProcessando: false,
            statusOk: false,
            isInvalido: false,
        };
    }
    onSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ isInvalido: true });
        }else{ 
            this.setState({ isInvalido: false, isProcessando: true, });
            this.props.firebase.patrocinadores().push({
                nomePatrocinador: this.state.nomePatrocinador,
                urlBanner: this.state.urlBanner,
                urlPatrocinador: this.state.urlPatrocinador,
                indDestaque: this.state.indDestaque,
                indAtivo: this.state.indAtivo,
            }).then(() => {
                this.setState({ ...INITIAL_STATE });
                this.setState({msgRetorno: 'Patrocinador cadastrado com sucesso!', isProcessando: false, statusOk: true});
            }).catch(error => {
                this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
            })
        }
        
    };

   componentWillUnmount() {
       this.props.firebase.patrocinadores().off();
   }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeCheckBoxAtivo = event => {
        this.setState({ indAtivo: !this.state.indAtivo,});
    };
    onChangeCheckBoxDestaque = event => {
        this.setState({ indDestaque: !this.state.indDestaque,});
    };

    render() {
        let { nomePatrocinador, urlPatrocinador, msgRetorno, isProcessando, statusOk, isInvalido} = this.state;
        const renderMessage = ()=>{
            if (statusOk){
                return (<p className="styleSuccess">{msgRetorno}</p>)
            }else{
                return <p className="styleError">{msgRetorno}</p>
            }
        }
        const isInvalidFields = false;
        return (
        <AuthUserContext.Consumer>
        {authUser => (
            <Form noValidate validated={isInvalido} onSubmit={this.onSubmit}>
                <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                    <Card className="cardPainelTexto">
                    <Card.Body>
                        <Card.Title className="cardPainelTitulo">Cadastro de Patrocinadores</Card.Title>
                        <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                        <Card.Text className="wordStyle">
                        <Form.Row>
                            <Form.Group as={Col} controlId="formNomePatrocinador">
                                <Form.Label>Nome do Patrocinador</Form.Label>
                                <Form.Control required pattern="\S(.*\S)?" maxLength={50} name="nomePatrocinador" type="text" placeholder="RedBull" autoComplete="on" onChange={this.onChange}/>
                                <Form.Control.Feedback type="invalid">
                                Informe o Nome do Patrocinador
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formUrlPatrocinador">
                                <Form.Label>Url do Banner</Form.Label>
                                <Form.Control required pattern="\S(.*\S)?" name="urlBanner" type="text" placeholder="http://www.site.com.br/patrocinador.jpg" autoComplete="on" onChange={this.onChange}/>
                                <Form.Control.Feedback type="invalid">
                                Informe a URL do Banner do Patrocinador
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formUrlPatrocinador">
                                <Form.Label>Url do Patrocinador</Form.Label>
                                <Form.Control required pattern="\S(.*\S)?" name="urlPatrocinador" type="text" placeholder="http://www.site.com.br/patrocinador.jpg" autoComplete="on" onChange={this.onChange}/>
                                <Form.Control.Feedback type="invalid">
                                Informe a URL do Patrocinador
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Group id="formGridCheckboxDestaque">
                            <Form.Check type="checkbox" name="indDestaque" label="Destacar Patrocinador" onChange={this.onChangeCheckBoxDestaque}/>
                        </Form.Group>
                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" name="indAtivo" label="Patrocinio Ativo" onChange={this.onChangeCheckBoxAtivo}/>
                        </Form.Group>
                        {renderMessage()}
                        <Button disabled={isInvalidFields}  variant="primary" type="submit">
                            Cadastrar
                        </Button>
                        </Card.Text>
                    </Card.Body>
                    </Card>
                </LoadingOverlay>
            </Form>      
        )}
        </AuthUserContext.Consumer>
        );
    }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const PatrocinadorForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(PatrocinadorFormBase);

export default PatrocinadorPage;
export { PatrocinadorForm };