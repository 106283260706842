import React, { Component } from 'react';
import { Card, Table, Image } from 'react-bootstrap'
import { compose } from 'recompose';
import '../../css/paddockleague.css';
import * as CONSTANTES from '../../../constants/constantes';

const GrandPrixPage = () => (
    <GrandPrixPageForm />
);

class GrandPrixBase extends Component {
  render() {
    return (
      <Card className="cardPainelTexto">
        <Card.Body>
          <Card.Title className="cardPainelTitulo"> Lista de Grand Prix - 2022 </Card.Title>
          <Card.Text className="wordStyle">
            Fórmula 1 é atualmente a categoria mais avançada do esporte a motor e é regulamentada pela Federação Internacional de Automobilismo (FIA). A temporada do Campeonato Mundial de Fórmula 1 consiste em uma série de corridas, conhecidas como Grandes Prêmios, geralmente realizadas em circuitos específicos e, em alguns casos, em pistas montadas em ruas fechadas de cidades. Os resultados de cada corrida são combinados para determinar dois campeonatos anuais, um para pilotos (Campeonato Mundial de Pilotos) e outro para construtores (Campeonato Mundial de Construtores).
            <br/>
            A lista abaixo contém as pistas que compõem a temporada 2022.
            <br/>
            <Table striped bordered hover variant="dark" className="tituloTabela">
              <thead>
                <tr className="tituloTabela" >
                  <th>GP</th>
                  <th>Temporada 2022</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                  <td>1</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_BAHRAIN}/>
                    <a href={CONSTANTES.URL_BAHRAIN} target="noreferrer">Bahrain</a>
                  </td>
                </tr>
                <tr key="2">
                  <td>2</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_ARABIA_SAUDITA}/>
                    <a href={CONSTANTES.URL_ARABIA_SAUDITA} target="noreferrer">Arábia Saudita</a>
                  </td>
                </tr>
                <tr key="3">
                  <td>3</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_AUSTRALIA}/>
                    <a href={CONSTANTES.URL_AUSTRALIA} target="noreferrer">Austrália</a>
                  </td>
                </tr>
                <tr key="4">
                  <td>4</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_EMILIA_ROMAGNA}/>
                    <a href={CONSTANTES.URL_EMILIA_ROMAGNA} target="noreferrer">Emília-Romanha</a>
                  </td>
                </tr>
                <tr key="5">
                  <td>5</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_MIAMI}/>
                    <a href={CONSTANTES.URL_MIAMI} target="noreferrer">Miami</a>
                  </td>
                </tr>
                <tr key="6">
                  <td>6</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_ESPANHA}/>
                    <a href={CONSTANTES.URL_ESPANHA} target="noreferrer">Espanha</a>
                  </td>
                </tr>
                <tr key="7">
                  <td>7</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_MONACO}/>
                    <a href={CONSTANTES.URL_MONACO} target="noreferrer">Mônaco</a>
                  </td>
                </tr>
                <tr key="8">
                  <td>8</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_AZERBAIJAO}/>
                    <a href={CONSTANTES.URL_AZERBAIJAO} target="noreferrer">Azerbaijão</a>
                  </td>
                </tr>
                <tr key="9">
                  <td>9</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_CANADA}/>
                    <a href={CONSTANTES.URL_CANADA} target="noreferrer">Canadá</a>
                  </td>
                </tr>
                <tr key="10">
                  <td>10</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_GRA_BRETANHA}/>
                    <a href={CONSTANTES.URL_GRA_BRETANHA} target="noreferrer">Grã-Bretanha</a>
                  </td>
                </tr>
                <tr key="11">
                  <td>11</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_AUSTRIA}/>
                    <a href={CONSTANTES.URL_AUSTRIA} target="noreferrer">Áustria</a>
                  </td>
                </tr>
                <tr key="12">
                  <td>12</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_FRANCA}/>
                    <a href={CONSTANTES.URL_FRANCA} target="noreferrer">França</a>
                  </td>
                </tr>
                <tr key="13">
                  <td>13</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_HUNGRIA}/>
                    <a href={CONSTANTES.URL_HUNGRIA} target="noreferrer">Hungria</a>
                  </td>
                </tr>
                <tr key="14">
                  <td>14</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_BELGICA}/>
                    <a href={CONSTANTES.URL_BELGICA} target="noreferrer">Bélgica</a>
                  </td>
                </tr>
                <tr key="15">
                  <td>15</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_PAISES_BAIXOS}/>
                    <a href={CONSTANTES.URL_PAISES_BAIXOS} target="noreferrer">Países Baixos</a>
                  </td>
                </tr>
                <tr key="16">
                  <td>16</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_ITALIA}/>
                    <a href={CONSTANTES.URL_ITALIA} target="noreferrer">Itália</a>
                  </td>
                </tr>
                <tr key="17">
                  <td>17</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_RUSSIA}/>
                    <a href={CONSTANTES.URL_RUSSIA} target="noreferrer">Rússia</a>
                  </td>
                </tr>
                <tr key="18">
                  <td>18</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_SINGAPURA}/>
                    <a href={CONSTANTES.URL_SINGAPURA} target="noreferrer">Singapura</a>
                  </td>
                </tr>
                <tr key="19">
                  <td>19</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_JAPAO}/>
                    <a href={CONSTANTES.URL_JAPAO} target="noreferrer">Japão</a>
                  </td>
                </tr>
                <tr key="20">
                  <td>20</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_ESTADOS_UNIDOS}/>
                    <a href={CONSTANTES.URL_ESTADOS_UNIDOS} target="noreferrer">Estados Unidos</a>
                  </td>
                </tr>
                <tr key="21">
                  <td>21</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_MEXICO}/>
                    <a href={CONSTANTES.URL_MEXICO} target="noreferrer">Cidade do México</a>
                  </td>
                </tr>
                <tr key="22">
                  <td>22</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_BRASIL}/>
                    <a href={CONSTANTES.URL_BRASIL} target="noreferrer">São Paulo</a>
                  </td>
                </tr>
                <tr key="23">
                  <td>23</td>
                  <td className="semBorda">
                    <Image className="bandeiraPaisSize" src={CONSTANTES.BANDEIRA_ABU_DHABI}/>
                    <a href={CONSTANTES.URL_ABU_DHABI} target="noreferrer">Abu Dhabi</a>
                  </td>
                </tr>
                
              </tbody>
            </Table>
            <p style={{fontSize: '12px'}}>
            Referências:
              <br/> 
              <a href="https://pt.wikipedia.org/wiki/Lista_de_Grandes_Pr%C3%AAmios_de_F%C3%B3rmula_1" target="noreferrer">Lista de Grandes Prêmios de Fórmula 1</a>
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

const GrandPrixPageForm = compose(
)(GrandPrixBase);

export default GrandPrixPage;
export { GrandPrixPageForm };