import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import '../../css/paddockleague.css';

const INITIAL_STATE = {
    nome_prova: '',
    cidade: '',
	pais: '',
    msgRetorno: '',
    isProcessando: false,
    statusOk: false,
    isValido: false,
};

const ProvasPage = () => (
    <ProvasForm />
);

class ProvasFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome_prova: '',
      cidade: '',
	  pais: '',
      msgRetorno: '',
      isProcessando: false,
      statusOk: false,
      isValido: false,
    };
  }
  componentWillUnmount() {
      this.props.firebase.provas().off();
  }
  
  onSubmit = event => {
    const { nome_prova, cidade, pais } = this.state;
    this.setState({ isProcessando: true });
    if (pais === 'null' || pais === ''){
        this.setState({msgRetorno: 'Selecione o País!', isProcessando: false, statusOk: false});
    }else{
        this.props.firebase.provas().push({
            nome_prova: nome_prova,
            cidade: cidade,
            pais: pais,
        }).then(() => {
            this.setState({ ...INITIAL_STATE });
            this.setState({msgRetorno: 'Prova cadastrada com sucesso!', isProcessando: false, statusOk: true});
        }).catch(error => {
            this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
        });
    }
    event.preventDefault();
  };
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    let { nome_prova, cidade, msgRetorno, isProcessando, statusOk, isValido} = this.state;
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    const isInvalidFields = nome_prova === '' || cidade === '';
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form noValidate validated={isValido} onSubmit={this.onSubmit}>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                <Card className="cardPainelTexto">
                    <Card.Body>
                        <Card.Title className="cardPainelTitulo">Cadastro de Provas</Card.Title>
                        <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                        <Card.Text className="wordStyle">
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridNomeProva">
                                    <Form.Label>Nome da Prova</Form.Label>
                                    <Form.Control required maxLength={20} name="nome_prova" type="text" placeholder="Interlagos" autoComplete="on" onChange={this.onChange}/>
                                    <Form.Control.Feedback type="invalid">
                                    Informe o nome da Prova
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCidade">
                                    <Form.Label>Cidade</Form.Label>
                                    <Form.Control required maxLength={20} name="cidade" type="Text" placeholder="São Paulo" autoComplete="on" onChange={this.onChange}/>
                                    <Form.Control.Feedback type="invalid">
                                    Informe a cidade da Prova
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridPais">
                                    <Form.Label>País</Form.Label>
                                    <Form.Control as="select" name="pais" onChange={this.onChange}>
                                        <option key='null' value='null'>Selecione o País</option>
                                        <option key='Abu Dhabi' value='Abu Dhabi'>Abu Dhabi</option>
										<option key='Arábia Saudita' value='Arábia Saudita'>Arábia Saudita</option>
										<option key='Alemanha' value='Alemanha'>Alemanha</option>
                                        <option key='Australia' value='Australia'>Australia</option>
										<option key='Áustria' value='Áustria'>Áustria</option>
										<option key='Azerbaijão' value='Azerbaijão'>Azerbaijão</option>
                                        <option key='Bahrein' value='Bahrein'>Bahrein</option>
                                        <option key='Bélgica' value='Bélgica'>Bélgica</option>
										<option key='Brasil' value='Brasil'>Brasil</option>
										<option key='Canadá' value='Canadá'>Canadá</option>
										<option key='Cidade do México' value='Cidade do México'>Cidade do México</option>
										<option key='China' value='China'>China</option>
										<option key='Espanha' value='Espanha'>Espanha</option>
										<option key='EUA' value='EUA'>EUA</option>
										<option key='França' value='França'>França</option>
                                        <option key='Grã-Bretanha' value='Grã-Bretanha'>Grã-Bretanha</option>
										<option key='Holanda' value='Holanda'>Holanda</option>
										<option key='Hungria' value='Hungria'>Hungria</option>
										<option key='Imola' value='Imola'>Imola</option>
										<option key='Itália' value='Itália'>Itália</option>
										<option key='Japão' value='Japão'>Japão</option>
										<option key='Mônaco' value='Mônaco'>Mônaco</option>
										<option key='Portugal' value='Portugal'>Portugal</option>
										<option key='Rússia' value='Rússia'>Rússia</option>
										<option key='Singapura' value='Singapura'>Singapura</option>
										<option key='Vietnã' value='Vietnã'>Vietnã</option>
                                        <option key='Países Baixos' value='Países Baixos'>Países Baixos</option>
                                    </Form.Control>
                                </Form.Group>    
                            </Form.Row>
                            {renderMessage()}
                            <Button disabled={isInvalidFields}  variant="primary" type="submit">
                                Enviar
                            </Button>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </LoadingOverlay>
          </Form>      
      )}
      </AuthUserContext.Consumer>
    );
  }
}
const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const ProvasForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(ProvasFormBase);

export default ProvasPage;
export { ProvasForm };