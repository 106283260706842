import React, { Component } from 'react';
import { Card, Table, Image } from 'react-bootstrap'
import { compose } from 'recompose';
import '../../css/paddockleague.css';
import * as CONSTANTES from '../../../constants/constantes';


const TipoPneuPage = () => (
    <TipoPneuForm />
);

class TipoPneuBase extends Component {
  render() {
    return (
      <Card className="cardPainelTexto">
        <Card.Body>
          <Card.Title className="cardPainelTitulo">Tipos de Pneus </Card.Title>
          <Card.Text className="wordStyle">
            Desde 2011, a Pirelli tem sido a fornecedora de pneus do campeonato de F1 oficial.
            <br/>
            Em 2019, para que o público casual entendesse mais os pneus, a Pirelli, ao pedido da FIA, reduziu os números de compostos para três de pista seca, sendo somente nomeado "Macio", "Médio" e "Duro". As cores para o macio são o mesmo que o "Supermacio", os médios o mesmo que o "Macio" e os duros o mesmo que o "Médio." Mesmo assim, os compostos são numerados tecnicamente de C1 a C5, sendo o C1 mais duro, enquanto o C5 mais macio. A consistência dos pneus afeta o desempenho do carro: pneus mais macios permitem desenvolver mais velocidade, mas são menos duráveis, e o inverso vale para pneus duros.
            
            <br/>
            <Table striped bordered hover variant="dark" className="tituloTabela">
              <thead>
                <tr className="tituloTabela" >
                  <th>Nome do composto</th>
                  <th>Cor</th>
                  <th>Banda de Rolamento</th>
                  <th>Condições de condução</th>
                  <th>Dry Type</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                  <td>Macio</td>
                  <td className="semBorda">
                    <Image className="pneuSize" src={CONSTANTES.PNEU_VERMELHO}/>
                  </td>
                  <td>Lisa</td>
                  <td>Seco</td>
                  <td>Option</td>
                </tr>
                <tr key="2">
                  <td>Médio</td>
                  <td className="semBorda">
                    <Image className="pneuSize" src={CONSTANTES.PNEU_AMARELO}/>
                  </td>
                  <td>Lisa</td>
                  <td>Seco</td>
                  <td>Option/Prime</td>
                </tr>
                <tr key="3">
                  <td>Duro</td>
                  <td className="semBorda">
                    <Image className="pneuSize" src={CONSTANTES.PNEU_BRANCO}/>
                  </td>
                  <td>Lisa</td>
                  <td>Seco</td>
                  <td>Prime</td>
                </tr>
                <tr key="4">
                  <td>Intermediário</td>
                  <td className="semBorda">
                    <Image className="pneuSize" src={CONSTANTES.PNEU_VERDE}/>
                  </td>
                  <td>Sulcos (em V)</td>
                  <td>Molhado (chuva fraca/moderada)</td>
                  <td>X</td>
                </tr>
                <tr key="5">
                  <td>Chuva</td>
                  <td className="semBorda">
                    <Image className="pneuSize" src={CONSTANTES.PNEU_AZUL}/>
                  </td>
                  <td>Sulcos (biscoito)</td>
                  <td>Molhado (chuva extrema)</td>
                  <td>X</td>
                </tr>
              </tbody>
            </Table>
            <p style={{fontSize: '12px'}}>
            Referências:
              <br/> 
              <a href="https://www.pirelli.com/tyres/pt-br/motorsport/f1/pneus" target="noreferrer">Pirelli</a>
            
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

const TipoPneuForm = compose(
)(TipoPneuBase);

export default TipoPneuPage;
export { TipoPneuForm };