import { Component } from 'react';

class ErrorMessages extends Component{
    
    errosAutenticacao=function(error) {
        var mensagem = "";
        if (error){
            switch (error.code) {
                case "auth/app-deleted":
                    mensagem = "DPS-001: Houve um problema com a instância do banco de dados. Entre em contato com o suporte"
                    break
                case "auth/app-not-authorized":
                    mensagem = "DPS-002: A aplicação não tem autorização para realizar utilizar o banco de dados."
                    break
                case "auth/invalid-api-key":
                    mensagem = "DPS-003: Chave da API invalida."
                    break
                case "auth/invalid-user-token":
                    mensagem = "DPS-004: Token utilizado está invalido. Feche a aplicação e entre novamente!"
                    break;
                case "auth/network-request-failed":
                    mensagem = "DPS-005: Houve um problema com a conexão. Verifique se você está conectado a uma rede Wi-Fi ou 3G"
                    break;
                case "auth/operation-not-allowed":
                    mensagem = "DPS-006: Operação não permitida."
                    break;
                case "auth/requires-recent-login":
                    mensagem = "DPS-007: Houve um problema com o seu Login. Feche a aplicação e realize o login novamente!"
                    break;
                case "auth/too-many-requests":
                    mensagem = "DPS-008: Seu dispositivo foi temporariamente bloqueado devido ao número excessivo de requisições. Aguarde!"
                    break;
                case "auth/user-disabled":
                    mensagem = "DPS-009: Sua conta foi desabilitada, entre em contato com o suporte!"
                    break;
                case "auth/weak-password":
                    mensagem = "DPS-010: Sua senha deve conter no minimo 6 caracteres."
                    break;
                case "auth/wrong-password":
                    mensagem = "DPS-011: O e-mail e/ou senha informados estão inválidos. Verifique!"
                    break;
                case "auth/invalid-email":
                    mensagem = "DPS-012: O e-mail informado está invalido. Verifique!"
                    break;
                case "auth/user-not-found":
                    mensagem = "DPS-013: O e-mail informado não existe. Verifique!"
                    break;
                case "auth/email-already-in-use":
                    mensagem = "DPS-014: O e-mail informado já está sendo utilizado. Verifique!"
                    break;
                default:
                    mensagem = "DPS-999: Houve um erro não tratado - "+error.code
                    break;
            }
        }
        return mensagem
    }

}
export default ErrorMessages;