import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Form, Button, Col, OverlayTrigger, Tooltip, Card, Modal } from 'react-bootstrap'
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import '../css/paddockleague.css';
import ErrorMessages from '../ErrorMessages';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect  } from 'react-router-dom'
import MaskedFormControl from 'react-bootstrap-maskedinput'
import * as PROVATRIAGEM from '../../constants/provasTriagem';
import * as CONSTANTES from '../../constants/constantes';
import * as moment from 'moment';

const SignUpPage = () => (
  <SignUpForm />
);

const INITIAL_STATE = {
  nomeUsuario: '',
  endereco: '',
  complemento: '',
  cidade: '',
  estado: '',
  cep: '',
  email: '',
  senha1: '',
  senha2: '',
  psnPlus: '',
  psn: '',
  celular: '',
  jogoEscolhido: CONSTANTES.JOGO_DEFAULT,
  jogoDisponivel: CONSTANTES.JOGOS_DISPONIVEIS,
  provas: [],
  utilizaPsnPlus: false,
  categoria: [],
  error: null,
  isValido: false,
  isProcessando: false,
  statusOK: false,
  show: false,
};

class SignUpFormBase extends Component {
  errorMessages = null;
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  componentWillUnmount() {
    this.errorMessages = null;
  }
  
  componentDidMount() {
    this.errorMessages = new ErrorMessages();
    this.retornaCategorias();
    this.getProvas();
  }

  getProvas(){
      this.setState({ isProcessando: true });
      
      this.props.firebase.provas().once('value', snapshot => {
      const provasObject = snapshot.val();

      const provasList = Object.keys(provasObject).map(key => ({
        ...provasObject[key],
        uid: key,
        tempo: '',
      }));
      this.setState({
        provas: provasList.filter(prova => prova.nome_prova === PROVATRIAGEM.NOME_PROVA_SPIELBERG || 
                                           prova.nome_prova === PROVATRIAGEM.NOME_PROVA_INTERLAGOS || 
                                           prova.nome_prova === PROVATRIAGEM.NOME_PROVA_HUNGARORING),
        isProcessando: false,
      });
    });
  }

  retornaCategorias(){
      this.setState({ isProcessando: true });
      this.props.firebase.categorias().once('value', snapshot => {
        const categoriaObject = snapshot.val();
        if (categoriaObject) {
          let listCategoria = Object.keys(categoriaObject).map(key => ({
            ...categoriaObject[key],
            uid: key,
          }));
          listCategoria = listCategoria.filter((categoria) =>{
            return categoria.nome_categoria === 'SEM CATEGORIA'
          })
          let aux = [];
          aux[listCategoria[0].uid] = listCategoria[0];
          this.setState({
            categoria: aux,
            isProcessando: false,
          });
        } else {
          this.setState({ categoria: null, isProcessando: false,});
        }
      }, error =>{
          this.setState({ categoria: null, isProcessando: false, error: error,});
      });
  }

  validaTempoTriagem(){
    const { provas/*, jogoEscolhido*/ } = this.state;
    
    let mensagemErro = "";
    let tempo = "";
    let isValido = false;
    
    for(let i=0; i < provas.length; i++){
        tempo = provas[i].tempo.replace(new RegExp("_", "g"), "");
        if(tempo.length < 8){
          mensagemErro = "Ajuste o tempo para a prova "+provas[i].nome_prova
          this.setState({error: mensagemErro, statusOk: false});
          isValido = false;
          break;
        }else{
          isValido = true;
        }
    }
    return isValido;
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckBox = event => {
    this.setState({ utilizaPsnPlus: !this.state.utilizaPsnPlus,});
  }
  onChangeJogo = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeTempo = event => {
    this.setState({ msgRetorno: "" });
    let provas = [...this.state.provas];
    let index = provas.findIndex(el => el.nome_prova === event.target.name);
    provas[index] = {...provas[index], tempo: event.target.value};
    this.setState({ provas });
  };
  
  onClick = (event) => {
    this.setState({ show: true })
    event.preventDefault();
  }

  render() {
    const handleCadastro = event => {
      const { nomeUsuario, endereco, complemento, cidade, estado, cep, email, senha1, senha2, psnPlus, psn, utilizaPsnPlus, categoria, isValido, error, statusOK, credenciaisOk, celular,provas, jogoEscolhido, show } = this.state;
      const roles = {};
      roles[ROLES.PILOTO] = ROLES.PILOTO;
      const form = event.currentTarget;
      let numCelular = '';
      this.setState({show: false});

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ isValido: true });
      }else{
        
        if (senha1 !== senha2){
          alert('As senhas informadas não são iguais, verifique!');
          event.preventDefault();
        }else{
          if(nomeUsuario === '' || nomeUsuario === null || typeof nomeUsuario === 'undefined'){
            alert('Informe o nome do usuário.');
            event.preventDefault();
          }else if(email === '' || email === null || typeof email === 'undefined'){
            alert('Informe um e-mail válido.');
            event.preventDefault();
          }else if(psnPlus === '' || psnPlus === null || typeof psnPlus === 'undefined'){
            alert('Informe a conta na PSN Plus');
            event.preventDefault();
          }else if(psn === '' || psn === null || typeof psn === 'undefined'){
            alert('Informe a conta na PSN');
            event.preventDefault();
          }else{
            numCelular = celular.replace(new RegExp("_", "g"), "");
            if (numCelular === '' || numCelular === null || numCelular.length < 15){
              alert('Informe o número do celular!');
              event.preventDefault();
            }else{
              if (this.validaTempoTriagem()){
                this.setState({ isValido: true, isProcessando: true });
                this.props.firebase
                  .doCreateUserWithEmailAndPassword(email, senha1).then(authUser => {
                    // Create a user in your Firebase realtime database
                    return this.props.firebase.user(authUser.user.uid).set({
                      nomeUsuario,
                      endereco,
                      complemento,
                      cidade,
                      estado,
                      cep,
                      email,
                      psnPlus,
                      psn,
                      celular,
                      utilizaPsnPlus,
                      categoria,
                      roles,
                      provas,
                      jogoEscolhido: jogoEscolhido,
                      dataCadastro: moment().utc().format("DD-MM-YYYY")
                    });
                  }).then(() => {
                    return this.props.firebase.doSendEmailVerification();
                  }).then(() => {
                    this.setState({ ...INITIAL_STATE });
                    this.setState({statusOk: true, credenciaisOk: true});
                  }).catch(error => {
                    this.setState({ error: this.errorMessages.errosAutenticacao(error), isProcessando: false, statusOk: false});
                  });
                event.preventDefault();
            }else{
              event.preventDefault();
            }
           }
          }
        }
      }
    };
    const {
      nomeUsuario,
      //endereco,
      //complemento,
      //cidade,
      //estado,
      //cep,
      email,
      senha1,
      senha2,
      psnPlus,
      psn,
      utilizaPsnPlus,
      error,
      isValido,
      isProcessando,
      statusOK,
      credenciaisOk,
      celular,
      provas,
      jogoEscolhido,
      jogoDisponivel,
      show
    } = this.state;
    const handleClose = () => {
      this.setState({ show: false })
    };
    const redirect = () => {
      if (credenciaisOk){
          return (<Redirect to={ROUTES.LISTAGEM_NEWS} />)
      }
    }
    const renderMessage = ()=>{
      if (statusOK){
        return (<p className="styleSuccess">{error}</p>)
      }else{
        return <p className="styleError">{error}</p>
      }
    }
    function renderTooltip(props) {
      return (
        <Tooltip id="button-tooltip" {...props}>
          
        </Tooltip>
      );
    }
    const renderProvaTriagem = (prova) => {
        return (
            <Form.Row key={"formRow" + prova.nome_prova}>
                <Form.Group as={Col} key={"formGrid" + prova.nome_prova} controlId={"formGrid" + prova.nome_prova}>
                    <Form.Label>Nome da Prova</Form.Label>
                    <Form.Control name={prova.nome_prova} readOnly value={prova.nome_prova}/>
                </Form.Group>
                
                <Form.Group key={"tempo" + prova.nome_prova} controlId={"tempo" + prova.nome_prova}>
                    <Form.Label>1º Tempo </Form.Label>
                    <MaskedFormControl type='text'  name={prova.nome_prova} mask='1:11.111' value={prova.tempo} placeholder="1:27.625" onChange={this.onChangeTempo}/>
                </Form.Group>
            </Form.Row>
        );
    }

    const isInvalid =
      senha1 !== senha2 ||
      senha1 === '' ||
      email === '' ||
      nomeUsuario === '';

      return(
        <Form noValidate validated={isValido} onSubmit={this.onSubmit}>
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Registra-se</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Para participar da nossa liga você precisa se registrar para ter acesso a área do piloto, onde terá acesso ao processo de seletiva e indicação da categoria adequada.</Card.Title>
                <Card.Title className="cardPainelSubTitulo styleError">Observações: O número do celular será utilizado unicamente para o controle interno para montarmos os grupos de cada grid de cada categoria.</Card.Title>
                <Card.Text className="wordStyle">
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridNome">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control required pattern="\S(.*\S)?" name="nomeUsuario" maxLength={200} placeholder="Ayrton Senna da Silva" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Preencha o seu nome piloto! Se necessário retire o caracter em branco no inicio ou no final
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formGridCelular">
                      <Form.Label>Celular</Form.Label>
                      <MaskedFormControl type='text' name="celular" mask='(11) 11111-1111' placeholder="(11) 12345-1234" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Informe o número do celular.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  
                  {/*
                  <Form.Group controlId="formGridEndereco">
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control required pattern="\S(.*\S)?" name="endereco" maxLength={150} placeholder="Av/Rua Nome da Rua" onChange={this.onChange}/>
                    <Form.Control.Feedback type="invalid">
                      O endereço será utilizado para o envio das premiações, por favor informe um endereço válido
                    </Form.Control.Feedback>
                  </Form.Group>
                  
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridBairro">
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control required pattern="\S(.*\S)?" name="complemento" maxLength={150} placeholder="Bairro, número" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Informe o seu bairro.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group controlId="formGridCelular">
                      <Form.Label>Celular</Form.Label>
                      <MaskedFormControl type='text' name="celular" mask='(11) 11111-1111' placeholder="(11) 12345-1234" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Informe o número do celular.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridCidade">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control required pattern="\S(.*\S)?" name="cidade" maxLength={80} placeholder="Joinville" onChange={this.onChange} />
                      <Form.Control.Feedback type="invalid">
                        Preencha a sua cidade.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group as={Col} controlId="formGridEstado">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control required as="select" name="estado" onChange={this.onChange}>
                        <option>AC - Acre</option>
                        <option>AL - Alagoas</option>
                        <option>AP - Amapá</option>
                        <option>AM - Amazonas</option>
                        <option>BA - Bahia</option>
                        <option>CE - Ceará</option>
                        <option>DF - Distrito Federal</option>
                        <option>ES - Espírito Santo</option>
                        <option>GO - Goiás</option>
                        <option>MA - Maranhão</option>
                        <option>MT - Mato Grosso</option>
                        <option>MS - Mato Grosso do Sul</option>
                        <option>MG - Minas Gerais</option>
                        <option>PA - Pará</option>
                        <option>PB - Paraíba</option>
                        <option>PR - Paraná</option>
                        <option>PE - Pernambuco</option>
                        <option>PI - Piauí</option>
                        <option>RJ - Rio de Janeiro</option>
                        <option>RN - Rio Grande do Norte</option>
                        <option>RS - Rio Grande do Sul</option>
                        <option>RO - Rondônia</option>
                        <option>RR - Roraima</option>
                        <option>SC - Santa Catarina</option>
                        <option>SP - São Paulo</option>
                        <option>SE - Sergipe</option>
                        <option>TO - Tocantins</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Informe o estado em que reside.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group as={Col} controlId="formGridCep">
                      <Form.Label>Cep</Form.Label>
                      <Form.Control required pattern="\S(.*\S)?" name="cep" maxLength={12} placeholder="Código de Endereçamento Postal" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Informe o seu CEP.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                  </Form.Row>
                  */}
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control required pattern="\S(.*\S)?" name="email" maxLength={60} type="email" placeholder="Digite seu e-mail" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Preencha o seu e-mail!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Senha</Form.Label>
                      <Form.Control required maxLength={15} autoComplete="on" name="senha1" type="password" placeholder="Senha" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Informe uma senha
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword2">
                      <Form.Label>Repita a senha</Form.Label>
                      <Form.Control required maxLength={15} autoComplete="on" name="senha2" type="password" placeholder="Repita a senha" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Repita a senha digitada anteriormente
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="formGridPsnPlus">
                      <Form.Label>PSN Plus</Form.Label>
                      <Form.Control required pattern="\S(.*\S)?" name="psnPlus" maxLength={30} type="text" placeholder="Informe a sua PSN Plus" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Informe a sua PSN Plus, pois será através dela que você será encontrado no jogo.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formGridPsn">
                      <Form.Label>PSN</Form.Label>
                      <Form.Control required pattern="\S(.*\S)?" name="psn" maxLength={30} type="text" placeholder="Informe a sua PSN" onChange={this.onChange}/>
                      <Form.Control.Feedback type="invalid">
                        Informe a sua PSN, pois será através dela que você será encontrado no jogo.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                      <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                        <Form.Check type="checkbox" name="utilizaPsnPlus" label="Utilizar conta da PSN Plus para identificação" onChange={this.onChangeCheckBox} />
                      </OverlayTrigger>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="6" controlId="formGridJogo">
                        <Form.Label>Jogo em que foi realizado a tomada de tempo</Form.Label>
                        <Form.Control required as="select" value={jogoEscolhido} name="jogoEscolhido" onChange={this.onChangeJogo}>
                        {jogoDisponivel.map(jogo => (
                            <option key={jogo} value={jogo}> {jogo}</option>
                        ))}    
                        </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Card.Title className="cardPainelTitulo">O carro usado para a tomada de tempo deve ser obrigatóriamente a Mercedes</Card.Title>
                  {provas.map(renderProvaTriagem)}
                  <Card.Title className="cardPainelSubTitulo">De acordo com o item 4.4 do nosso regulamento, os pilotos que porventura, tentarem burlar a triagem a fim de obter vantagem no campeonato serão prontamente excluídos da liga.</Card.Title>
                  {renderMessage()} 
                  {redirect()}
                  <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                      <Modal.Title className="wordStyle centered">{CONSTANTES.DRIVE_PRO_SERIES}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="wordStyle">
                        A comunicação entre a Liga DriveProSeries e os pilotos ocorrerá EXCLUSIVAMENTE por e-mail.
                        Verificar também a caixa de spam.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button className="wordStyle" variant="secondary" onClick={() => handleClose()}> Discordo </Button>
                      <Button className="wordStyle" variant="primary" onClick={(event) => handleCadastro(event) }>
                          Concordo
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Button variant="primary" type="submit" onClick={(event) => this.onClick(event)}>
                    Enviar
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </LoadingOverlay>
        </Form>
      );
  }
}

const SignUpLink = () => (
  <p>
    Você ainda não se registrou? <Link to={ROUTES.SIGN_UP}>Registra-se agora</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };