import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import '../css/paddockleague.css';
import MaskedFormControl from 'react-bootstrap-maskedinput'
import * as PROVATRIAGEM from '../../constants/provasTriagem';
import * as CONSTANTES from '../../constants/constantes.js';
import moment from 'moment';

const INITIAL_STATE = {
    msgRetorno: '',
    isProcessando: false,
    statusOk: false,
    indPossuiTempoCadastrado: false,
    isValido: false,
};

const TriagemPage = () => (
  <Card className="cardPainelTexto">
    <Card.Body>
        <Card.Title className="cardPainelTitulo">Triagem </Card.Title>
        <Card.Text>
            <div className="wordStyle">
                <p>Para verificarmos em qual categoria você melhor se encaixa, é necessário o preenchimento deste formulário.</p>
                <p>Realize uma tomada de tempo em cada uma das pistas abaixo podendo utilizar qualquer carro com a opção para Desempenho Igual e cadastre seus tempos.</p>
            </div>
            <TriagemForm />
        </Card.Text>
    </Card.Body>
  </Card>  
);

class TriagemFormBase extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      msgRetorno: '',
      provas: [],
      jogoEscolhido: CONSTANTES.JOGO_DEFAULT,//'F1 - 2022', //Valor default
      jogoDisponivel: CONSTANTES.JOGOS_DISPONIVEIS,//['F1 - 2022', 'F1 - 2022'],
      isProcessando: false,
      statusOk: false,
      indPossuiTempoCadastrado: false,
      isValido: false,
    };

  }
  getProvas(){
      this.setState({ isProcessando: true });
      
      this.props.firebase.provas().once('value', snapshot => {
      const provasObject = snapshot.val();

      const provasList = Object.keys(provasObject).map(key => ({
        ...provasObject[key],
        uid: key,
        tempo: '',
      }));
      this.setState({
        provas: provasList.filter(prova => prova.nome_prova === PROVATRIAGEM.NOME_PROVA_SPIELBERG || 
                                           prova.nome_prova === PROVATRIAGEM.NOME_PROVA_INTERLAGOS || 
                                           prova.nome_prova === PROVATRIAGEM.NOME_PROVA_HUNGARORING),
        isProcessando: false,
      });
    });
  }
  componentWillUnmount() {
      this.props.firebase.triagens().off();
  }
  
  componentDidMount() {
    this.getProvas();
    this.props.firebase.auth.onAuthStateChanged((authUser) => {
        this.getTempoTriagem(authUser);
    });
    
  }
  

  getTempoTriagem(authUser){  
      this.setState({ isProcessando: true });
      //this.props.firebase.triagem(authUser.uid).once('value', snapshot => {
      this.props.firebase.user(authUser.uid).once('value', snapshot => {
          let temposTriagem = snapshot.val().provas;
          if (temposTriagem){
              this.setState({ provas: temposTriagem,
                              jogoEscolhido: snapshot.val().jogoEscolhido,
                              isProcessando: false, 
                              indPossuiTempoCadastrado: true, });
          }else{
              this.setState({isProcessando: false, indPossuiTempoCadastrado: false, });
          }
      }).catch(error =>{
          this.setState({isProcessando: false, indPossuiTempoCadastrado: false, msgRetorno: error,});
      })
  }
  
  onSubmit = (event, authUser) => {
    const { provas, jogoEscolhido } = this.state;
    
    let mensagemErro = "";
    let tempo = "";
    let isValido = false;

    for(let i=0; i < provas.length; i++){
        tempo = provas[i].tempo.replace(new RegExp("_", "g"), "");
        if(tempo.length < 8){
            mensagemErro = "Ajuste o tempo para a prova "+provas[i].nome_prova
            this.setState({msgRetorno: mensagemErro, statusOk: false});
            isValido = false;
            break;
        }else{
            isValido = true;
        }
    }
    if (isValido){
        this.setState({ isProcessando: true });
        this.props.firebase.user(authUser.uid).update({
            provas: provas,
            jogoEscolhido: jogoEscolhido,
            dataHoraTriagem: moment().format("DD/MM/YYYY HH:mm:ss")
        }).then(() => {
            this.setState({ ...INITIAL_STATE });
            this.setState({msgRetorno: 'Tempos cadastrados com sucesso!', isProcessando: false, statusOk: true, indPossuiTempoCadastrado: true});
        }).catch(error => {
            this.setState({ msgRetorno: error, isProcessando: false, statusOk: false });
        })
        /*this.props.firebase.triagem(authUser.uid).set({
            provas,
            jogoEscolhido: jogoEscolhido,
        }).then(() => {
            this.props.firebase.user(authUser.uid).update({
                jogoEscolhido:jogoEscolhido
            }).then(() => {
                this.setState({ ...INITIAL_STATE });
                this.setState({msgRetorno: 'Tempos cadastrados com sucesso!', isProcessando: false, statusOk: true, indPossuiTempoCadastrado: true});
            }).catch(error => {
                this.setState({ msgRetorno: error, isProcessando: false, statusOk: false });
            })
        }).catch(error => {
            this.setState({ msgRetorno: error, isProcessando: false, statusOk: false });
        });*/
    }
    event.preventDefault();
    
  };

  /*
  onSubmit = event => {
      const {uid, categoria, categoriaList } = this.state;
      this.setState({ isProcessando: true });
      this.props.firebase.user(uid).update({
          categoria:categoriaList.filter(cat => cat.nome_categoria === categoria)[0]
      }).then(() => {
          this.setState({ isProcessando: false, statusOk: true, error: 'Registro atualizado com sucesso!'});
      }).catch(error => {
          this.setState({ isProcessando: false, statusOk: false, error: error });
      })
      
      event.preventDefault();;
  }
  */
  
  onChange = event => {
    this.setState({ msgRetorno: "" });
    let provas = [...this.state.provas];
    let index = provas.findIndex(el => el.nome_prova === event.target.name);
    provas[index] = {...provas[index], tempo: event.target.value};
    this.setState({ provas });
  };

  onChangeJogo = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    let { provas, msgRetorno, isProcessando, statusOk, isValido, indPossuiTempoCadastrado, jogoDisponivel, jogoEscolhido} = this.state;
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    const renderProvaTriagem = (prova) => {
        return (
            <Form.Row key={"formRow" + prova.nome_prova}>
                <Form.Group as={Col} key={"formGrid" + prova.nome_prova} controlId={"formGrid" + prova.nome_prova}>
                    <Form.Label>Nome da Prova</Form.Label>
                    <Form.Control name={prova.nome_prova} readOnly value={prova.nome_prova}/>
                </Form.Group>
                
                <Form.Group key={"tempo" + prova.nome_prova} controlId={"tempo" + prova.nome_prova}>
                    <Form.Label>1º Tempo </Form.Label>
                    <MaskedFormControl type='text' disabled={indPossuiTempoCadastrado} name={prova.nome_prova} mask='1:11.111' value={prova.tempo} placeholder="1:27.625" onChange={this.onChange}/>
                </Form.Group>
            </Form.Row>
        );
    }
    
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form className="wordStyle" noValidate validated={isValido} onSubmit={event => this.onSubmit(event, authUser)}>
            <LoadingOverlay active={isProcessando}
                            spinner
                            text='Aguarde!'>
                {provas.map(renderProvaTriagem)}
                <Form.Row>
                    <Form.Group as={Col} md="6" controlId="formGridJogo">
                        <Form.Label>Jogo em que foi realizado a tomada de tempo</Form.Label>
                        <Form.Control required disabled={indPossuiTempoCadastrado} as="select" value={jogoEscolhido} name="jogoEscolhido" onChange={this.onChangeJogo}>
                        {jogoDisponivel.map(jogo => (
                            <option key={jogo} value={jogo}> {jogo}</option>
                        ))}    
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                {renderMessage()}
                <p className="styleError">De acordo com o item 4.4 do nosso regulamento, os pilotos que porventura, tentarem burlar a triagem a fim de obter vantagem no campeonato serão prontamente excluídos da liga.</p>
                <Button variant="primary" disabled={indPossuiTempoCadastrado} type="submit">
                    Enviar tempos
                </Button>
            </LoadingOverlay>
          </Form>      
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.PILOTO];

const TriagemForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(TriagemFormBase);

export default TriagemPage;
export { TriagemForm };
