import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Image, Modal, Button, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import * as CONSTANTES from '../../../constants/constantes';
import '../../css/paddockleague.css';
import Delete from '../../css/delete.png';
import GreenSignal from '../../css/greenSignal.png';
import RedSignal from '../../css/redSignal.png';
import Detail from '../../css/detail.png';
import ReactTooltip from "react-tooltip";

const TabelaPage = () => (
  <TabelaForm />
);

class TabelaListBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessando: false,
            statusOk: false,
            msgRetorno: '',
            tabelas: [],
            show: false,
            tabelaSelecionada: ''
        };
    }

    retornaTabelas(){
        this.setState({ isProcessando: true });

        this.props.firebase.tabelas().once('value', snapshot => {
            const tabelasObject = snapshot.val();
            let tabelasList = [];
            if(tabelasObject){
                tabelasList = Object.keys(tabelasObject).map(key => ({
                ...tabelasObject[key],
                uid: key,
                }));
            }else{
                tabelasList = [];
            }

            this.setState({
                tabelas: tabelasList,
                isProcessando: false,
            });
        });
    }
    
    componentDidMount() {
        this.retornaTabelas();
    }

    render() {
      const { tabelas, isProcessando, show, msgRetorno, tabelaSelecionada} = this.state;
      const handleClose = () => {
        this.setState({ show: false })
      };
      const handleShow = (tabela) => {
        this.setState({ tabelaSelecionada: tabela, show: true })
      };
      const handleDeletarTabela = (uid) => {
        this.setState({ show: false, isProcessando: true })
        this.props.firebase.tabela(uid).remove().then(() => {
            let listaTabelas = tabelas.filter(tabela => tabela.uid !== uid);
            this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Registro atualizado com sucesso!', tabelas: listaTabelas});
        }).catch(error => {
            this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
        })
      };
      const renderBtnExcluir = (tabela, roles) => {
        if(roles.ADMIN === ROLES.ADMIN) {
          return(
            <td className="centerTableImage">
              <Image src={Delete} data-tip data-for='deleteTip' className="IconTable" onClick={() => handleShow(tabela)}/>
              <ReactTooltip id='deleteTip' type='error'>
                <span>Apagar</span>
              </ReactTooltip>
            </td>
          );
        }
      }
      /*const renderTabela = (tabela) => {
          return (
            <tr key={tabela.uid}>
                <td>{tabela.tituloTabela}</td> 
                <td className="centerTableImage">
                  <Link to={{ pathname: `${ROUTES.LISTAGEM_TABELA}/${tabela.uid}`, 
                                        state: { tabela }, }} >
                    <Image src={Detail} data-tip data-for='detailTip' className="IconTable" />
                    <ReactTooltip id='detailTip' type='error'>
                      <span>Detalhes</span>
                    </ReactTooltip>
                  </Link>
                </td>
                <td className="centerTableImage">
                  <Image src={Delete} data-tip data-for='deleteTip' className="IconTable" onClick={() => handleShow(tabela)}/>
                  <ReactTooltip id='deleteTip' type='error'>
                    <span>Apagar</span>
                  </ReactTooltip>
                </td>
                <td className="centerTableImage" >
                  <Image src={!tabela.indAtivo ? RedSignal : GreenSignal} disabled data-tip data-for='readyTip' className="IconTable"/>
                  <ReactTooltip id='readyTip' type='error'>
                    <span>{!tabela.indAtivo ? 'Inativo' : 'Ativo'}</span>
                  </ReactTooltip>
                </td>
            </tr>
          );
      }*/

      const render = (tabelas, roles) =>{
        let array = [];
        for(let i = 0; i < tabelas.length; i++) {
          let tabela = tabelas[i];
          array.push(
            <tr key={tabela.uid}>
                <td>{tabela.tituloTabela}</td> 
                <td className="centerTableImage">
                  <Link to={{ pathname: `${ROUTES.LISTAGEM_TABELA}/${tabela.uid}`, 
                                        state: { tabela }, }} >
                    <Image src={Detail} data-tip data-for='detailTip' className="IconTable" />
                    <ReactTooltip id='detailTip' type='error'>
                      <span>Detalhes</span>
                    </ReactTooltip>
                  </Link>
                </td>
                {renderBtnExcluir(tabela, roles)}
                <td className="centerTableImage" >
                  <Image src={!tabela.indAtivo ? RedSignal : GreenSignal} disabled data-tip data-for='readyTip' className="IconTable"/>
                  <ReactTooltip id='readyTip' type='error'>
                    <span>{!tabela.indAtivo ? 'Inativo' : 'Ativo'}</span>
                  </ReactTooltip>
                </td>
            </tr>
          );
        }
        return array;
      }
      return (
      <AuthUserContext.Consumer>
      {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Listagem de Tabelas</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <div className="wordStyle">
                  <Table striped bordered hover variant="dark">
                      <thead>
                          <tr>
                              <th>Título Tabela</th>
                          </tr>
                      </thead>
                      <tbody>
                        {render(tabelas, authUser.roles)}
                      </tbody>
                  </Table>
                  <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                      <Modal.Title className="wordStyle">{CONSTANTES.DRIVE_PRO_SERIES}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="wordStyle">
                      Você está prestes a deletar a Tabela {tabelaSelecionada.tituloTabela}, tem certeza disso? 
                    </Modal.Body>
                    <Modal.Footer>
                      <Button className="wordStyle" variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                      </Button>
                      <Button className="wordStyle" variant="primary" onClick={() => handleDeletarTabela(tabelaSelecionada.uid)}>
                        Ok, pode deletar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Card.Body>
            </Card>
          </LoadingOverlay>
      )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.DIRETOR]);

const TabelaForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(TabelaListBase);

export default TabelaPage;
export { TabelaForm };