import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withFirebase } from '../Firebase';


class SeasonList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      temporadas: [],
      temporadas_inativas:[],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.temporadas().once('value', snapshot => {
        const temporadaObject = snapshot.val();
        if (temporadaObject) {
          let temporadaAtiva, temporadaInativa;
          let temporadaList = Object.keys(temporadaObject).map(key => ({
            ...temporadaObject[key],
            uid: key,
          }));
          temporadaAtiva = temporadaList.filter(temp => temp.ind_ativo === true);
          temporadaInativa = temporadaList.filter(temp => temp.ind_ativo === false);
          this.setState({
            temporadas: temporadaAtiva,
            temporadas_inativas: temporadaInativa,
            loading: false,
          });
        } else {
          this.setState({ temporadas: [], temporadas_inativas: [], loading: false,});
        }
      }, 
      error =>{
          console.log(error)
      });
  }

  componentWillUnmount() {
    this.props.firebase.temporadas().off();
  }

  render() {
    const { temporadas, temporadas_inativas } = this.state;
    
    const renderLinkMaisTemporadas = (temporadas_inativas) =>{
      if (temporadas_inativas !== null){
        if (temporadas_inativas.length > 0){
          return(<NavLink key='1'
                          className='dropdown-item'
                          to={{pathname: '/temporadas/', 
                                        state: { temporadas_inativas }, }}> Todos 
                 </NavLink>);
        }
      }
    }
    const renderLinkTemporadas = (temporadas) => {
      if (temporadas.length <= 0 ){
        return (<NavLink key="-1" 
                         className='dropdown-item'
                         to={{pathname: ''}}> Temporada não finalizada</NavLink >)
      }
    }

    return (
        <React.Fragment>
          {renderLinkTemporadas(temporadas)}
          {temporadas.map(temporada => (
            <NavLink key={temporada.uid}
                    className='dropdown-item' 
                    to={{pathname: '/temporada/'+temporada.uid, 
                                        state: { temporada }, }}> {temporada.nome_temporada}
            </NavLink >
          ))}
          {renderLinkMaisTemporadas(temporadas_inativas)}
        </React.Fragment>
    );
  }
}

export default withFirebase(SeasonList);