import React, { Component } from 'react';
import { Card, Table } from 'react-bootstrap'
import { compose } from 'recompose';
import '../../css/paddockleague.css';

const PontuacaoPage = () => (
    <PontuacaoForm />
);

class PontuacaoBase extends Component {
  render() {
    return (
      <Card className="cardPainelTexto">
        <Card.Body>
          <Card.Title className="cardPainelTitulo">Sistema de Pontuação </Card.Title>
          <Card.Text className="wordStyle">
            O sistema de pontuação da Fórmula 1 para determinar o piloto vencedor de cada temporada, foi criado pela FIA em 1950. Desde 1958, o mesmo sistema passou a ser usado para determinar o construtor vencedor de cada temporada.
            A tabela de pontuação da categoria já passou por diversas modificações durante os anos.
            A Temporada 2010 marcou o início de um novo esquema de pontuação que passou a premiar até o 10º colocado em cada Grande Prêmio.
            A partir da temporada 2019, o piloto que fizer a volta mais rápida e estiver dentre os dez primeiros colocados irá ganhar um pontro extra.
            <br/>
            <Table striped bordered hover variant="dark" className="tituloTabela">
              <thead>
                <tr className="tituloTabela" >
                  <th>Temporada/Posição</th>
                  <th>1º</th>
                  <th>2º</th>
                  <th>3º</th>
                  <th>4º</th>
                  <th>5º</th>
                  <th>6º</th>
                  <th>7º</th>
                  <th>8º</th>
                  <th>9º</th>
                  <th>10º</th>
                </tr>
              </thead>
              <tbody>
                <tr key="1">
                  <td>1950-1959</td>
                  <td>8</td>
                  <td>6</td>
                  <td>4</td>
                  <td>3</td>
                  <td>2</td>
                </tr>
                <tr key="2">
                  <td>1960</td>
                  <td>8</td>
                  <td>6</td>
                  <td>4</td>
                  <td>3</td>
                  <td>2</td>
                  <td>1</td>
                </tr>
                <tr key="3">
                  <td>1961-1990</td>
                  <td>9</td>
                  <td>6</td>
                  <td>4</td>
                  <td>3</td>
                  <td>2</td>
                  <td>1</td>
                </tr>
                <tr key="4">
                  <td>1991-2002</td>
                  <td>10</td>
                  <td>6</td>
                  <td>4</td>
                  <td>3</td>
                  <td>2</td>
                  <td>1</td>
                </tr>
                <tr key="5">
                  <td>2003-2009</td>
                  <td>10</td>
                  <td>8</td>
                  <td>6</td>
                  <td>5</td>
                  <td>4</td>
                  <td>3</td>
                  <td>2</td>
                  <td>1</td>
                </tr>
                <tr key="6">
                  <td>2010-Presente</td>
                  <td>25</td>
                  <td>18</td>
                  <td>15</td>
                  <td>12</td>
                  <td>10</td>
                  <td>8</td>
                  <td>6</td>
                  <td>4</td>
                  <td>2</td>
                  <td>1</td>
                </tr>
              </tbody>
            </Table>
            <p style={{fontSize: '12px'}}>
            Referências:
              <br/> 
              <a href="https://www.redbull.com/pt-pt/desvenda-o-sistema-de-pontuacao-na-f1" target="noreferrer">Redbull</a>
              <br/>
              <a href="https://pt.wikipedia.org/wiki/Sistema_de_pontua%C3%A7%C3%A3o_da_F%C3%B3rmula_1" target="noreferrer">Sistema de Pontuação</a>
              
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

const PontuacaoForm = compose(
)(PontuacaoBase);

export default PontuacaoPage;
export { PontuacaoForm };