import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import ErrorMessages from '../ErrorMessages';
import LoadingOverlay from 'react-loading-overlay';
//<div className="card card-block padding10px width50porcento centered">
const PasswordForgetPage = () => (
    <PasswordForgetForm />
);

const INITIAL_STATE = {
  email: '',
  error: null,
  isValido: false,
  isSucesso: false,
  isProcessando: false,
};

class PasswordForgetFormBase extends Component {
  errorMessages = null;
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  componentWillUnmount() {
    this.errorMessages = null;
  }
  
  componentDidMount() {
    this.errorMessages = new ErrorMessages();
  }

  onSubmit = event => {
    const { email } = this.state;
    this.setState({ isProcessando: true });
    
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.setState({ isSucesso: true, isProcessando: false });
      }).catch(error => {
        this.setState({ ...INITIAL_STATE });
        this.setState({ error: this.errorMessages.errosAutenticacao(error), isProcessando: false });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, isValido, isSucesso, isProcessando } = this.state;

    const isInvalid = email === '';

    return (
      <Form noValidate validated={isValido} onSubmit={this.onSubmit} className="width50porcento centered">
        <LoadingOverlay active={isProcessando} spinner text='Aguarde'>
          <Card className="cardPainelTexto">
            <Card.Body>
              <Card.Title className="cardPainelTitulo">Esqueceu sua senha? </Card.Title>
              <Card.Text className="wordStyle">
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control required value={email} name="email" type="email" placeholder="Informe o e-mail" onChange={this.onChange}/>
                    <Form.Control.Feedback type="invalid">
                      Informe o e-mail!
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row> 
                {error && <p className="styleError">{error}</p>}
                {isSucesso && <p className="styleSuccess">A nova senha foi enviada para seu e-mail, verifique!</p>}
                <Button disabled={isInvalid} variant="primary" type="submit">
                      Gerar nova senha
                </Button>
              </Card.Text>
            </Card.Body>
          </Card>
        </LoadingOverlay>
      </Form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Esqueceu a senha?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };