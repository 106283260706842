import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Button, Col, Card } from 'react-bootstrap'
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import '../../css/paddockleague.css';
import MaskedFormControl from 'react-bootstrap-maskedinput'

const CampeonatoPage = () => (
  <CampeonatoForm />
);
const INITIAL_STATE = {
    nome_campeonato: '',
    descricao: '',
	  data_inicio: '',
    data_fim: '',
    categoria: '',
    lista_provas_selecionadas: [],
    lista_pilotos: [],
    ind_ativo: false,
    qtde_voltas: 0,
    qtde_max_piloto: 0,

    msgRetorno: '',
    categoria_nome: '-1',
    isProcessando: false,
    statusOk: false,
    isInvalido: false,
};
class CampeonatoFormBase extends Component {
  
  constructor(props) {
    super(props);
    this.listaProvaSelecionada = new Map();

    this.state = {
      nome_campeonato: '',
      descricao: '',
	    data_inicio: '',
      data_fim: '',
      categoria: '',
      lista_provas_selecionadas: [],
      lista_pilotos: [],
      ind_ativo: false,
      percentual_prova: 0,
      qtde_max_piloto: 0,

      categoria_list: [],
      provas_list: [],
      categoria_nome: '-1',
      msgRetorno: '',
      isProcessando: false,
      statusOk: false,
      isInvalido: false,
    };
  }
  
  retornaCategorias(){
      this.setState({ isProcessando: true });
      this.props.firebase.categorias().once('value', snapshot => {
        const categoriaObject = snapshot.val();
        if (categoriaObject) {
          const listCategoria = Object.keys(categoriaObject).map(key => ({
            ...categoriaObject[key],
            uid: key,
          }));
          
          this.setState({
            categoria_list: listCategoria,
            isProcessando: false,
            statusOk: true,
          });
        } else {
          this.setState({ categoria_list: null, isProcessando: false, statusOk: false});
        }
      }, error =>{
          this.setState({ categoria_list: null, isProcessando: false, msgRetorno: error, statusOk: false});
      });
  }
  retornaProvas(){
    this.setState({ isProcessando: true });
    this.props.firebase.provas().once('value', snapshot => {
        const provasObject = snapshot.val();
        if (provasObject) {
          const listProvas = Object.keys(provasObject).map(key => ({
            ...provasObject[key],
            uid: key,
          }));

          this.setState({
            provas_list: listProvas,
            isProcessando: false,
            statusOk: true,
          });
        } else {
          this.setState({ provas_list: null, isProcessando: false, statusOk: false});
        }
      }, error =>{
          this.setState({ provas_list: null, isProcessando: false, error: error, statusOk: false});
      });
  }
  componentWillUnmount() {
      this.props.firebase.campeonatos().off();
  }
  componentDidMount() {  
    this.retornaCategorias();
    this.retornaProvas();
  }
  
  onSubmit = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isInvalido: true });
    }else{
      let provasSelecionadas = new Map(Object.entries(this.state.lista_provas_selecionadas)); 
      
      if(provasSelecionadas.length === 0){
        this.setState({ msgRetorno: 'Adicione as provas que serão disponibilizadas no campeonato!', statusOk: false});
      }else{
        if(this.state.categoria_nome === '-1'){
          this.setState({ msgRetorno: 'Seleciona uma categoria!', statusOk: false});
        }else{
          this.setState({ isInvalido: false, isProcessando: true, });
          this.props.firebase.campeonatos().push({
            nome_campeonato: this.state.nome_campeonato,
            descricao: this.state.descricao,
	          data_inicio: this.state.data_inicio,
            data_fim: this.state.data_fim,
            categoria: this.state.categoria_list.filter(cat => cat.nome_categoria === this.state.categoria_nome)[0],
            lista_provas_selecionadas: this.state.lista_provas_selecionadas,
            ind_ativo: this.state.ind_ativo,
            percentual_prova: this.state.percentual_prova,
            qtde_max_piloto: this.state.qtde_max_piloto,
            ind_preparado: false,
          }).then(() => {
            
            this.setState({ ...INITIAL_STATE });
            this.setState({msgRetorno: 'Campeonato cadastrado com sucesso!', isProcessando: false, statusOk: true});
          }).catch(error => {
            this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
          })
        }
      }
    }
    event.preventDefault();
  };
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckBox = event => {
    this.setState({ ind_ativo: !this.state.ind_ativo,});
  }

  handleSelect = event => {
        let { provas_list, lista_provas_selecionadas } = this.state;
        let provaSelecionada = lista_provas_selecionadas[event.target.value];
        
        if(provaSelecionada === undefined || null){
          let auxProva = provas_list.filter(prova => prova.uid === event.target.value)[0];
          this.listaProvaSelecionada = lista_provas_selecionadas;
          this.listaProvaSelecionada[auxProva.uid] = auxProva
        }else{
          let novaLista = new Map();
          for (let chave of Object.keys(this.listaProvaSelecionada)){
            if (chave !== event.target.value){
              novaLista[chave] = this.listaProvaSelecionada[chave];
            }
          }
          this.listaProvaSelecionada = novaLista; 
        }
        this.setState({lista_provas_selecionadas: this.listaProvaSelecionada})
  }

  render() {
    let { categoria_list, provas_list, lista_provas_selecionadas, data_inicio, data_fim, msgRetorno, isProcessando, statusOk, isInvalido} = this.state;
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    
    const isInvalidFields = false;
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form noValidate validated={isInvalido} onSubmit={this.onSubmit}>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                <Card className="cardPainelTexto">
                  <Card.Body>
                    <Card.Title className="cardPainelTitulo">Cadastro de Campeonato</Card.Title>
                    <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                    <Card.Text className="wordStyle">
                      <Form.Row>
                          <Form.Group as={Col} controlId="formNomeCampeonato">
                              <Form.Label>Nome do Campeonato</Form.Label>
                              <Form.Control required pattern="\S(.*\S)?" maxLength={50} name="nome_campeonato" type="text" placeholder="Campeonato Redbull" autoComplete="on" onChange={this.onChange}/>
                              <Form.Control.Feedback type="invalid">
                              Informe um Nome do Campeonato
                              </Form.Control.Feedback>
                          </Form.Group>
                      </Form.Row>
                      <Form.Row>
                          <Form.Group as={Col} controlId="formDescricao">
                              <Form.Label>Descrição</Form.Label>
                              <Form.Control required maxLength={200} rows="4" name="descricao" as="textarea" placeholder="Campeonato composto por provas longas e clima aleatório" autoComplete="on" onChange={this.onChange}/>
                              <Form.Control.Feedback type="invalid">
                              Informe uma Descrição do Campeonato
                              </Form.Control.Feedback>
                          </Form.Group>
                      </Form.Row>
                      <Form.Row>
                          <Form.Group as={Col} md="4.5" controlId="formDtInicio">
                              <Form.Label>Data Inicio </Form.Label>
                              <MaskedFormControl type='date' name="data_inicio" mask='1111-11-11' placeholder="25/01/2020" onChange={this.onChange} selected={data_inicio}/>
                              <Form.Control.Feedback type="invalid">
                                Informe a data de inicio do campeonato.
                              </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} md="4.5" controlId="formDtFim">
                              <Form.Label>Data Fim </Form.Label>
                              <MaskedFormControl type='date' name="data_fim" mask='1111-11-11' placeholder="25/01/2021" onChange={this.onChange} selected={data_fim}/>
                          </Form.Group>
                          <Form.Group as={Col} md="4" controlId="formGridCategoria">
                              <Form.Label>Categoria</Form.Label>
                              <Form.Control required as="select" name="categoria_nome" onChange={this.onChange}>
                                  <option key="-1" value="-1"></option>
                                  {categoria_list.map(categoria => (
                                    <option key={categoria.uid} value={categoria.nome_categoria}> {categoria.nome_categoria}</option>
                                  ))}
                              </Form.Control>
                          </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formPercentualProva">
                          <Form.Label>Percentual da Prova</Form.Label>
                          <Form.Control required as="select" name="percentual_prova" onChange={this.onChange}>
                            <option key="25" value="25%"> 25%</option>
                            <option key="50" value="50%"> 50%</option>
                            <option key="75" value="75%"> 75%</option>
                            <option key="100" value="100%"> 100%</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formQtdeMaxPiloto">
                          <Form.Label>Quantidade Máxima de Pilotos</Form.Label>
                          <Form.Control required min={1} max={100}  name="qtde_max_piloto" type="number" placeholder="20" autoComplete="on" onChange={this.onChange}/>
                          <Form.Control.Feedback type="invalid">
                              Informe a quantidade máxima de Pilotos
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formProvasDisponiveis">
                          <Form.Label>Provas Disponiveis</Form.Label>
                          <Form.Control as="select" multiple onDoubleClick={this.handleSelect}>
                            {provas_list.map(prova => (
                              <option key={prova.uid} value={prova.uid}>{prova.pais} - {prova.nome_prova}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        
                        <Form.Group as={Col} controlId="formProvasSelecionadas">
                          <Form.Label>Provas Selecionadas</Form.Label>
                          <Form.Control as="select" multiple disabled>
                            {Object.keys(lista_provas_selecionadas).map((uid) => 
                              <option key={uid} value={lista_provas_selecionadas[uid].nome_prova}>{lista_provas_selecionadas[uid].pais} - {lista_provas_selecionadas[uid].nome_prova}</option>
                            )}
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>
                      <Form.Group id="formGridCheckbox">
                          <Form.Check type="checkbox" name="ind_ativo" label="Ativo" onChange={this.onChangeCheckBox}/>
                      </Form.Group>
                      {renderMessage()}
                      <Button disabled={isInvalidFields}  variant="primary" type="submit">
                          Cadastrar
                      </Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
            </LoadingOverlay>
          </Form>      
      )}
      </AuthUserContext.Consumer>
    );
  }
}


const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const CampeonatoForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(CampeonatoFormBase);

export default CampeonatoPage;
export { CampeonatoForm };