import React,  { Component } from 'react';
import img from 'react-image';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
//import HomePage from '../Home';
import AccountPage from '../Account/Profile';
import CategoriaPage from '../Categoria';
//import SeasonPage from '../Season';
import {Season as SeasonPage, SeasonAll as SeasonAllPage } from '../Season';
import NotFound from '../NotFound';
import PasswordPage from '../Account/Password';
import { Categoria, CategoriaList, CategoriaDetail } from '../Admin/Categoria';
import CadastroProvasPage from '../Admin/Provas';
import { PilotoList, PilotoDetail } from '../Admin/Pilotos';
import { Campeonato, CampeonatoDetail, CampeonatoList} from '../Admin/Campeonato';
import { Temporada, TemporadaDetail, TemporadaList} from '../Admin/Temporada';
import { Patrocinador, PatrocinadorDetail, PatrocinadorList } from '../Admin/Patrocinador';
import { Administrativo } from '../Admin/Administrativo';
import { Noticia, NoticiaDetail, NoticiaList } from '../Admin/Noticia';
import { Notificacao } from '../Admin/Notificacao';
import { NewsList } from '../News';
import TriagemPage from '../Triagem';
import { Sobre } from '../Sobre';
import { UltimosResultados } from '../UltimosResultados';
import { ClassificacaoPilotos } from '../ClassificacaoPilotos';
import { ClassificacaoConstrutores } from '../ClassificacaoConstrutores';
import { Contato } from '../Contato';
import { TipoPneu } from '../Formula1/TipoPneu';
import { Pontuacao } from '../Formula1/Pontuacao';
import { Bandeiras } from '../Formula1/Bandeiras';
import { GrandPrix } from '../Formula1/GrandPrix';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import '../css/paddockleague.css';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
//import { Navbar } from 'react-bootstrap'
import {NotificationContainer} from 'react-notifications';
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { CalendarioComponent } from "../Calendario";
import { Calendario, CalendarioDetail, CalendarioList } from '../Admin/Calendario';
import { Tabelas, TabelasDetail, TabelasList } from '../Admin/Tabelas';
import { Adsense } from '@ctrl/react-adsense';


class App extends Component{
	constructor(props) {
		super(props);
		this.slideRef = React.createRef();
		
		this.state = {
			linkPatrocinadorDestacado: [{}],
			linkPatrocinador: [{}],
			isProcessando: true
		};
		
	}
	componentDidMount () {
		this.getLinkPatrocinadores();
	}

	getLinkPatrocinadores(){
		let linkImagens = null;
		let linkImagensIndDestacado = [{}];
		let linkImagensIndDestacadoFalse = [{}];
		this.props.firebase.patrocinadores().once('value', snapshot =>{
			if(snapshot.val()){
				linkImagens = Object.keys(snapshot.val()).map(key => ({
					...snapshot.val()[key],
					uid: key,
				}))
				linkImagensIndDestacado = linkImagens.filter(img => img.indDestaque === true && img.indAtivo === true);
				linkImagensIndDestacadoFalse = linkImagens.filter(img => img.indDestaque === false && img.indAtivo === true);
			}
			
		}).then(() => {
			this.setState({ linkPatrocinadorDestacado: linkImagensIndDestacado,
							linkPatrocinador: linkImagensIndDestacadoFalse })
		}).catch(error => {
			this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
		});
	}

	render (){
		const properties = {
			duration: 5000,
			autoplay: true,
			transitionDuration: 500,
			arrows: false,
			infinite: true,
			easing: "ease",
		};

		const renderApoiadoresDestacado = (linkPatrocinadorDestacado) => {
			if(typeof linkPatrocinadorDestacado === 'undefined' || linkPatrocinadorDestacado === null || linkPatrocinadorDestacado === '') return;

			return (
				<div className="slide-container">
					<Slide ref={this.slideRef} {...properties}>
						{linkPatrocinadorDestacado.map((each, index) => (
							<div key={index} className="each-slide">
								<a href={each.urlPatrocinador} target="_blank" rel="noreferrer">
									<img className="lazy" src={each.urlBanner} alt={each.nomePatrocinador} width="950" />
								</a>
							</div>
						))}
					</Slide>
				</div>
			);
		}
		const renderApoiadoresNaoDestacado = (linkPatrocinadorNaoDestacado) => {
			if(typeof linkPatrocinadorNaoDestacado === 'undefined' || linkPatrocinadorNaoDestacado === null || linkPatrocinadorNaoDestacado === '') return;

			return (
				<div className="slide-container-side">
					<Slide ref={this.slideRef} {...properties}>
						{linkPatrocinadorNaoDestacado.map((each, index) => (
							<div key={index} className="each-slide">
								<a href={each.urlPatrocinador} target="_blank" rel="noreferrer">
									<img className="lazy" src={each.urlBanner} alt={each.nomePatrocinador} />
								</a>
							</div>
						))}
					</Slide>
				</div>
			);
		}
		//const { imgCalendario } = this.state;
		//<Router forceRefresh={true}>
		//const { isProcessando, msgRetorno, statusOk } = this.state;
		//<Route exact path={ROUTES.HOME} component={(HomePage)} />
		//<CalendarioComponent/>
		return (
			<Router forceRefresh={true}>
				<div className="container navMenu">
					<NotificationContainer/>
					{renderApoiadoresDestacado(this.state.linkPatrocinadorDestacado)}
					<Navigation />
					<div className="float-sm-left calendario tituloCalendario"> 
						<aside>
							<a className="wordStyleCalendario">Parceiros</a>
							{renderApoiadoresNaoDestacado(this.state.linkPatrocinador)}
						</aside>
						<CalendarioComponent/>
					</div>
					

					<div className="row h-100">
						<div className="col-sm-12 centerContent">
							<div className="padding10px">
							<Switch>
								<Route exact path={ROUTES.SIGN_UP} component={(SignUpPage)} />
								<Route exact path={ROUTES.PASSWORD_FORGET} component={(PasswordForgetPage)} />
								
								<Route exact path={ROUTES.ACCOUNT_PROFILE} component={(AccountPage)} />
								
								<Route exact path={ROUTES.LISTAGEM_PILOTOS} component={PilotoList} />
								<Route exact path={ROUTES.PILOTO_DETAILS} component={PilotoDetail} />

								<Route exact path={ROUTES.LISTAGEM_CATEGORIA} component={CategoriaList} />
								<Route exact path={ROUTES.CADASTRO_CATEGORIA} component={(Categoria)} />
								<Route exact path={ROUTES.CATEGORIA_DETAILS} component={CategoriaDetail} />

								<Route exact path={ROUTES.TRIAGEM} component={TriagemPage} />

								<Route exact path={ROUTES.CADASTRO_PROVAS} component={(CadastroProvasPage)} />
								
								<Route exact path={ROUTES.CATEGORIA_DETAIL} component={(CategoriaPage)} />
								<Route exact path={ROUTES.TEMPORADA_SITE_DETAIL} component={(SeasonPage)} />
								<Route exact path={ROUTES.TEMPORADA_ALL_SITE} component={(SeasonAllPage)} />
								
								<Route exact path={ROUTES.SIGN_IN} component={(SignInPage)}/>
								
								<Route exact path={ROUTES.PASSWORD_CHANGE} component={(PasswordPage)}/>
								
								
								
								<Route exact path={ROUTES.CADASTRO_CAMPEONATO} component={(Campeonato)} />
								<Route exact path={ROUTES.LISTAGEM_CAMPEONATOS} component={(CampeonatoList)} />
								<Route exact path={ROUTES.CAMPEONATO_DETAILS} component={(CampeonatoDetail)} />
								
								<Route exact path={ROUTES.CADASTRO_TEMPORADA} component={(Temporada)} />
								<Route exact path={ROUTES.LISTAGEM_TEMPORADAS} component={(TemporadaList)} />
								<Route exact path={ROUTES.TEMPORADA_DETAILS} component={(TemporadaDetail)} />
								
								<Route exact path={ROUTES.PAINEL_ADMINISTRATIVO} component={(Administrativo)} />
								
								<Route exact path={ROUTES.CADASTRO_NOTICIA} component={(Noticia)} />
								<Route exact path={ROUTES.LISTAGEM_NOTICIAS} component={(NoticiaList)} />
								<Route exact path={ROUTES.NOTICIA_DETAILS} component={(NoticiaDetail)} />

								
								<Route exact path={ROUTES.CADASTRO_PATROCINADOR} component={(Patrocinador)} />
								<Route exact path={ROUTES.LISTAGEM_PATROCINADOR} component={(PatrocinadorList)} />
								<Route exact path={ROUTES.PATROCINADOR_DETAILS} component={(PatrocinadorDetail)} />

								<Route exact path={ROUTES.CADASTRO_CALENDARIO} component={(Calendario)} />
								<Route exact path={ROUTES.LISTAGEM_CALENDARIO} component={(CalendarioList)} />
								<Route exact path={ROUTES.CALENDARIO_DETAIL} component={(CalendarioDetail)} />

								<Route exact path={ROUTES.CADASTRO_TABELA} component={(Tabelas)} />
								<Route exact path={ROUTES.LISTAGEM_TABELA} component={(TabelasList)} />
								<Route exact path={ROUTES.TABELA_DETAIL} component={(TabelasDetail)} />


								<Route exact path={ROUTES.LISTAGEM_NEWS} component={(NewsList)} />

								<Route exact path={ROUTES.TIPO_PNEU} component={(TipoPneu)} />
								<Route exact path={ROUTES.PONTUACAO} component={(Pontuacao)} />
								<Route exact path={ROUTES.BANDEIRAS} component={(Bandeiras)} />
								<Route exact path={ROUTES.GRAND_PRIX} component={(GrandPrix)} />
								<Route exact path={ROUTES.SOBRE} component={(Sobre)} />
								<Route exact path={ROUTES.ULTIMOS_RESULTADOS} component={(UltimosResultados)} />
								<Route exact path={ROUTES.CLASSIFICACAO_PILOTOS} component={(ClassificacaoPilotos)} />
								<Route exact path={ROUTES.CLASSIFICACAO_CONSTRUTORES} component={(ClassificacaoConstrutores)} />
								<Route exact path={ROUTES.CONTATO} component={(Contato)} />

								<Route exact path={ROUTES.ENVIO_NOTIFICACAO} component={(Notificacao)} />
								
								<Route exact path={ROUTES.LANDING} component={(LandingPage)} />
								<Route path='*' exact={true} component={NotFound} />
							</Switch>
							</div>
						</div>
					</div>
				</div>
				
			</Router>
		);
	}
}

export default compose(
  withFirebase,
  withAuthentication
)(App);
