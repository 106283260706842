import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Image, Modal, Button, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import * as CONSTANTES from '../../../constants/constantes';
import '../../css/paddockleague.css';
import Delete from '../../css/delete.png';
import GreenSignal from '../../css/greenSignal.png';
import RedSignal from '../../css/redSignal.png';
import Detail from '../../css/detail.png';
import ReactTooltip from "react-tooltip";


const PatrocinadorPage = () => (
  <PatrocinadorForm />
);

class PatrocinadorListBase extends Component {
  constructor(props) {
      super(props);
      this.state = {
          isProcessando: false,
          statusOk: false,
          msgRetorno: '',
          patrocinadores: [],
          show: false,
          patrocinadorSelecionado: ''
      };
  }

  retornaPatrocinadores(){
    this.setState({ isProcessando: true });

    this.props.firebase.patrocinadores().once('value', snapshot => {
      const patrocinadorObject = snapshot.val();
      let patrocinadorList = [];
      if(patrocinadorObject){
        patrocinadorList = Object.keys(patrocinadorObject).map(key => ({
          ...patrocinadorObject[key],
          uid: key,
        }));
      }else{
        patrocinadorList = [];
      }

      this.setState({
        patrocinadores: patrocinadorList,
        isProcessando: false,
      });
    });
  }

  componentDidMount() {
    this.retornaPatrocinadores();
  }

  render() {
      const { patrocinadores, isProcessando, show, msgRetorno, patrocinadorSelecionado} = this.state;
      const handleClose = () => {
        this.setState({ show: false })
      };
      const handleShow = (patrocinador) => {
        this.setState({ patrocinadorSelecionado: patrocinador, show: true })
      };
      const handleDeletarPatrocinador = (uid) => {
        this.setState({ show: false, isProcessando: true })
        this.props.firebase.patrocinador(uid).remove().then(() => {
            let listaPatrocinadores = patrocinadores.filter(patrocinador => patrocinador.uid !== uid);
            this.setState({ isProcessando: false, statusOk: true, msgRetorno: 'Registro atualizado com sucesso!', patrocinadores: listaPatrocinadores});
        }).catch(error => {
            this.setState({ isProcessando: false, statusOk: false, msgRetorno: error });
        })
      };
      const renderPatrocinador = (patrocinador) => {
          return (
            <tr key={patrocinador.uid}>
                <td>{patrocinador.nomePatrocinador}</td>
                <td className="centerTableImage">
                  <Link to={{ pathname: `${ROUTES.LISTAGEM_PATROCINADOR}/${patrocinador.uid}`, 
                                        state: { patrocinador }, }} >
                    <Image src={Detail} data-tip data-for='detailTip' className="IconTable" />
                    <ReactTooltip id='detailTip' type='error'>
                      <span>Detalhes</span>
                    </ReactTooltip>
                  </Link>
                </td>
                <td className="centerTableImage">
                  <Image src={Delete} data-tip data-for='deleteTip' className="IconTable" onClick={() => handleShow(patrocinador)}/>
                  <ReactTooltip id='deleteTip' type='error'>
                    <span>Apagar</span>
                  </ReactTooltip>
                </td>
                <td className="centerTableImage" >
                  <Image src={!patrocinador.indAtivo ? RedSignal : GreenSignal} disabled data-tip data-for='readyTip' className="IconTable"/>
                  <ReactTooltip id='readyTip' type='error'>
                    <span>{!patrocinador.indAtivo ? 'Inativo' : 'Ativo'}</span>
                  </ReactTooltip>
                </td>
            </tr>
          );
      }
      return (
      <AuthUserContext.Consumer>
      {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Listagem de Patrocinadores</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <div className="wordStyle">
                  <Table striped bordered hover variant="dark">
                      <thead>
                          <tr>
                              <th>Nome Patrocinador</th>
                          </tr>
                      </thead>
                      <tbody>
                          {patrocinadores.map(renderPatrocinador)}
                      </tbody>
                  </Table>
                  <Modal show={show} onHide={() => handleClose()} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                      <Modal.Title className="wordStyle">{CONSTANTES.DRIVE_PRO_SERIES}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="wordStyle">
                      Você está prestes a deletar o Patrocinador {patrocinadorSelecionado.nomePatrocinador}, tem certeza disso? 
                    </Modal.Body>
                    <Modal.Footer>
                      <Button className="wordStyle" variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                      </Button>
                      <Button className="wordStyle" variant="primary" onClick={() => handleDeletarPatrocinador(patrocinadorSelecionado.uid)}>
                        Ok, pode deletar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Card.Body>
            </Card>
          </LoadingOverlay>
      )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const PatrocinadorForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(PatrocinadorListBase);

export default PatrocinadorPage;
export { PatrocinadorForm };