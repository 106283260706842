import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Form, Image, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';
import * as CONSTANTES from '../../../constants/constantes';
import Detail from '../../css/detail.png';
import GreenSignal from '../../css/greenSignal.png';
import RedSignal from '../../css/redSignal.png';
import ReactTooltip from "react-tooltip";

const CategoriaPage = () => (
    <CategoriaForm />
);

class CategoriaListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessando: false,
      statusOk: false,

      msgRetorno: '',
      categoriaList: [],
    };
  }

  retornaCategorias(){
      this.setState({ isProcessando: true });
      this.props.firebase.categorias().once('value', snapshot => {
        const categoriaObject = snapshot.val();
        if (categoriaObject) {
          const listCategoria = Object.keys(categoriaObject).map(key => ({
            ...categoriaObject[key],
            uid: key,
          }));
          this.setState({
            categoriaList: listCategoria.filter(cat => cat.nome_categoria !== CONSTANTES.SEM_CATEGORIA),
            isProcessando: false,
            statusOk: true,
          });
        } else {
          this.setState({ categoriaList: [], isProcessando: false, statusOk: false});
        }
      }, error =>{
          this.setState({ categoriaList: [], isProcessando: false, msgRetorno: error, statusOk: false});
      });
  }

  componentDidMount() {
    this.retornaCategorias();
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, isProcessando, categoriaList } = this.state;
    const renderCategoria = (categoria) => {
        return (
            <tr key={categoria.uid}>
                <td>{categoria.nome_categoria}</td>
                <td>
                  <Link to={{ pathname: `${ROUTES.LISTAGEM_CATEGORIA}/${categoria.uid}`, 
                                        state: { categoria }, }} >
                    <Image src={Detail} data-tip data-for='detailTip' className="IconTable" />
                    <ReactTooltip id='detailTip' type='error'>
                      <span>Detalhes</span>
                    </ReactTooltip>
                  </Link>
                </td>
                <td className="centerTableImage" >
                  <Image src={!categoria.indAtivo ? RedSignal : GreenSignal} disabled data-tip data-for='readyTip' className="IconTable"/>
                  <ReactTooltip id='readyTip' type='error'>
                    <span>{!categoria.indAtivo ? 'Inativo' : 'Ativo'}</span>
                  </ReactTooltip>
                </td>
            </tr>
        );
    }

    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
            <Card className="cardPainelTexto">
              <Card.Body>
                <Card.Title className="cardPainelTitulo">Listagem de Categorias</Card.Title>
                <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                <div className="wordStyle">
                  <Table striped bordered hover variant="dark">
                      <thead>
                          <tr>
                              <th>Categoria</th>
                          </tr>
                      </thead>
                      <tbody>
                          {categoriaList.map(renderCategoria)}
                      </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </LoadingOverlay>
      )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const CategoriaForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(CategoriaListBase);

export default CategoriaPage;
export { CategoriaForm }; 