import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap'
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import '../css/paddockleague.css';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import CategoriaList from '../Categoria/CategoriasList'
import SeasonList from '../Season/SeasonList';
import AccountOptionProfile from '../Account/AccountOptionProfile';
import '../css/paddockleague.css';
import Instagram from '../css/Instagram.png';
import Youtube from '../css/Youtube.png';

class Navigation extends Component {

	constructor(props) {
    super(props);
    
    this.state = {
        link_calendario: '',
        link_regulamento: '',
        uid: '',
    };
     
  }
	componentDidMount() {
      this.props.firebase.administrativos().once('value', snapshot => {
          
        const administrativoObject = Object.keys(snapshot.val()).map(key => ({
            ...snapshot.val()[key],
            uid: key,
        }))[0]
        
        this.setLinkRegulamento(administrativoObject) 
    });  
  }
  setLinkRegulamento = (administrativoObject) => {
      this.setState({ link_regulamento: administrativoObject.link_regulamento, uid: administrativoObject.uid })
  }
  render () {
		let { link_regulamento } = this.state;
	  return(
		<AuthUserContext.Consumer>
			{authUser =>
				<NavigationAuth authUser={authUser} linkRegulamento={link_regulamento} />
			}
		</AuthUserContext.Consumer>
	  );
  }
}
//<div style={{paddingLeft: 96 + 'px'}}></div>
const NavigationAuth = ({ authUser, linkRegulamento }) => (
	<Navbar bg="light" expand="lg" className="navBarMargin">
	  
		<Navbar.Brand className="wordStyle" href="/">DriveProSeries League</Navbar.Brand>
	  <Navbar.Toggle aria-controls="basic-navbar-nav" />
	  <Navbar.Collapse id="basic-navbar-nav">
			<Nav className="mr-auto">
				<Nav.Link href={ROUTES.LISTAGEM_NEWS}>News</Nav.Link>
				<NavDropdown title="Categorias" id="basic-nav-dropdown">
					<CategoriaList/>
				</NavDropdown>
				<Nav.Link href={linkRegulamento} target="_blank" download>Regulamento</Nav.Link> 
				<NavDropdown title="Temporadas" id="basic-nav-dropdown">
					<SeasonList/>
				</NavDropdown>
				<NavDropdown title="Fórmula 1" id="basic-nav-dropdown_1">
					<Nav.Link href={ROUTES.CLASSIFICACAO_PILOTOS}>Classificação Pilotos</Nav.Link>
					<Nav.Link href={ROUTES.CLASSIFICACAO_CONSTRUTORES}>Classificação Construtores</Nav.Link>
					<Nav.Link href={ROUTES.ULTIMOS_RESULTADOS}>Último resultado</Nav.Link>
					<NavDropdown.Divider />
					<Nav.Link href={ROUTES.GRAND_PRIX}>Grandes Prêmios e autódromos</Nav.Link>
					<Nav.Link href={ROUTES.BANDEIRAS}>Bandeiras</Nav.Link>
					<Nav.Link href={ROUTES.PONTUACAO}>Sistema de pontuação</Nav.Link>
					<Nav.Link href={ROUTES.TIPO_PNEU}>Tipos de pneus</Nav.Link>
				</NavDropdown>
				<NavDropdown title="Dicas" id="basic-nav-dropdown_1">
					<Nav.Link href={ROUTES.SOBRE}>Pistas</Nav.Link>
					<Nav.Link href={ROUTES.SOBRE}>Setup</Nav.Link>
					<Nav.Link href={ROUTES.SOBRE}>Sistema de pontuação</Nav.Link>
				</NavDropdown>
				

				<Nav.Link href={ROUTES.SOBRE}>Sobre</Nav.Link>
				<Nav.Link href={ROUTES.CONTATO}>Contato</Nav.Link>
			</Nav>
			<Nav>
				{authUser ? (
					<React.Fragment>
						<div className="nav-link"> Seja bem Vindo </div>
						<NavDropdown title="Conta">
							<AccountOptionProfile />
						</NavDropdown>
					</React.Fragment>
				):(
					<React.Fragment>
						<Nav.Link href={ROUTES.SIGN_IN}>Login</Nav.Link>
						<Nav.Link href={ROUTES.SIGN_UP}>Registra-se</Nav.Link>
					</React.Fragment>
				)}
				
			</Nav>
	  </Navbar.Collapse>
		<React.Fragment>
			<div style={{display: 'inline-flex'}}>
				<a href="https://www.instagram.com/dps_league/" target="_blank" rel="noreferrer">
					<Image alt="Instagram DriveProSeries" src={Instagram} className="IconSocialMediaNavBar"/>
				</a>
				<a href="https://www.youtube.com/channel/UC8HwT8yORFJJ8h53Rgk9XZg" target="_blank" rel="noreferrer">
					<Image alt="YouTube DriveProSeries" src={Youtube} className="IconSocialMediaNavBar"/>
				</a>
			</div>
		</React.Fragment>
	</Navbar>
	
);


export default compose(
  withFirebase
)(Navigation); 
