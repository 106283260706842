import React, { Component } from 'react';
import { Modal, Button, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { compose } from 'recompose';
import '../css/paddockleague.css';
import ReactHtmlParser from 'react-html-parser';

const NewsListPage = () => (
  <div className="wordStyle">
    <h1 className="textAlignCenter">Últimas notícias</h1>
    <NewsListForm />
  </div>
);

class NewsListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessando: false,
      statusOk: false,
      msgRetorno: '',
      noticias: [],
      show: false,
      noticiaEscolhida: '',
    };
  }

  retornaNoticias(){
    this.setState({ isProcessando: true });
    this.props.firebase.noticias().orderByChild("data_timestamp").limitToFirst(5).once('value', snapshot => {
      const newsObject = snapshot.val();
      let newsList = [];
      if(newsObject){
        newsList = Object.keys(newsObject).map(key => ({
          ...newsObject[key],
          uid: key,
        })).sort(function compare(a,b) { return a.data_timestamp - b.data_timestamp; });
      }else{
        newsList = [];
      }
      
      this.setState({
        noticias: newsList,
        isProcessando: false,
      });
    });
  }

  componentDidMount() {
    this.retornaNoticias();
  }

  componentWillUnmount() {
    this.props.firebase.noticias().off();
  }
  

  render() {
    const { noticias, isProcessando, show, noticiaEscolhida} = this.state;
    
    const handleClose = () => {
      this.setState({ show: false })
    };
    
    const handleShow = (noticia) => {
      this.setState({ noticiaEscolhida: noticia, show: true })
    };

    const renderNoticia = (noticia) => {
        if(noticia){
            return (
                <React.Fragment key={noticia.uid}>
                    <Card className="cardPainelTexto">
                        <Card.Body>
                            <Card.Title className="cardPainelTitulo">{noticia.titulo_noticia}</Card.Title>
                            <Card.Text className="wordStyle">
                                {noticia.editorState.substring(0,150)} ...
                            </Card.Text>
                            <Button className="wordStyle" variant="primary" onClick={() => handleShow(noticia)}>Leia Mais!!</Button>
                        </Card.Body>
                    </Card>
                    <div style={{ paddingTop: '25px' }}></div>
                </React.Fragment>
            );
        }
    }

    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
             {noticias.map(renderNoticia)}
             {noticias.length === 0 && <div> Sem noticia cadastrada! </div>}
             <Modal dialogClassName="modal-dialog-noticias" show={show} onHide={() => handleClose()} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title className="wordStyle">{noticiaEscolhida.titulo_noticia}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="wordStyle">
                    <h6 className= "autorNoticiaInformacao">Autor: {noticiaEscolhida.autor}</h6>
                    <h6 className= "autorNoticiaInformacao">Data publicação: {noticiaEscolhida.data_publicacao}</h6>
                    {ReactHtmlParser(noticiaEscolhida.conteudo_html)}
                </Modal.Body>
                <Modal.Footer className="wordStyle">
                    <Button variant="primary" onClick={() => handleClose()}>
                        Fechar
                    </Button>
                </Modal.Footer>
             </Modal>
          </LoadingOverlay>
      )}
      </AuthUserContext.Consumer>
    );
  }
}

const NewsListForm = compose(
  withFirebase,
)(NewsListBase);

export default NewsListPage;
export { NewsListForm };