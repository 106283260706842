export const DRIVE_PRO_SERIES = 'Drive Pro Series League';
export const SEM_CATEGORIA = 'SEM CATEGORIA';
export const DATE_OPTIONS = { year: 'numeric', month: 'numeric', day: 'numeric' };
export const JOGO_DEFAULT = ['F1 - 2022'];
//export const JOGOS_DISPONIVEIS = ['F1 - 2022', 'F1 - 2021'];
export const JOGOS_DISPONIVEIS = ['F1 - 2022'];

export const PNEU_VERMELHO = 'https://upload.wikimedia.org/wikipedia/commons/d/df/F1_tire_Pirelli_PZero_Red.svg';
export const PNEU_AMARELO = 'https://upload.wikimedia.org/wikipedia/commons/4/4d/F1_tire_Pirelli_PZero_Yellow.svg';
export const PNEU_BRANCO = 'https://upload.wikimedia.org/wikipedia/commons/d/d6/F1_tire_Pirelli_PZero_White.svg';
export const PNEU_VERDE = 'https://upload.wikimedia.org/wikipedia/commons/8/86/F1_tire_Pirelli_Cinturato_Green.svg';
export const PNEU_AZUL = 'https://upload.wikimedia.org/wikipedia/commons/6/63/F1_tire_Pirelli_Cinturato_Blue.svg';

export const BANDEIRA_AMARELA = 'https://upload.wikimedia.org/wikipedia/commons/1/11/F1_yellow_flag.svg';
export const BANDEIRA_AZUL = 'https://upload.wikimedia.org/wikipedia/commons/f/f5/F1_light_blue_flag.svg';
export const BANDEIRA_VERDE = 'https://upload.wikimedia.org/wikipedia/commons/d/d9/F1_green_flag.svg';
export const BANDEIRA_VERMELHA = 'https://upload.wikimedia.org/wikipedia/commons/8/81/F1_red_flag.svg';
export const BANDEIRA_LISTRADA = 'https://upload.wikimedia.org/wikipedia/commons/a/a3/F1_yellow_flag_with_red_stripes.svg';
export const BANDEIRA_BRANCA = 'https://upload.wikimedia.org/wikipedia/commons/6/62/F1_white_flag.svg';
export const BANDEIRA_QUADRICULADA = 'https://upload.wikimedia.org/wikipedia/commons/9/99/F1_chequered_flag.svg';
export const BANDEIRA_PRETA_BRANCA = 'https://upload.wikimedia.org/wikipedia/commons/9/90/F1_black_and_white_diagonal_flag.svg';
export const BANDEIRA_PRETA = 'https://upload.wikimedia.org/wikipedia/commons/4/48/Auto_Racing_Black.svg';
export const BANDEIRA_PRETA_CIRCULO_LARANJA = 'https://upload.wikimedia.org/wikipedia/commons/4/45/F1_black_flag_with_orange_circle.svg';

export const BANDEIRA_BAHRAIN = 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg';
export const URL_BAHRAIN = 'https://pt.wikipedia.org/wiki/Circuito_Internacional_do_Bar%C3%A9m';
export const BANDEIRA_ARABIA_SAUDITA = 'https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg';
export const URL_ARABIA_SAUDITA = 'https://pt.wikipedia.org/wiki/Circuito_Urbano_de_Gid%C3%A1';
export const BANDEIRA_AUSTRALIA = 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg';
export const URL_AUSTRALIA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_da_Austr%C3%A1lia';
export const BANDEIRA_EMILIA_ROMAGNA = 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Emilia-Romagna_%28de_facto%29.svg';
export const URL_EMILIA_ROMAGNA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_da_Em%C3%ADlia-Romanha';
export const BANDEIRA_MIAMI = 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Miami%2C_Florida.svg';
export const URL_MIAMI = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_de_Miami';
export const BANDEIRA_ESPANHA = 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg';
export const URL_ESPANHA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_da_Espanha';
export const BANDEIRA_MONACO = 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg';
export const URL_MONACO = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_de_M%C3%B4naco';
export const BANDEIRA_AZERBAIJAO = 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg';
export const URL_AZERBAIJAO = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_do_Azerbaij%C3%A3o';
export const BANDEIRA_CANADA = 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg';
export const URL_CANADA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_do_Canad%C3%A1';
export const BANDEIRA_GRA_BRETANHA = 'https://upload.wikimedia.org/wikipedia/commons/a/ae/Flag_of_the_United_Kingdom.svg';
export const URL_GRA_BRETANHA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_do_Canad%C3%A1';
export const BANDEIRA_AUSTRIA = 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg';
export const URL_AUSTRIA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_da_%C3%81ustria';
export const BANDEIRA_FRANCA = 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg';
export const URL_FRANCA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_da_Fran%C3%A7a';
export const BANDEIRA_HUNGRIA = 'https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg';
export const URL_HUNGRIA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_da_Hungria';
export const BANDEIRA_BELGICA = 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg';
export const URL_BELGICA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_da_B%C3%A9lgica';
export const BANDEIRA_PAISES_BAIXOS = 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg';
export const URL_PAISES_BAIXOS = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_dos_Pa%C3%ADses_Baixos';
export const BANDEIRA_ITALIA = 'https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Italy.svg';
export const URL_ITALIA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_da_It%C3%A1lia';
export const BANDEIRA_RUSSIA = 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Russia.svg';
export const URL_RUSSIA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_da_R%C3%BAssia';
export const BANDEIRA_SINGAPURA = 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg';
export const URL_SINGAPURA = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_de_Singapura';
export const BANDEIRA_JAPAO = 'https://upload.wikimedia.org/wikipedia/commons/9/9e/Flag_of_Japan.svg';
export const URL_JAPAO = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_do_Jap%C3%A3o';
export const BANDEIRA_ESTADOS_UNIDOS = 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg';
export const URL_ESTADOS_UNIDOS = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_dos_Estados_Unidos';
export const BANDEIRA_MEXICO = 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg';
export const URL_MEXICO = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_do_M%C3%A9xico';
export const BANDEIRA_BRASIL = 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg';
export const URL_BRASIL = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_do_Brasil';
export const BANDEIRA_ABU_DHABI = 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg';
export const URL_ABU_DHABI = 'https://pt.wikipedia.org/wiki/Grande_Pr%C3%AAmio_de_Abu_Dhabi';