import React from 'react';
import { Card, Button } from 'react-bootstrap';
import '../css/paddockleague.css';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      const renderBtnEnviarEmailConfirmacao = ()=>{
        return (<Button className="wordStyle" onClick={this.onSendEmailVerification} disabled={this.state.isSent}>
                  Enviar e-mail de confirmação
                </Button>)
      }
      return (
        <AuthUserContext.Consumer>
          {authUser => 
            needsEmailVerification(authUser) ? (
              <div>
                {this.state.isSent ? (
                  <Card className="cardPainelTexto">
                    <Card.Body>
                      <Card.Title className="cardPainelTitulo">DRIVE PRO SERIES LEAGUE </Card.Title>
                      <Card.Text>
                        <div className="wordStyle">
                          Foi enviado um e-mail de confirmação para {authUser.email}. Verifique a sua caixa de entrada e confirme seu cadastro clicando no link enviado.
                        </div>
                      </Card.Text>
                      {renderBtnEnviarEmailConfirmacao()}
                    </Card.Body>
                  </Card>
                ) : (
                  <Card className="cardPainelTexto">
                    <Card.Body>
                      <Card.Title className="cardPainelTitulo">DRIVE PRO SERIES LEAGUE </Card.Title>
                      <Card.Text>
                        <div className="wordStyle">
                          Verifique o seu e-mail {authUser.email}: Verifique a sua caixa de entrada e confirme seu cadastro clicando no link enviado, ou clique no botão abaixo para enviar outro link de confirmação.
                        </div>
                      </Card.Text>
                      {renderBtnEnviarEmailConfirmacao()}
                    </Card.Body>
                  </Card>
                )}

                
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;