import React, { Component } from 'react';
import { Form, Button, Col, Card } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import '../../css/paddockleague.css';
import * as CONSTANTES from '../../../constants/constantes';
import { EditorState, convertToRaw, ContentState, convertFromHTML, CompositeDecorator } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

const NoticiaDetailPage = () => (
    <NoticiaDetalheForm />
);

class NoticiaDetalheFormBase extends Component {
  
  constructor(props) {
    super(props);
    let infoNoticia = props.location.state.noticia;
    
    //const { value } = this.props
    const blocksFromHTML = convertFromHTML(infoNoticia.editorState);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );
    const compositeDecorator = new CompositeDecorator([]);

    this.state = {
        uid: infoNoticia.uid,
        titulo_noticia: infoNoticia.titulo_noticia,
        editorState: EditorState.createWithContent(state, compositeDecorator),
        data_publicacao: infoNoticia.data_publicacao,
        autor: infoNoticia.autor,

        msgRetorno: '',
        isProcessando: false,
        statusOk: false,
        isInvalido: false,
    };
  }
  
  componentWillUnmount() {
      this.props.firebase.noticias().off();
  }
  
  onSubmit = (event, authUser) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isInvalido: true });
    }else{
        this.setState({ isInvalido: false, isProcessando: true, });
        
        let conteudoNoticia = this.state.editorState.getCurrentContent();
        const blocks = convertToRaw(conteudoNoticia).blocks;
        const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        this.props.firebase.noticia(this.state.uid).update({
            titulo_noticia: this.state.titulo_noticia,
            editorState: value,
            conteudo_html: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
            data_publicacao: new Date().toLocaleDateString('pt-BR', CONSTANTES.DATE_OPTIONS),
            autor: authUser.nomeUsuario,
        }).then(() => {
            this.setState({msgRetorno: 'Notícia atualizada com sucesso!', isProcessando: false, statusOk: true});
        }).catch(error => {
            this.setState({ msgRetorno: error, isProcessando: false, statusOk: false});
        })
    }
    event.preventDefault();
  };

  onEditorStateChange = editorState => {
      this.setState({ editorState });
  };
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  render() {
    let { titulo_noticia, editorState, msgRetorno, isProcessando, statusOk, isInvalido} = this.state;
    const renderMessage = ()=>{
      if (statusOk){
        return (<p className="styleSuccess">{msgRetorno}</p>)
      }else{
        return <p className="styleError">{msgRetorno}</p>
      }
    }
    
    const isInvalidFields = false;
    return (
      <AuthUserContext.Consumer>
      {authUser => (
          <Form noValidate validated={isInvalido} onSubmit={(event) => this.onSubmit(event, authUser)}>
            <LoadingOverlay active={isProcessando} spinner text='Aguarde!'>
                <Card className="cardPainelTexto">
                  <Card.Body>
                    <Card.Title className="cardPainelTitulo">Detalhe da Notícia</Card.Title>
                    <Card.Title className="cardPainelSubTitulo">Somente usuários administrativos podem acessar</Card.Title>
                    <Card.Title className="cardPainelSubTitulo">A aplicação não está pegando o estilo (Negrito, Italico, etc)</Card.Title>
                    <Card.Text className="wordStyle">
                      <Form.Row>
                          <Form.Group as={Col} controlId="formNomeCampeonato">
                              <Form.Label>Título</Form.Label>
                              <Form.Control required pattern="\S(.*\S)?" value={titulo_noticia} maxLength={100} name="titulo_noticia" type="text" placeholder="Título da Notícia" onChange={this.onChange}/>
                              <Form.Control.Feedback type="invalid">
                                  Informe o Título da Notícia!
                              </Form.Control.Feedback>
                          </Form.Group>
                      </Form.Row>
                      <Editor editorState={editorState}
                              wrapperClassName="rich-editor demo-wrapper"
                              editorClassName="demo-editor"
                              onEditorStateChange={this.onEditorStateChange}
                              placeholder="Qual será a noticia de hoje..." />
                      {renderMessage()}
                      <Button disabled={isInvalidFields}  variant="primary" type="submit">
                          Atualizar Notícia
                      </Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
            </LoadingOverlay>
          </Form> 
      )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.IMPRENSA]);

const NoticiaDetalheForm = compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(NoticiaDetalheFormBase);

export default NoticiaDetailPage;
export { NoticiaDetalheForm };