import React from 'react';
import ReactDOM from 'react-dom';
 
import './index.css';
import './components/css/paddockleague.css';
import * as serviceWorker from './serviceWorker';
 
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';

/**
<div className="backgroundImage">
		<App className="backgroundImage" />
	</div>
*/ 
ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
	  
		  <App className="backgroundImage" />
      
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);
 
serviceWorker.unregister();