import React, { Component } from 'react';
import { Card } from 'react-bootstrap'
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import '../css/paddockleague.css';

const SobrePage = () => (
    <SobreForm />
);

class SobrePageBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        sobre: '',
    };
     
  }
  componentDidMount() {
      this.props.firebase.administrativos().once('value', snapshot => {
          
        const administrativoObject = Object.keys(snapshot.val()).map(key => ({
            ...snapshot.val()[key],
            uid: key,
        }))[0]
        
        this.setTextoSobre(administrativoObject) 
    });  
  }
  setTextoSobre = (administrativoObject) => {
      this.setState({ sobre: administrativoObject.sobre })
  }
  
  render() {
    const { sobre } = this.state;
    return (
      <Card className="cardPainelTexto">
        <Card.Body>
          <Card.Title className="cardPainelTitulo">DRIVE PRO SERIES LEAGUE </Card.Title>
          <Card.Text className="wordStyle">
            {sobre}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

const SobreForm = compose(
  withFirebase
)(SobrePageBase);

export default SobrePage;
export { SobreForm };